import request from './request';
import {ENDPOINTS} from 'config';
import Toast from 'components/Toast/Toast';
const queryString = require('query-string');

const signIn = (username, password) => {
  return request({
    url: `${ENDPOINTS.SIGN_IN}`,
    method: 'POST',
    data: {
      username,
      password,
    },
  });
};

const getCampaigns = () => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGNS}`,
    method: 'GET',
  });
};

const getCampaignsFiltered = (query = {}) => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGNS_FILTERED}?${queryString.stringify(query)}`,
    method: 'GET',
  });
};

const getCampaignDetails = id => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGN_DETAILS}${id}`,
    method: 'GET',
  });
};

const getCampaignStatus = id => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGN_STATUS}${id}`,
    method: 'GET',
  });
};

const getCampaignDatasource = id => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGN_DATASOURCE}${id}`,
    method: 'GET',
  });
};

const getCampaignTypes = () => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGN_TYPES}`,
    method: 'GET',
  });
};

const getCampaignDocumentIds = id => {
  return request({
    url: `${ENDPOINTS.GET_CAMPAIGN_DOCIDS}${id}`,
    method: 'GET',
  });
};

const getMailClasses = did => {
  if (did == null) did = 3;
  return request({
    url: `${ENDPOINTS.GET_MAIL_CLASSES}${did}`,
    method: 'GET',
  });
};

const getDocumentStatuses = cid => {
  return request({
    url: `${ENDPOINTS.GET_DOCUMENT_STATUSES}${cid}${'&limit=10'}`,
    method: 'GET',
  });
};

const getDocument = id => {
  return request({
    url: `${ENDPOINTS.GET_DOCUMENT}${id}`,
    method: 'GET',
  });
};

const searchDocuments = query => {
  return request({
    url: `${ENDPOINTS.SEARCH_DOCUMENTS}?${queryString.stringify(query)}`,
    method: 'GET',
  });
};

const getPaperTypes = did => {
  if (did == null) did = 3;
  return request({
    url: `${ENDPOINTS.GET_PAPER_TYPES}${did}`,
    method: 'GET',
  });
};

const postCampaign = campaign => {
  // IF NOT SPECIFIED, HARDCODE LOB/DOCSMIT AS DESTINATION (id=3)
  if (
    !campaign.hasOwnProperty('destination_id') ||
    campaign.destination_id == null
  ) {
    campaign.destination_id = null;
  }
  return request({
    url: `${ENDPOINTS.POST_CAMPAIGN}`,
    method: 'POST',
    data: campaign,
  });
};

const postFollowupCampaign = campaign => {
  return request({
    url: `${ENDPOINTS.POST_FOLLOWUP_CAMPAIGN}`,
    method: 'POST',
    data: campaign,
  });
};

const postCampaignTemplate = template => {
  return request({
    url: `${ENDPOINTS.POST_CAMPAIGN_TEMPLATE}`,
    method: 'POST',
    data: template,
  });
};

const putTemplate = template => {
  return request({
    url: `${ENDPOINTS.PUT_TEMPLATE}`,
    method: 'PUT',
    data: template,
  });
};

const deleteCampaignTemplate = campaign_id => {
  return request({
    url: `${ENDPOINTS.POST_CAMPAIGN_TEMPLATE}`,
    method: 'DELETE',
    data: {id: campaign_id},
  });
};

const postCampaignCsv = argsObj => {
  argsObj.type = 'file_upload';
  return request({
    url: `${ENDPOINTS.POST_CAMPAIGN_CSV}`,
    method: 'POST',
    data: argsObj,
    timeout: 6000000,
  });
};

const patchCampaign = campaign => {
  return request({
    url: `${ENDPOINTS.PATCH_CAMPAIGN_DETAILS}`,
    method: 'PATCH',
    data: campaign,
  });
};

const sendCampaign = campaign_id => {
  return request({
    url: `${ENDPOINTS.SEND_CAMPAIGN}`,
    method: 'POST',
    data: campaign_id,
  });
};

const attachDatasource = (cid, dsid) => {
  return request({
    url: `${ENDPOINTS.ATTACH_DATASOURCE}`,
    method: 'POST',
    data: {campaign_id: cid, data_source_id: dsid},
  });
};

const getPreviews = id => {
  return request({
    url: `${ENDPOINTS.GET_PREVIEWS}${id}`,
    method: 'GET',
  });
};

const getTemplate = (id, doMerge) => {
  return request({
    url: `${ENDPOINTS.GET_TEMPLATE}${id}${'&do_merge='}${doMerge}`,
    method: 'GET',
  });
};

const getTemplateById = id => {
  return request({
    url: `${ENDPOINTS.GET_ALL_TEMPLATES}?id=${id}`,
    method: 'GET',
  });
};

const getAllTemplates = async () => {
  // return request({
  //   url: `${ENDPOINTS.GET_ALL_TEMPLATES}`,
  //   method: 'GET',
  // });

  let allResults = [];
  let pageSize = 100;
  return await getPageOfTemplates()
  .then((pgRes) => {
    allResults = allResults.concat(pgRes.templates);
    if(pgRes.total_count > pageSize) {
      let pagesLeft = Math.ceil((pgRes.total_count - pageSize) / pageSize);
      let apiCalls = [];
      for(var i = 1; i <= pagesLeft; i++) {
        apiCalls.push(getPageOfTemplates(pageSize*i, pageSize));
      }
      return Promise.all(apiCalls);
    }
    else {
      return Promise.resolve([]);
    }
  })
  .then((apiResponses) => {
    apiResponses.forEach((resp) => {
      allResults = allResults.concat(resp.templates);
    })
    return Promise.resolve(allResults);
  })
  .catch((err) => {
    console.log(err);
  })
};

const getPageOfTemplates = async (offset = 0, limit = 100) => {
  return request({
    url: `${ENDPOINTS.GET_ALL_TEMPLATES}?offset=${offset}&limit=${limit}`,
    method: 'GET'
  })
}


const getCsv = id => {
  return request({
    url: `${ENDPOINTS.GET_CSV}${id}`,
    method: 'GET',
  });
};

const getDatasource = id => {
  return request({
    url: `${ENDPOINTS.GET_DATASOURCE}${id}`,
    method: 'GET',
    data: {
      id: id ? id : '',
    },
  });
};

const getDatasourceFiltered = filters => {
  return request({
    url: `${ENDPOINTS.GET_DATASOURCE_FILTERED}?${queryString.stringify(
      filters,
    )}`,
    method: 'GET',
  });
};

const getDatasourceMaps = id => {
  return request({
    url: `${ENDPOINTS.GET_DATASOURCE_FIELDMAP}`,
    method: 'GET',
  });
};

const postDatasourceMap = data => {
  return request({
    url: `${ENDPOINTS.POST_DATASOURCE_FIELDMAP}`,
    method: 'POST',
    data: data,
  });
};

const applyDatasourceMap = data => {
  return request({
    url: `${ENDPOINTS.APPLY_DATASOURCE_FIELDMAP}`,
    method: 'POST',
    data: data,
  });
};

const mergeCampaign = campaign_id => {
  return request({
    url: `${ENDPOINTS.MERGE_CAMPAIGN}`,
    method: 'POST',
    data: campaign_id,
  });
};

const sentToCSMailhouse = () => {
  return request({
    url: `${ENDPOINTS.GET_SENT_CS_MAILHOUSE}`,
    method: 'GET',
  });
};

const getSuggestedFieldMap = datasourceId => {
  return request({
    url: `${ENDPOINTS.SUGGESTED_FIELDMAP}${datasourceId}`,
    method: 'GET',
  });
};

const APIService = {
  signIn,
  getCampaigns,
  getCampaignDetails,
  getCampaignStatus,
  getCampaignDatasource,
  getCampaignTypes,
  getMailClasses,
  getCampaignDocumentIds,
  getDocument,
  getDocumentStatuses,
  searchDocuments,
  getPaperTypes,
  postCampaign,
  postCampaignTemplate,
  putTemplate,
  postCampaignCsv,
  patchCampaign,
  sendCampaign,
  attachDatasource,
  getPreviews,
  postFollowupCampaign,
  getTemplate,
  getCsv,
  getDatasource,
  getDatasourceFiltered,
  getCampaignsFiltered,
  postDatasourceMap,
  applyDatasourceMap,
  mergeCampaign,
  sentToCSMailhouse,
  getDatasourceMaps,
  getAllTemplates,
  deleteCampaignTemplate,
  getTemplateById,
  getSuggestedFieldMap,
  getPageOfTemplates,
};

export default APIService;
