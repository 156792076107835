import React, { useEffect } from 'react';
import { CTX } from 'store';

import APIService from 'services/backstrap/apiService';
import Header from 'components/Header/Header';
import imgErr from 'assets/img/ico_err.svg';
import imgMail from 'assets/img/ico_mail.svg';
import imgBoth from 'assets/img/ico_both.svg';
import plus from 'assets/img/ico_plus.svg';
import NoDrafts from 'assets/img/ico_no_drafts.svg';
import { monthNames, modifyCampaignObject } from 'services/util'


const Scheduled = (props) => {

    document.title = `Scheduled`;

    const [appState, dispatch] = React.useContext(CTX);

    function campaignDetail(cmp) {
      if(cmp.id) {
        APIService.getCampaignDetails(cmp.id)
        .then((res) => {
          const selectedCampaign = modifyCampaignObject(res)
          dispatch({type: 'SET_ACTIVE_SEND', payload: selectedCampaign})
          props.history.push('/sent/letterlist/'+cmp.id);
        })
      }
    }

    useEffect(() => {
      dispatch({type: 'LOADING_ENABLED'});
      APIService.getCampaigns()
      .then((res) =>  {
        let sched = []

        // FOR NOW, SHOW 'FAILED' CAMPAIGNS IN THE SCHEDULED SCREEN
        res.campaigns.forEach(cmp =>  cmp.status_name === 'scheduled' || cmp.status_name === 'failed' ? sched.push(cmp) : null )
        dispatch({type: 'GET_ALL_CAMPAIGNS', payload: sched })
        dispatch({type: 'LOADING_DISABLED'});
      })
      .catch((err) => console.log(err))
    }, [])

    useEffect( () =>  { dispatch({type: 'UPDATE_ACTIVE_STEP', payload: 'scheduled'}) }, [appState.activeStep] )

    return (
      <>
      <Header props={props} />
      <div className="container">

        { appState.campaigns && appState.campaigns.length > 0 ?
        <>
        <div className="row row-header justify-content-center mb-3 mt-5 ">
          <div className="col-sm-12 row">
            <div className="col-sm-2"><small className="th">How</small></div>
            <div className="col-sm-7"><small className="th">Send</small></div>
            <div className="col-sm-2"><small className="th">What</small></div>
            <div className="col-sm-1"><small className="th">When</small></div>
          </div>
        </div>
        </>
        :
        null }

        { appState.campaigns && appState.campaigns.length > 0?
            appState.campaigns.map( (cmp, i) => {
            const modified =  new Date(cmp.modified_at);
            return (
              <div className="row justify-content-center" key={i}>
                <div className="btn-row col-sm-12 pointer" onClick={() => campaignDetail(cmp)}>
                  <div className="col-auto col-sm-2 d-flex justify-content-start">
                    { cmp.mail_class && cmp.mail_class === 'First Class' ? <img src={imgMail} alt="" className="type" /> : null }
                    { cmp.mail_class && cmp.mail_class === "Certified Mail" ? <img src={imgErr} alt="" className="type" /> : null }
                    { cmp.mail_class && cmp.mail_class === 'First Class Certified Mail' ? <img src={imgBoth} alt="" className="type" /> : null }
                  </div>
                  <div className="col-auto col-sm-7">
                    {cmp.name}
                  </div>
                  <div className="col-auto col-sm-2">
                    {cmp.letter_count}
                  </div>
                  <div className="col-auto col-sm-1  justify-content-center">
                    <div className="calendar-day">
                    <small>{cmp.modified_at ? monthNames[modified.getMonth()].substring(0,3).toUpperCase() : 'JAN'}</small>
                    <h4>{cmp.modified_at ? modified.getDate() : '1'}</h4>
                    <span>{cmp.modified_at ? modified.getFullYear() : '2019'}</span>
                    </div>
                  </div>
                </div>
              </div>
            )
            })
          :

          <div className="full-center flex-column">
          <img src={NoDrafts} alt=""/>
          <h1 className="mt-5 text-center">
            There are no scheduled campaigns.
            <small>Press <img src={plus} className="plus-home" /> to create new send.</small>
          </h1>
          </div>
        }
      </div>

      </>
    );

}

export default Scheduled;
