export const formatMailClass = id => {
  switch (id) {
    case 1:
      return 'First-Class Mail';
    case 2:
      return 'Certified Mail® Services';
    case 3:
      return 'First Class + Certified Mail®';
    default:
      return null;
  }
};
