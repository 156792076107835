import {useState, useEffect, useContext} from 'react';
import {useLocation, useParams, useHistory} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';
import {handleException} from 'services/exceptions';
import {Context as SendContext} from 'contexts/send';

const STEPS = [
  'new',
  'how',
  'how/options',
  'who',
  'who/options',
  'where',
  'where/options',
  'what',
  'what/templates',
  'what/editor',
  'when',
  'confirm',
];

const isWhoStepComplete = sendObject =>
  (!sendObject.from_first_name && !sendObject.from_organization_name) ||
  (sendObject.from_address_line_1 && !sendObject.from_city_town);

const getRules = sendObject => ({
  new: true,
  how: Boolean(sendObject.name),
  'how/options': Boolean(sendObject.mail_class_id),
  who: Boolean(sendObject.mail_class),
  'who/options': Boolean(isWhoStepComplete(sendObject)),

  where: Boolean(sendObject.message1),
  'where/options': Boolean(sendObject.draft_data_source_id),

  what: true,
  when: Boolean(sendObject.csv_file),
  confirm: Boolean(sendObject.when),
});

const useStep = () => {
  const routerLocation = useLocation();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const {sendId} = useParams();
  const {send, setSend} = useContext(SendContext);
  const [toast, setToast] = useState(true);
  const [err, setErr] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(null);

  const id = Number(sendId);

  const toggletoast = () => setToast(!toast);

  const nextStep = (callback = () => {}) => {
    callback();
    const currentStepIndex = STEPS.indexOf(step);
    let nextStep = STEPS[currentStepIndex + 1];
    history.push(`/send/${id}/${nextStep}`);
  };

  const prevStep = () => {
    const currentStepIndex = STEPS.indexOf(step);
    let prevStep = STEPS[currentStepIndex - 1];
    const prevUrl = step === 'how' ? '/send/new' : `/send/${id}/${prevStep}`;
    history.push(prevUrl);
  };

  useEffect(() => {
    const redirect = selectedCampaign => {
      const rules = getRules(selectedCampaign);
      const blockedStep = Object.keys(rules).find(key => rules[key] === false);
      const currentStep = STEPS.find(step =>
        routerLocation.pathname.includes(step),
      );
      const currentStepIndex = STEPS.indexOf(step);
      setStep(currentStep);
      if (!rules[currentStep]) {
        const redirectUrl =
          blockedStep === 'new' ? 'new' : `${id}/${blockedStep}`;
        history.push(`/send/${redirectUrl}`);
        setToast(`You need to complete the ${blockedStep} step.`);
      }
    };

    const loadSend = async () => {
      await APIService.getCampaignDetails(id)
        .then(response => {
          setSend(response);
          // redirect(send);
        })
        .catch(err => {
          handleException(err);
        });
    };
    loadSend();
    setLoading(false);
  }, [setSend, history, id, step, routerLocation.pathname]);

  return {
    id,
    nextStep,
    prevStep,
    toast,
    toggletoast,
    err,
    setErr,
    setError,
    send,
    loading,
    error,
    step,
  };
};

export default useStep;
