import React from 'react';
import './ActionButton.scss';

const ActionButton = props => {
  return (
    <>
      {props.type === 'horizontal' ? (
        <div className="row mb-2">
          <button
            className={
              'card-button horizontal ' + (props.active ? 'active' : '')
            }
            onClick={props.action ? props.action : null}
            value={props.value}>
            <div className="card-body">
              <h4>{props.title}</h4>
              <p className="m-0">{props.subtitle}</p>
            </div>
          </button>
        </div>
      ) : (
        <div
          className={
            props.columns
              ? props.columns === 2
                ? 'col-sm-6 col-md-6 two-col position-relative'
                : 'col-sm-6 col-md-4 position-relative'
              : 'col-sm-6 col-md-4 position-relative'
          }>
          {props.modalClick ? (
            <button
              className="btn btn-outline-primary nb modal-toggle"
              onClick={props.modalClick}>
              {' '}
              ?{' '}
            </button>
          ) : null}
          <button
            className={
              'card-button ' +
              (props.active ? 'active' : '') +
              (props.disabled ? 'disabled' : '')
            }
            onClick={props.action ? props.action : null}
            value={props.value}>
            <div className="card-body">
              {props.img ? (
                <img
                  className={props.imgClass ? props.imgClass : ''}
                  src={props.img}
                  alt={props.title + ' - ' + props.subtitle}
                />
              ) : null}
              <h4>{props.title}</h4>
              <p className="m-0">{props.subtitle}</p>
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default ActionButton;
