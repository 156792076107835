import {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Context as SendContext} from 'contexts/send';

const useWhatStep = (send, setError) => {
  const history = useHistory();
  const {whatType, setWhatType} = useContext(SendContext);

  const handleChange = event => {
    event.preventDefault();
    setWhatType(event.target.value);
  };

  const reviewLetters = () => {
    history.push(`/reviewed/letterlist/${send.id}`);
  };

  const isPreMerged = send.campaign_type_id === 2

  useEffect(() => {
    if (send.campaign_type_id === 2) {
      setWhatType('print');
    }
  }, [send]);

  return {
    handleChange,
    whatType,
    reviewLetters,
    isPreMerged,
  };
};

export default useWhatStep;
