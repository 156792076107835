const TaxTablePartial = `
    <span style="font-size:14px;"><strong><span style="font-family:Arial,Helvetica,sans-serif;">TAXES</span></strong> </span>

    <table border="0" cellpadding="3" cellspacing="0" style="border:1px solid #333333;text-align:center;width:100%;">
        <tbody style="font-family: Arial, Helvetica, sans-serif;">
            <tr>
                <td style="background-color:#333;text-align: left;"><span style="font-size:12px;"><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;"><span style="color: rgb(255, 255, 255); font-weight: bold;">Year</span></span></span></span></td>
                <td style="background-color: #333;text-align: left;"><span style="font-size:12px;"><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;"><span style="color: rgb(255, 255, 255); font-weight: bold;">Type</span></span></span></span></td>
                <td style="background-color:#333; text-align: left;"><span style="font-size:12px;"><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;"><span style="color: rgb(255, 255, 255); font-weight: bold;">Base</span></span></span></span></td>
                <td style="background-color: #333; text-align: left;"><span style="font-size:12px;"><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;"><span style="color: rgb(255, 255, 255); font-weight: bold;">Interests</span></span></span></span></td>
                <td style="background-color:#333; text-align: left; "><span style="font-size:12px;"><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;"><span style="color: rgb(255, 255, 255); font-weight: bold;">Penalty</span></span></span></span></td>
                <td style="background-color: #333; text-align: left;"><span style="font-size:12px;"><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;"><span style="color: rgb(255, 255, 255); font-weight: bold;">Collection</span></span></span></span></td>
                <td style="background-color: #333; text-align: left;"><span style="font-size:12px;"><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;"><span style="color: rgb(255, 255, 255); font-weight: bold;">Total</span></span></span></span></td>
            </tr>
            <tr>
                <td style="text-align: left; font-family: Arial, Helvetica, sans-serif;max-width: 80px;"><span style="font-size:12px;"><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{tablerow from=$taxtable item=t1}{tableif! empty($t1.year)}{$t1.year}</span></span></span></td>
                <td style="text-align: left; font-family: Arial, Helvetica, sans-serif"><span style="font-size:12px;"><span style="font-size:12px;min-width:200px"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$t1.type}</span></span></span></td>
                <td style="text-align: left; font-family: Arial, Helvetica, sans-serif"><span style="font-size:12px;"><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$t1.base}</span></span></span></td>
                <td style="text-align: left; font-family: Arial, Helvetica, sans-serif"><span style="font-size:12px;"><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$t1.int}</span></span></span></td>
                <td style="text-align: left; font-family: Arial, Helvetica, sans-serif"><span style="font-size:12px;"><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$t1.pen}</span></span></span></td>
                <td style="text-align: left; font-family: Arial, Helvetica, sans-serif"><span style="font-size:12px;"><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$t1.collect}</span></span></span></td>
                <td style="text-align: left; font-family: Arial, Helvetica, sans-serif"><span style="font-size:12px;"><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$t1.Total}{/tableif}{/tablerow}</span></span></span></td>
            </tr>
            <tr>
                <td style="text-align: left"><span style="font-size:12px;"><strong><span style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;">Total</span></span></strong></span></td>
                <td style="text-align: left"><span style="font-size:12px;">{$ttype} </span></td>
                <td style="text-align: left"><span style="font-size:12px;"><strong><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$tbase}</span></span></strong></span></td>
                <td style="text-align: left"><span style="font-size:12px;"><strong><span class="highlight_yellow" style="font-size:12px;"><span style="font-family:Arial,Helvetica,sans-serif;">{$tint}</span></span></strong></span></td>
                <td style="text-align: left"><span style="font-size:12px;"><strong><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$tpen}</span></span></strong></span></td>
                <td style="text-align: left"><span style="font-size:12px;"><strong><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$tcollect}</span></span></strong></span></td>
                <td style="text-align: left"><span style="font-size:12px;"><strong><span style="font-size:12px;"><span class="highlight_yellow" style="font-family:Arial,Helvetica,sans-serif;">{$ttotal}</span></span></strong></span></td>
            </tr>
        </tbody>
    </table>
    <br />

    </div>`;

export default TaxTablePartial;
