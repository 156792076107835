import * as React from 'react';

const NotFound = () => (
  <div>
    <h3>404</h3>
    <p>Not found</p>
  </div>
);

export default NotFound;
