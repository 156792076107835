import React from 'react';
import StepLayout from 'layouts/Step';
import ActionButton from 'components/ActionButton/ActionButton';
import imgMail from 'assets/img/ico_mail.svg';
import imgERR from 'assets/img/ico_err.svg';
import imgBoth from 'assets/img/ico_both.svg';
import {formatMailClass} from 'helpers/mailClass';
import useHowStep from './hooks';

const HowStepOptions = props => {
  const {step, mailClassId, handleSelect, error, disabled} = useHowStep();
  return (
    <StepLayout
      {...props}
      disabled={disabled}
      error={error}
      step={step}
      nextLink={mailClassId === 1 ? `who` : `how/options`}
      previousLink={`name`}>
      <h1 className="mt-5">How are we sending?</h1>
      <div className="row mx-0 text-center mt-5">
        <ActionButton
          title={formatMailClass(1)}
          subtitle="Starting at $0.70."
          img={imgMail}
          imgClass="fcm-icon"
          action={() => handleSelect(1)}
          active={mailClassId === 1}
        />

        <ActionButton
          title={formatMailClass(2)}
          subtitle="Starting at $7.30."
          img={imgERR}
          action={() => handleSelect(2)}
          active={mailClassId === 2}
        />

        <ActionButton
          disabled
          title={formatMailClass(3)}
          subtitle="Starting at $8.00."
          img={imgBoth}
          active={mailClassId === 3}
        />
      </div>
    </StepLayout>
  );
};

export default HowStepOptions;
