export const modifyCampaignObject = (campaign) => {
    let Obj = {}
    Obj.id = campaign.id
    Obj.name = campaign.name
    
    switch(campaign.mail_class_id) {
      case 1:
        Obj.how = 'First Class';
        break;
      case 2:
        Obj.how = 'Certified Mail Services';
        break;
      case 3:
        Obj.how = 'First Class Certified Mail';
        break;
      default:
        Obj.how = null;
    }
    
    Obj.howType = campaign.delivery_confirmation;
    Obj.sender = {}
    Obj.sender.first_name = campaign.from_first_name
    Obj.sender.last_name = campaign.from_last_name
    Obj.sender.from_organization_name = campaign.from_organization_name
    Obj.sender.middle_name = campaign.from_middle_name
    Obj.sender.address1 = campaign.from_address_line_1
    Obj.sender.address2 = campaign.from_address_line_2
    Obj.sender.city = campaign.from_city_town
    Obj.status_name = campaign.status_name
    Obj.sender.state = USAStates.find(data => data.value === campaign.from_state_province_district);;
    Obj.sender.zip = campaign.from_postal_code
    Obj.where = campaign.data_source_id ? campaign.data_source_id+'/senddata.csv' : null;
    Obj.what = {templateType: null}
    if(campaign.template_do_merge === false) {
      Obj.what.type = 'print';
    } else if (campaign.template_do_merge === null || campaign.template_do_merge === undefined){
      Obj.what.type = null;
    }
    else {
      Obj.what.type = 'merge';
    }
    Obj.what.file = campaign.template_id ? campaign.template_id+'/'+campaign.template_name : null;
    if(campaign.send_on_date !== null && campaign.send_on_date !== '') { Obj.when = new Date(campaign.send_on_date) }
    else { Obj.when = null }

    if(campaign.document_count != null && campaign.document_count != '') {
      try {
        Obj.numLetters = parseInt(campaign.document_count);
      }
      catch(e) {
        Obj.numLetters = 0;
      }
    }
    else {
      Obj.numLetters = 0;
    }

    Obj.metadata = campaign.metadata || {};

    Obj.options = {
      coversheet: campaign.coversheet || false,
      perf: campaign.perf || false,
      returnEnv: campaign.return_envelope || false,
      alertColor: campaign.alert_color_name || 'none',
      message1: campaign.message1,
      message2: campaign.message2
    }

    return Obj
}

export const splitName = (full_name) => {
    let obj = {
        first_name: '',
        last_name: '',
        middle_name: ''
    }
    const splitName = full_name.split(' ')

    if (splitName.length > 0) {
        switch (splitName.length) {
            case 1:
                obj.first_name = splitName[0];
                break;
            case 2: 
                obj.first_name = splitName[0];
                obj.last_name = splitName[1];
                break;
            case 3: 
                obj.first_name = splitName[0];
                obj.middle_name = splitName[1];
                obj.last_name = splitName[2]; 
                break;
            case 4: 
                obj.first_name = splitName[0];
                obj.middle_name = splitName[1] + splitName[2];
                obj.last_name = splitName[3]; 
                break;
            default:
                obj.first_name = splitName[0];
                break;
        }
    }
    return obj;
}

export const USAStates = [
    {
        label: "Alabama",
        value: "AL"
    },
    {
        label: "Alaska",
        value: "AK"
    },
    {
        label: "Arizona",
        value: "AZ"
    },
    {
        label: "Arkansas",
        value: "AR"
    },
    {
        label: "California",
        value: "CA"
    },
    {
        label: "Colorado",
        value: "CO"
    },
    {
        label: "Connecticut",
        value: "CT"
    },
    {
        label: "Delaware",
        value: "DE"
    },
    {
        label: "District Of Columbia",
        value: "DC"
    },
    {
        label: "Florida",
        value: "FL"
    },
    {
        label: "Georgia",
        value: "GA"
    },
    {
        label: "Hawaii",
        value: "HI"
    },
    {
        label: "Idaho",
        value: "ID"
    },
    {
        label: "Illinois",
        value: "IL"
    },
    {
        label: "Indiana",
        value: "IN"
    },
    {
        label: "Iowa",
        value: "IA"
    },
    {
        label: "Kansas",
        value: "KS"
    },
    {
        label: "Kentucky",
        value: "KY"
    },
    {
        label: "Louisiana",
        value: "LA"
    },
    {
        label: "Maine",
        value: "ME"
    },
    {
        label: "Maryland",
        value: "MD"
    },
    {
        label: "Massachusetts",
        value: "MA"
    },
    {
        label: "Michigan",
        value: "MI"
    },
    {
        label: "Minnesota",
        value: "MN"
    },
    {
        label: "Mississippi",
        value: "MS"
    },
    {
        label: "Missouri",
        value: "MO"
    },
    {
        label: "Montana",
        value: "MT"
    },
    {
        label: "Nebraska",
        value: "NE"
    },
    {
        label: "Nevada",
        value: "NV"
    },
    {
        label: "New Hampshire",
        value: "NH"
    },
    {
        label: "New Jersey",
        value: "NJ"
    },
    {
        label: "New Mexico",
        value: "NM"
    },
    {
        label: "New York",
        value: "NY"
    },
    {
        label: "North Carolina",
        value: "NC"
    },
    {
        label: "North Dakota",
        value: "ND"
    },
    {
        label: "Ohio",
        value: "OH"
    },
    {
        label: "Oklahoma",
        value: "OK"
    },
    {
        label: "Oregon",
        value: "OR"
    },
    {
        label: "Palau",
        value: "PW"
    },
    {
        label: "Pennsylvania",
        value: "PA"
    },
    {
        label: "Puerto Rico",
        value: "PR"
    },
    {
        label: "Rhode Island",
        value: "RI"
    },
    {
        label: "South Carolina",
        value: "SC"
    },
    {
        label: "South Dakota",
        value: "SD"
    },
    {
        label: "Tennessee",
        value: "TN"
    },
    {
        label: "Texas",
        value: "TX"
    },
    {
        label: "Utah",
        value: "UT"
    },
    {
        label: "Vermont",
        value: "VT"
    },
    {
        label: "Virginia",
        value: "VA"
    },
    {
        label: "Washington",
        value: "WA"
    },
    {
        label: "West Virginia",
        value: "WV"
    },
    {
        label: "Wisconsin",
        value: "WI"
    },
    {
        label: "Wyoming",
        value: "WY"
    }
]

export const monthNames = [
    "January", 
    "February", 
    "March", 
    "April", 
    "May", 
    "June",
    "July", 
    "August", 
    "September", 
    "October", 
    "November", 
    "December"
];

export const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
]

export const resizeImage = (file, max) => {
  console.log('file', file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement('canvas');
        const ctx = elem.getContext('2d');
        var scaleFactor = 0;
        if (img.width >= img.height && img.width > max) {
          scaleFactor = max / img.width;
          elem.width = max;
          elem.height = img.height * scaleFactor;
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          resolve(ctx.canvas.toDataURL());
        }
        else if (img.height >= img.width && img.height > max) {
          scaleFactor = max / img.height;
          elem.width = img.width * scaleFactor;
          elem.height = max;
          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          resolve(ctx.canvas.toDataURL());
        }
        else {
          resolve(event.target.result);
        }
      };
      reader.onerror = error => reject(error);
    };
  })
}

export const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      borderRadius: 0,
      borderColor: '#ced4da',
      borderRight: 'none',
      borderLeft: 'none',
      borderTop: 'none',
      boxShadow: null,
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontWeight: 'lighter',
      fontSize: '20px',
      letterSpacing: '1px',
      padding: '0.5rem 0',
      width: '100%',
      paddingBottom: '3px'
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      hyphens: "auto",
      marginTop: 0,
      textAlign: "left",
      wordWrap: "break-word",
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    })
  };

export const fetchAsBlob = url => fetch(url)
    .then(response => response.blob());

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const convertBlobToArrayBuffer = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onloadend = () => {
        resolve(reader.result);
    };
    reader.readAsArrayBuffer(blob);
});

export const base64ToArrayBuffer = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}