import React from 'react';
import './ProgressNav.scss';
import {useParams, useHistory} from 'react-router-dom';
import imgHow from 'assets/img/nav_how.svg';
import imgWho from 'assets/img/nav_who.svg';
import imgWhere from 'assets/img/nav_where.svg';
import imgWhen from 'assets/img/nav_when.svg';
import imgWhat from 'assets/img/nav_what.svg';
import imgSend from 'assets/img/nav_send.svg';

const ProgressNav = ({step}) => {
  const history = useHistory();
  const {sendId} = useParams();

  const howLink = () => history.push(`/send/${sendId}/how`);
  const whoLink = () => history.push(`/send/${sendId}/who`);
  const whereLink = () => history.push(`/send/${sendId}/where`);
  const whatLink = () => history.push(`/send/${sendId}/what`);
  const whenLink = () => history.push(`/send/${sendId}/when`);
  const sendLink = () => history.push(`/send/${sendId}/confirm`);

  return (
    <div className={'progress-nav-wrap ' + {step}}>
      <div className="progress-nav container">
        <div className="row d-flex align-items-center justify-content-between">
          <div className={'tab first ' + (step === 'how' ? 'active how' : '')}>
            <div onClick={howLink}>
              <img src={imgHow} alt="" />
              <span>How</span>
            </div>
          </div>
          <div className={'tab who ' + (step === 'who' ? 'active' : '')}>
            <div onClick={whoLink}>
              <img src={imgWho} alt="" />
              <span>Who</span>
            </div>
          </div>
          <div className={'tab where ' + (step === 'where' ? 'active' : '')}>
            <div onClick={whereLink}>
              <img src={imgWhere} alt="" />
              <span>Where</span>
            </div>
          </div>
          <div className={'tab what ' + (step === 'what' ? 'active' : '')}>
            <div onClick={whatLink}>
              <img src={imgWhat} alt="" />
              <span>What</span>
            </div>
          </div>
          <div className={'tab when ' + (step === 'when' ? 'active' : '')}>
            <div onClick={whenLink}>
              <img src={imgWhen} alt="" />
              <span>When</span>
            </div>
          </div>
          <div className={'tab last send ' + (step === 'send' ? 'active' : '')}>
            <div onClick={sendLink}>
              <img src={imgSend} alt="" />
              <span>Send</span>
            </div>
          </div>
        </div>
        <div className={'progress-bar ' + (step ? step : '')}></div>
      </div>
    </div>
  );
};

export default ProgressNav;
