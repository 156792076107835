import React, {useEffect} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {CTX} from 'store';
import APIService from 'services/backstrap/apiService';
import Header from 'components/Header/Header';
import Checkbox from 'components/Checkbox/Checkbox';
import LetterDetailsModal from 'components/LetterDetailsModal/LetterDetailsModal';
import imgMail from 'assets/img/ico_mail.svg';
import imgDownload from 'assets/img/ico_download.svg';
import imgEnvelope from 'assets/img/ico_envelope.svg';
import ReactPaginate from 'react-paginate';
import {monthNames, modifyCampaignObject} from 'services/util';
import useLetterList from './hooks';

const LetterList = props => {
  document.title = `Details`;
  const history = useHistory();
  const {send, cid, letterIdFromParams, showModal, openModal, closeModal} = useLetterList();
  const [appState, dispatch] = React.useContext(CTX);
  const [viewState, setView] = React.useState({letterStatuses: []});
  const [menuState, showMenu] = React.useState({show: false, count: 0});
  const [searchState, setSearch] = React.useState({term: '', results: null});
  const [letter, setLetter] = React.useState(null);
  const [documentsTotal, setDocumentsTotal] = React.useState([]);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const pageSize = 10;
  const [selectedDocuments, setSelectedDocuments] = React.useState({});
  const [allDocumentIds, setAllDocumentIds] = React.useState([]);

  const letterDetail = letterInfo => {
    if (!['draft', 'scheduled'].includes(letterInfo.status_name)) {
      setLetter(letterInfo);
      openModal()
    }
  };

  const selectBoxClick = (evt, ltr) => {
    if (evt.target.checked) {
      setSelectedDocuments({
        ...selectedDocuments,
        [ltr.id]: true,
      });
    } else {
      delete selectedDocuments[ltr.id];
      setSelectedDocuments({...selectedDocuments});
    }
  };

  const selectAll = evt => {
    if (evt.target.checked) {
      var selectedDocs = {};
      allDocumentIds.forEach(doc => {
        selectedDocs[doc] = true;
      });
      setSelectedDocuments(selectedDocs);
    } else {
      setSelectedDocuments({});
    }
  };

  const followupSend = () => {
    dispatch({type: 'LOADING_ENABLED'});

    var resends = Object.keys(selectedDocuments);

    var followupArgs = {
      documents: resends,
      parent_campaign_id: cid,
    };
    APIService.postFollowupCampaign(followupArgs)
      .then(res => {
        const selectedCampaign = modifyCampaignObject(res);
        dispatch({type: 'SET_ACTIVE_SEND', payload: selectedCampaign});
        history.push('/send/new/');
      })
      .catch(post_err => {
        console.log(post_err);
      })
      .finally(() => {
        dispatch({type: 'LOADING_DISABLED'});
      });
  };

  const downloadSelected = () => {
    Object.keys(selectedDocuments).forEach(documentId => {
      APIService.getDocument(documentId)
        .then(lDet => {
          window.open(lDet.merged_doc_uri);
        })
        .catch(lDet_err => {
          console.log(lDet_err);
        });
    });
  };

  const searchDocuments = async () => {
    try {
      const documentIds = await APIService.getCampaignDocumentIds(cid);
      setAllDocumentIds(documentIds);
      const search = await APIService.searchDocuments({
        limit: pageSize,
        offset: pageOffset,
        search_string: searchState.term || '',
        campaign_id: cid,
      });
      setDocumentsTotal(search.total_count);
      setView(lastState => ({
        letterStatuses: search.documents,
      }));
    } catch (err) {
      alert('There was a problem searching for letters');
    }
  };

  const resetAndSearch = e => {
    if (e != null) e.preventDefault();

    setCurrentPage(0);
    // CHANGING THE PAGE OFFSET RUNS THE SEARCH, BUT IF WE ARE ALREADY
    // ON THE FIRST PAGE, WE NEED TO FIRE THE SEARCH FUNCTION MANUALLY
    if (pageOffset !== 0) {
      setPageOffset(0);
    } else {
      searchDocuments();
    }
  };

  // WHEN THE PAGE OFFSET CHANGES, RERUN THE SEARCH WITH THAT OFFSET
  useEffect(() => {
    if (!!cid) {
      searchDocuments();
    }
  }, [pageOffset]);

  useEffect(() => {
    if (viewState.letterStatuses) {
      showMenu({show: true, count: Object.keys(selectedDocuments).length});
    }
  }, [selectedDocuments]);

  useEffect(() => {
    if (!cid) {
      APIService.getCampaignDetails(cid).then(res => {
        const selectedCampaign = modifyCampaignObject(res);
        dispatch({type: 'SET_ACTIVE_SEND', payload: selectedCampaign});
      });
    }
    dispatch({type: 'UPDATE_ACTIVE_STEP', payload: 'list'});
  }, [appState.activeStep]);

  return (
    <>
      <Header props={props} />
      <div className="container">
        {
          <>
            <div className="mt-5 mb-5 text-center">
          {send && (!['started', 'reviewed'].includes(send.status_name) && send.locked_at != null) ? (
                <h1>Details</h1>
              ) : (
                <div className="d-flex justify-content-between">
                  <h1>Letter List</h1>
                  <button
                    className="btn btn-link btn-lg btn-outline-primary"
                    onClick={() => history.push(`/send/${send.id}/confirm`)}>
                    Edit Send
                  </button>
                </div>
              )}
              {menuState.show && menuState.count > 0 ? (
                <div className="action-row d-flex align-items-center">
                  <span className="font-sm mr-4">
                    {menuState.count > 0 ? menuState.count : 0} selected
                  </span>
                  {send && send.status_name !== 'reviewed' ? (
                    <button
                      className="btn btn-link btn-sm mr-4"
                      onClick={followupSend}>
                      <img
                        src={imgEnvelope}
                        style={{width: '15px'}}
                        alt="Resend"
                      />{' '}
                      Resend
                    </button>
                  ) : (
                    ''
                  )}
                  <button
                    className="btn btn-link btn-sm"
                    onClick={downloadSelected}>
                    <img src={imgDownload} alt="Download" /> Download
                  </button>
                </div>
              ) : null}
            </div>
            <div className="row mb-5">
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search through results"
                  value={searchState.term}
                  onChange={e =>
                    setSearch({...searchState, term: e.target.value})
                  }
                  onKeyPress={ev =>
                    ev.key === 'Enter' ? resetAndSearch(ev) : null
                  }
                />
              </div>
              <div className="col-sm-2">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={resetAndSearch}
                  style={{height: '100%'}}>
                  Search
                </button>
              </div>
            </div>
            <div className="row row-header justify-content-center mb-4 position-relative">
              <div className="check-parent">
                <Checkbox
                  id="unchecked"
                  label="Unchecked"
                  onClick={selectAll}
                  checked={
                    allDocumentIds.length > 0 &&
                    Object.keys(selectedDocuments).length ==
                      allDocumentIds.length
                  }
                />
              </div>
              <div className="col-sm-12 row">
                <div className="col-sm-1">
                  <small className="th">How</small>
                </div>
                <div className="col-sm-3">
                  <small className="th">Where</small>
                </div>
                <div className="col-sm-2">
                  <small className="th">Sender</small>
                </div>
                <div className="col-sm-3">
                  <small className="th">Send</small>
                </div>
                <div className="col-sm-2">
                  <small className="th">Result</small>
                </div>
                <div className="col-sm-1">
                  <small className="th">When</small>
                </div>
              </div>
            </div>
          </>
        }
        {viewState.letterStatuses.length > 0 ? (
          <div>
            {viewState.letterStatuses.map((ltr, i) => {
              const modified = new Date(send.send_on_date);
              return (
                <div
                  className={
                    'row justify-content-center position-relative check-row active'
                  }
                  key={i}>
                  <div className="check-container">
                    <Checkbox
                      id="unchecked"
                      label="Unchecked"
                      checked={selectedDocuments[ltr.id] === true}
                      onClick={evt => {
                        selectBoxClick(evt, ltr);
                      }}
                    />
                  </div>
                  <div
                    className={
                      'btn-row col-sm-12 pointer justify-content-start'
                    }>
                    <div
                      className="col-auto col-sm-1 d-flex justify-content-start"
                      onClick={() => letterDetail(ltr)}>
                      <img src={imgMail} alt="" className="type" />
                    </div>
                    <div
                      className="col-auto col-sm-3 truncate-text"
                      onClick={() => letterDetail(ltr)}>
                      {/* {ltr.first_name+(ltr.last_name != null && ltr.last_name !== 'null' ? ' '+ltr.last_name+(ltr.suffix != null && ltr.suffix !== 'null' ? ' '+ltr.suffix : '')  : '')} */}
                      {ltr.address_line_1 +
                        ' ' +
                        ltr.city_town +
                        ', ' +
                        ltr.state_province_district +
                        ' ' +
                        ltr.postal_code}
                    </div>
                    <div
                      className="col-auto col-sm-2 truncate-text"
                      onClick={() => letterDetail(ltr)}>
                      {send.from_organization_name ||
                        send.from_first_name + '' + send.from_last_name}
                    </div>
                    <div
                      className="col-auto col-sm-3 truncate-text"
                      onClick={() => letterDetail(ltr)}>
                      {send.name}
                    </div>
                    <div
                      className="col-auto col-sm-2"
                      onClick={() => letterDetail(ltr)}>
                      {ltr.status_name !== 'draft' ? (
                        <span className="btn btn-outline-danger nb status-btn">
                          {ltr.status_name}
                        </span>
                      ) : (
                        <div />
                      )}
                    </div>
                    <div className="col-auto col-sm-1  justify-content-center">
                      <div className="calendar-day">
                        <small>
                          {send.send_on_date
                            ? monthNames[modified.getUTCMonth()]
                                .substring(0, 3)
                                .toUpperCase()
                            : 'JAN'}
                        </small>
                        <h4>
                          {send.send_on_date ? modified.getUTCDate() : '1'}
                        </h4>
                        <span>
                          {send.send_on_date
                            ? modified.getUTCFullYear()
                            : '2019'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className={'mt-5'}
              style={{
                textAlign: 'center',
              }}>
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(documentsTotal / pageSize)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={data => {
                  setCurrentPage(data.selected);
                  setPageOffset(Math.ceil(data.selected * pageSize));
                }}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={currentPage}
              />
            </div>
          </div>
        ) : (
          <h3>This campaign has no letters</h3>
        )}
      </div>
      {showModal && (
        <LetterDetailsModal
          lid={letterIdFromParams || (letter || {}).id}
          allDocumentIds={allDocumentIds}
          close={closeModal}
        />
      )}
    </>
  );
};

export default LetterList;
