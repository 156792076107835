import {useState, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';
import {Context as SendContext} from 'contexts/send';

const useHowStep = nextStep => {
  const {sendId} = useParams();
  const {send} = useContext(SendContext);
  const [disabled, setDisabled] = useState(true);
  const [mailClassId, setMailClassId] = useState(null);
  const [error, setError] = useState(null);
  const [howType, setHowType] = useState(false);

  const calcPrice = how => {
    var price = (0.7).toFixed(2);
    switch (how) {
      case 2: // Certified Mail Services
        price = (7.3).toFixed(2);
        break;
      case 3: // First Call + Certified Mail
        price = (8.0).toFixed(2);
        break;
      default:
        return null;
    }

    return price;
  };

  const handleChange = async value => {
    try {
      await APIService.patchCampaign({id: sendId, delivery_confirmation: value});
      setHowType(value)
    } catch (err) {
      const errorMessage =
        err.data && err.data.message
          ? err.data.message
          : 'Failed to set mail class';
      setError(errorMessage);
    }
    setDisabled(false);
  };

  useEffect(() => {
    if (send.mail_class_id) {
      setMailClassId(send.mail_class_id);
    }
    if (send.delivery_confirmation) {
      setHowType(send.delivery_confirmation);
      setDisabled(false);
    }
  }, [send.mail_class_id, send.delivery_confirmation]);

  return {
    calcPrice,
    howType,
    handleChange,
    disabled,
    mailClassId,
    error,
  };
};

export default useHowStep;
