import React from 'react';
import useFroala from './hooks';

//editor CSS
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import './styles.scss';

//to enable all froala plugins
//import 'froala-editor/js/plugins.pkgd.min.js';

//to enable single plugins
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/entities.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import './html-removable-plugin.js';
import './mergetag-plugin.js';

import FroalaEditorComponent from 'react-froala-wysiwyg';

//needed for custom icons
import 'font-awesome/css/font-awesome.css';

export const Froala = ({
  mergeTags,
  addNewMergeTagPress,
  editorContent,
  onEditorContentChange,
}) => {
  const {froalaConfig, tagStatus} = useFroala(
    mergeTags,
    addNewMergeTagPress,
  );

  if (Object.keys(mergeTags).length <= 1) {
    return <span>Loading...</span>;
  }

  return (
    <>
      {tagStatus && (
        <div className="froala-merge-tags-wrap row mb-5">
          {tagStatus.length > 0 &&
            tagStatus.map((tag, i) => (
              <div key={i} className={`col-sm-4 ${tag.active && 'active'}`}>
                {tag.name} {tag.active}{' '}
                <i
                  className={`fa fa-${
                    tag.active ? 'check-square-o' : 'times-rectangle'
                  }`}></i>
              </div>
            ))}
        </div>
      )}
      <div className="froala-wrap">
        <FroalaEditorComponent
          config={froalaConfig}
          model={editorContent}
          onModelChange={onEditorContentChange}
        />
      </div>
    </>
  );
};
