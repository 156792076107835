import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://cc902517591e46c2b4b9c58e935bf0e2@o424614.ingest.sentry.io/5357313',
    environment: process.env.NODE_ENV,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
