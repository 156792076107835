import {useContext} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';
import {Context as SendContext} from 'contexts/send';

const useEditor = (
  loadHTMLFileIntoEditor,
  docFileState,
  setDocFileState,
  setErr,
  editorContent,
  send,
) => {
  const {whatType, templateType} = useContext(SendContext);
  const {templateId} = useParams();
  const history = useHistory();
  const saveHTMLTemplateData = async () => {
    const encodedData = window.btoa(
      unescape(encodeURIComponent(editorContent)),
    );
    let newFileName = docFileState.fileName;
    // TEST IF THIS ALREADY HAS A DATE STAMPED ON IT
    let hasTimestampRegex = /\(.*[AM PM]\)$/;
    if (newFileName && newFileName.match(hasTimestampRegex) != null) {
      newFileName = newFileName.substring(0, newFileName.lastIndexOf(' ('));
    }
    let rawDate = new Date();
    let year = rawDate.getFullYear();
    let month = rawDate.getMonth();
    let day = rawDate.getDate();
    let time = rawDate.toLocaleTimeString('en-us');
    let dateString = ' (' + year + '-' + month + '-' + day + ' ' + time + ')';
    newFileName += dateString;
    setDocFileState(prevState => {
      return {
        ...prevState,
        docFile: {name: newFileName || 'Custom HTML Template'},
        base64String: encodedData,
        fileName: newFileName || 'Custom HTML Template',
        fileExt: 'html',
      };
    });

    try {
      if (send.template_id === Number(templateId)) {
        await APIService.putTemplate({
          id: templateId,
          merge: true,
          file_name: newFileName || 'Custom HTML Template',
          file: {
            body: encodedData,
            ext: 'html',
          },
        });
      } else {
        await APIService.postCampaignTemplate({
          campaign_id: send.id,
          merge: true,
          file_name: newFileName || 'Custom HTML Template',
          file: {
            body: encodedData,
            ext: 'html',
          },
        });
      }
      history.push(`/send/${send.id}/when`);
    } catch (err) {
      if (err.data && err.data.message) {
        setErr(err.data.message);
      } else {
        setErr('Failed to update this template');
      }
    }
  };

  return {
    saveHTMLTemplateData,
  };
};

export default useEditor;
