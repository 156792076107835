
//documenation on froala plugins
//https://froala.com/wysiwyg-editor/docs/concepts/custom/plugin/
//documentation on froala popups
//https://froala.com/wysiwyg-editor/docs/concepts/custom/popup/


import Froalaeditor from 'froala-editor';

// Define popup template.
Object.assign(Froalaeditor.POPUP_TEMPLATES, {
  'htmlRemovable.popup': '[_BUTTONS_][_CUSTOM_LAYER_]'
});

// Define popup buttons.
Object.assign(Froalaeditor.DEFAULTS, {
  popupButtons: ['remove'],
});

// The custom popup is defined inside a plugin (new or existing).
Froalaeditor.PLUGINS.htmlRemovable = function (editor) {
  var k = editor.$;

  // Create custom popup.
  function initPopup () {
    // Load popup template.
    var template = Froalaeditor.POPUP_TEMPLATES.customPopup;
    if (typeof template == 'function') template = template.apply(editor);

    // Popup buttons.
    var popup_buttons = '';

    // Create the list of buttons.
    if (editor.opts.popupButtons.length > 0) {
      popup_buttons += '<div class="fr-buttons">';
      popup_buttons += editor.button.buildList(editor.opts.popupButtons);
      popup_buttons += '</div>';
    }

    // Load popup template.
    var template = {
      buttons: popup_buttons,
      custom_layer: ''
      // custom_layer: '<div class="custom-layer">Hello World!</div>'
    };

    // Create popup.
    var $popup = editor.popups.create('htmlRemovable.popup', template);

    return $popup;
  }

  // Show the popup
  function showPopup () {

    var $popup = editor.popups.get('htmlRemovable.popup');
    if (!$popup) $popup = initPopup();


    editor.popups.setContainer("htmlRemovable.popup", editor.$sc);

    //set the popup relative to the element
    var element = k(editor.selection.element());
    var left = element.offset().left + element.outerWidth() / 2;
    var top = element.offset().top + element.outerHeight();
    editor.popups.show("htmlRemovable.popup", left, top, element.outerHeight(), !0);

    //blur the selection
    editor.events.disableBlur(); editor.$el.blur(); editor.events.enableBlur();
  }

  // Hide the custom popup.
  function hidePopup () {
    editor.popups.hide('htmlRemovable.popup');
  }


  function removeHtmlRemovable () {
    //some voodoo to remove the selection
    //look up 5 elements
    for(var i = 0; i < 5; i++){
      var element;
      if(!editor.node.isDeletable(editor.selection.element()))
      {
        element = k(editor.selection.element());
        editor.selection.setAfter(element);
        editor.selection.restore();
      }
      else{
        break;
      }
    }

    //once we have the deletable element, move to the end of it, and hit backspace
    element = k(editor.selection.element());
    editor.selection.setAfter(element);
    editor.selection.restore();


    editor.cursor.backspace();

    editor.popups.hide('htmlRemovable.popup');
  }

  function handleAlternateInteraction(r){
    if (r.type == "mouseup" || r.which != Froalaeditor.KEYCODE.ESC && editor.core.hasFocus()) {
      if(editor.node.hasClass(editor.selection.element(), 'LF-REMOVEABLE-PIECE')){
        r.preventDefault();
        showPopup();
        return false;
      }
    }
  }

  function _init () {
    // You can access any option from documentation or your custom options.

    editor.events.on("keyup", function (e) {
        return handleAlternateInteraction(e);
    });
    editor.events.on("window.mouseup", function(e){ return handleAlternateInteraction(e); });

    editor.events.$on(editor.$el, "click", ".LF-REMOVEABLE", function (e) {
        e.preventDefault();
        showPopup();
        editor.edit.isDisabled() && e.preventDefault();
    });

  }

  // Methods visible outside the plugin.
  return {
    _init: _init,
    showPopup: showPopup,
    hidePopup: hidePopup,
    removeHtmlRemovable: removeHtmlRemovable
  }
}

// Define custom popup close button icon and command.
Froalaeditor.DefineIcon('remove', { NAME: "close", SVG_KEY: 'close'});
Froalaeditor.RegisterCommand('remove', {
  title: 'Remove',
  undo: false,
  focus: false,
  callback: function () {
    this.htmlRemovable.removeHtmlRemovable();
  }
});

