import React, {useState} from 'react';
const Context = React.createContext({});
const initialSendState = {
  id: null,
  name: null,
  how: null,
  howType: null,
  options: {
    coversheet: false,
    perf: false,
    returnEnv: false,
    alertColor: null,
    message1: null,
    message2: null,
  },
  sender: {
    first_name: null,
    last_name: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    from_organization_name: null,
  },
  where: null,
  what: {
    type: 'merge',
    file: null,
    templateType: null,
  },
  when: null,
  numLetters: 0,
  metadata: {
    organization_logo: null,
    logo_path: null,
  },
  datasource: null,
};

const Provider = ({children}) => {
  const [send, setSend] = useState(initialSendState);
  const [activeStep, setActiveStep] = useState(false);
  const [datasourceId, setDatasourceId] = useState(null);
  const [whatType, setWhatType] = useState('merge');
  const [templateType, setTemplateType] = useState(null);
  const [numberOfLetters, setNumberOfLetters] = useState(0);

  return (
    <Context.Provider
      value={{
        send,
        setSend,
        activeStep,
        setActiveStep,
        datasourceId,
        setDatasourceId,
        whatType,
        setWhatType,
        templateType,
        setTemplateType,
        setNumberOfLetters,
        numberOfLetters,
      }}>
      {children}
    </Context.Provider>
  );
};

export {Provider, Context};
