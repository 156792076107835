import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';

const useWhatStep = (send, setError) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [templates, setTemplates] = useState({templates: []});
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [showCurrentTemplate, setShowCurrentTemplate] = useState(null);
  const [showUploadTemplate, setShowUploadTemplate] = useState(false);

  const selectWhatTemplateType = templateType => {
    switch (templateType) {
      case 'createTemplate':
        return history.push(`/send/${send.id}/what/templates/editor`);
      case 'uploadTemplate':
        return setShowUploadTemplate(true);
      case 'selectTemplate':
        setShowUploadTemplate(false);
        return history.push(`/send/${send.id}/what/templates`);
      default:
        return history.push(`/send/${send.id}/what/templates`);
    }
  };

  const previewTemplate = selected => {
    console.log(selected);
    setActiveTemplate(selected);
  };

  //select template from list
  const selectTemplate = async () => {
    if (['docx', 'doc'].includes((activeTemplate.ext))) {
      await APIService.postCampaignTemplate({
        campaign_id: send.id,
        template_id: activeTemplate.id,
      })
      history.push(`/send/${send.id}/when`);
    } else {
      history.push(`/send/${send.id}/what/templates/${activeTemplate.id}/editor`);
    }
  };

  const handlePageChange = data => {
    setOffset(Math.ceil(data.selected * pageCount));
    loadTemplates();
  };

  const loadTemplates = async () => {
    try {
      const allTemplates = await APIService.getPageOfTemplates(offset, 50);
      setTemplates(allTemplates.templates);
      setPageCount(Math.ceil(allTemplates.total_count / 100));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const loadTemplate = async id => {
    try {
      const template = await APIService.getTemplateById(id);
      setActiveTemplate(template.templates[0]);
      setShowCurrentTemplate(true);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (send.template_id) {
      loadTemplate(send.template_id);
    }
    loadTemplates();
  }, [setError, send]);

  return {
    showCurrentTemplate,
    activeTemplate,
    loading,
    previewTemplate,
    selectTemplate,
    selectWhatTemplateType,
    templates,
    handlePageChange,
    pageCount,
    showUploadTemplate,
  };
};

export default useWhatStep;
