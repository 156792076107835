import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from 'components/Button';
import useEditor from './hooks';

import {Froala} from 'components/Froala/froala';

const Editor = ({
  selectWhatTemplateType,
  send,
  mergeTags,
  setNewMergeTagModal,
  editorContent,
  setEditorContent,
  loadHTMLFileIntoEditor,
  docFileState,
  setDocFileState,
  setErr,
  activeTemplate,
  loading,
}) => {
  const history = useHistory();
  const {saveHTMLTemplateData, templateType} = useEditor(
    loadHTMLFileIntoEditor,
    docFileState,
    setDocFileState,
    setErr,
    editorContent,
    send,
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="text-md mb-5 d-flex flex-column">
        <div className="row">
          <div className="col-sm-9">
            <h1 className="mb-0">
              {activeTemplate
                ? `Editing ${activeTemplate.name}`
                : `Create a template`}
            </h1>
            <p className="mb-4 d-flex align-items-center text-sm">
              <button
                className="btn btn-link p-0 mx-2 text-sm"
                onClick={() => history.push(`send/${send.id}/templates`)}
                disabled={templateType === 'selectTemplate' ? true : false}>
                select a template
              </button>{' '}
              instead
            </p>
          </div>

          <div className="col-sm-3">
            <Button onClick={() => saveHTMLTemplateData()}>
              SAVE & CONTINUE
            </Button>
          </div>
        </div>

        <Froala
          mergeTags={mergeTags}
          editorContent={editorContent}
          onEditorContentChange={content => {
            setEditorContent(content);
          }}
          addNewMergeTagPress={() => {
            setNewMergeTagModal(true);
          }}
        />
      </div>
    </>
  );
};

export default Editor;
