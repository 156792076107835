import React from 'react';
import styles from './styles.scss';
import Button from 'components/Button';

const SelectedTemplate = ({
  showCurrentTemplate,
  activeTemplate,
  selectTemplate,
}) => {
  if (activeTemplate) {
    const {merge_tags} = activeTemplate;

    return (
      <div className="SelectedTemplate-container">
        {showCurrentTemplate && (
          <h1 className="mb-0" data-testid="select-a-template">
            The template {activeTemplate.name} is currently attached to this
            campaign.
          </h1>
        )}
        <h2 className="lighter mb-2">{activeTemplate.name}</h2>
        {merge_tags ? (
          <strong> {merge_tags.length} Available Tags</strong>
        ) : null}

        <div className="">
          {merge_tags &&
            merge_tags.map((tag, index) => (
              <span key={index} className="badge badge- secondary mr-3">
                {tag.replace('$', '').replace('}', '')}
              </span>
            ))}
          <div className="step-nav col-sm-12 d-flex mb-5 mt-">
            <Button onClick={selectTemplate} disabled={!activeTemplate}>
              Select {activeTemplate.name}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="SelectedTemplate-container">
      <p className="mt-3 text-muted">
        &#8592; Select a template from the left to preview.
      </p>
    </div>
  );
};

export default SelectedTemplate;
