import React from 'react';
import 'font-awesome/css/font-awesome.css';
import StepLayout from 'layouts/Step';
import useStep from 'hooks/steps';
import useWhatStep from './hooks';
import Editor from 'components/Editor';

import './styles.scss';

const WhatStep = props => {
  const {error, send, loading, setError} = useStep();
  const {
    activeTemplate,
    selectWhatTemplateType,
    editorContent,
    setEditorContent,
    loadHTMLFileIntoEditor,
    docFileState,
    setDocFileState,
    mergeTags,
    setNewMergeTagModal,
    loading: loadingEditor,
  } = useWhatStep(send, setError);

  return (
    <StepLayout
      {...props}
      loading={loading && !send}
      disabled={false}
      error={error}
      title="Send - What are we sending?"
      noSteps>
      <Editor
        loading={loadingEditor}
        loadHTMLFileIntoEditor={loadHTMLFileIntoEditor}
        activeTemplate={activeTemplate}
        selectWhatTemplateType={selectWhatTemplateType}
        send={send}
        mergeTags={mergeTags}
        setNewMergeTagModal={setNewMergeTagModal}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        setErr={setError}
        docFileState={docFileState}
        setDocFileState={setDocFileState}
      />
    </StepLayout>
  );
};

export default WhatStep;
