const template = `<meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
<style type='text/css'>@page {
margin-top: 50px;
margin-bottom: 50px;
margin-left: 100px;
margin-right: 60px;
}
.highlight_yellow_remove {
display: inline-block;
padding: 3px;
background-color: #feeb9d;
color: #000000;
border-radius: 5px;
}
.highlight_yellow_dark_remove {
display: inline-block;
padding: 3px;
background-color: #fad133;
color: #000000;
border-radius: 5px;
}
.column_50 {
    float: left;
    width: 50%;
}
.black_bar {
    font-size: 14px;
    font-family: Arial,Helvetica,sans-serif;
    display: block;
    width: 100%;
    color: white;
    background: black;
    border-radius: 8px;
    text-align: center;
    padding: 2px;
}
th, td {
    padding: 3px 4px 3px 4px;
}
table.rounded tr {
    border-radius:5px;
}
.small_margin {
    margin: 15px, 5px, 5px, 5px;
}
.no_margin {
    margin: 0;
}
.clear {
    clear: both;
    height: 0 !important;
}
</style>
<div class='block' style='margin-bottom: 20px;'>
<div style='display:inline-block;float:left;width:50%;'>
<p><br />
 </p>
</div>
</div>

<div class='clear'> </div>

<div class='block_head extra'>
<div class='sub_block' style='width:35%; height: 25%; float:left;'>
<div>
<table style='width:100%;'>
    <tbody>
        <tr>
            <td style='vertical-align: top; text-align: center;'><span style='font-family:Arial,Helvetica,sans-serif;'><img src='https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_icon_1.png' style='width: 25px;' /></span></td>
            <td><span style='font-family:Arial,Helvetica,sans-serif;'><span style='font-size:11px;'><span style='font-weight:bold;'>PROPERTY ADDRESS</span></span><br />
            <span style='font-size:11px;'><span class='highlight_yellow'>{$propertyaddress}</span></span></span></td>
        </tr>
        <tr>
            <td style='vertical-align: top; text-align: center;'><img alt='' src='https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_icon_2.png' style='width: 25px;' /></td>
            <td><span style='font-family:Arial,Helvetica,sans-serif;'><span style='font-size:11px;'><span style='font-weight:bold;'>ASSESSMENT NUMBER</span></span><br />
            <span style='font-size:11px;'><span class='highlight_yellow'>{$assessmentnumber}</span></span></span></td>
        </tr>
    </tbody>
</table>
</div>
</div>

<div class='sub_block' style='width:65%;float:left;min-height:240px;background-image: url('https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_red_line.png');background-repeat:no-repeat;background-size: contain;display:none;'>
<div style='margin-top:-20px;padding-left:50px;'>
<div class='clear'> </div>
</div>
</div>

<div class='clear'> </div>

<div class='clear' style='text-align: right;'> </div>

<div style='border-radius: 0.0625in;'>
<h1 style='font-size:22px; display:block; padding:5px 10px; color: white; background: black; border-radius:10px; font-family:Arial,Helvetica,sans-serif;font-weight: bold;text-align: center;'><strong>TOWN OF AMITE POST TAX SALE NOTICE</strong></h1>
<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'>September 10, 2019</span></p>
<p class='no_margin' style='text-align: center;'><span style='font-size:22px;'><span style='font-family:Arial,Helvetica,sans-serif;font-weight:bold;'>YOU MAY LOSE THE TITLE TO YOUR PROPERTY. <br/> PLEASE READ THIS NOTICE CAREFULLY.</span></span></p>


<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:12px;'>This letter is in reference to the property listed at the top of this notice with assessment number <strong> {$assessmentnumber}</strong></span></p>

<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:12px;'>The property taxes for this property were not paid, and tax sale title to the property was adjudicated to the Town of Amite for delinquent taxes in the year 2018 at CivicSource.com on Tuesday, May 21, 2019.</span></p>

<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:12px;'>You may redeem this property within three years from the date of filing, June 25, 2019, by contacting the Town of Amite tax collector, whose contact information is listed below. The period in which you can redeem your property will expire on June 25, 2022. <strong>THE TOWN OF AMITE MAY TAKE POSSESSION OF THE PROPERTY AFTER THE EXPIRATION OF THE REDEMPTIVE PERIOD.</strong> After the expiration of the redemptive period the property cannot be redeemed. Continued possession of the property does not extend the redemptive period.</span></p>

<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:12px;'>Please contact the Town of Amite tax collector if you believe that you received this notice in error, if the property has been sold or the title transferred, or for further information or assistance.</span></p>

<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:12px;'>This notice concerns only the property listed above; the address of that property may or may not be the same as the mailing address of this notice.</span></p>

<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:12px;'>You are receiving this notice because you are listed on the assessment with Notice Number/Parcel Number or instrument: {$assessmentnumber}</span></p>


<p class='small_margin'><span style='font-family:Arial,Helvetica,sans-serif;font-size:12px;'>If you have questions about this notice, please call <strong>(888) 628-9038.</strong> To redeem your property, please contact:</span></p>
<div class='block'>
    <div class='column_50'>
    <table style='margin-top:20px;margin-bottom:20px;'>
        <tbody>
            <tr>
                <td><span style='font-size:12px;'><span style='font-family:Arial,Helvetica,sans-serif;'><span style='font-weight:bold;'>TOWN OF AMITE</span><br />
                212 EAST OAK STREET<br />
                AMITE CITY, LA 70422</span></span></td>
            </tr>
        </tbody>
    </table>
    </div>


    </div>
</div>
<div><formfeed></formfeed></div>


<div>

<table style='width:100%;margin:0 auto;'>
    <tbody>
        <tr>

            <td style='width:100%;'>
            <table style='width:80%;'>
                <tbody>
                    <tr>
                        <td><span style='font-size:12px;'><span style='font-family:Arial,Helvetica,sans-serif;'><span style='font-weight:bold;'>PROPERTY:</span></span></span></td>
                        <td><span style='font-size:12px;'><span class='highlight_yellow' style='font-family:Arial,Helvetica,sans-serif;'>{$propertyaddress}</span></span></td>
                    </tr>
                    <tr>
                        <td><span style='font-size:12px;'><span style='font-family:Arial,Helvetica,sans-serif;'><span style='font-weight:bold;'>ASSESSMENT NUMBER:</span></span></span></td>
                        <td><span style='font-size:12px;'><span class='highlight_yellow_dark' style='font-family:Arial,Helvetica,sans-serif;'>{$assessmentnumber}</span></span></td>
                    </tr>

                    <tr>
                        <td><span style='font-size:12px;'><span style='font-size:12px;'><span style='font-family:Arial,Helvetica,sans-serif;'><span style='font-weight:bold;'>LEGAL DESCRIPTION:</span></span></span></span></td>
                        <td><span style='font-size:12px;'><span style='font-family:Arial,Helvetica,sans-serif;'><span class='highlight_yellow' style='font-size:12px;'>{$legal}</span><br />
                        <span style='font-size:9px;'>Legal description may be truncated to fit. For full description, please call the tax collector.</span></span></span></td>
                    </tr>
                </tbody>
            </table>
            </td>
        </tr>
    </tbody>
</table>



<div><formfeed></formfeed></div>




</div>


`;

export default template;
