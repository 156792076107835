import React from 'react';
import Header from 'components/Header/Header';
import plus from 'assets/img/ico_plus.svg';
import NoDrafts from 'assets/img/ico_no_drafts.svg';
import ReactPaginate from 'react-paginate';
import {FiFilter} from 'react-icons/fi';
import Loading from './Loading';
import useCampaigns from './hooks';
import {MAILHOUSE_URL} from 'config';

const getSenderName = send => {
  if (send.from_organization_name) {
    return send.from_organization_name;
  }

  if (send.from_first_name && send.from_last_name) {
    return send.from_first_name + ' ' + send.from_last_name;
  }

  if (send.from_first_name || send.from_last_name) {
    return send.from_first_name || send.from_last_name;
  }

  return '';
};

const getMailClassName = (send)  => {
  if (send.mail_class_id === 1) {
    return 'First Class'
  }

  if (send.mail_class_id === 2) {
    return send.delivery_confirmation
  }

  if (send.mail_class_id === 3) {
    return 'First Class + ' + send.delivery_confirmation
  }

  return ''
}

const Scheduled = props => {
  document.title = `Campaigns`;
  const {
    loading,
    campaigns,
    statusFilter,
    togglePopover,
    popover,
    filterChanged,
    campaignDetail,
    campaignsTotal,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageOffset,
  } = useCampaigns();

  return (
    <>
      <Header props={props} noTitle />
      <div className="container">
        {!loading && campaigns.length > 0 && (
          <div>
            <div className="row">
              <div className="col-sm">
                <h2>{statusFilter === 'ALL' && 'All Campaigns'}</h2>
                <h2>{statusFilter === 'DRAFT' && 'Draft Campaigns'}</h2>
                <h2>{statusFilter === 'SCHEDULED' && 'Scheduled Campaigns'}</h2>
                <h2>{statusFilter === 'REVIEWED' && 'Reviewed Campaigns'}</h2>
                <h2>{statusFilter === 'SENT' && 'Sent Campaigns'}</h2>
              </div>
              <div className="col-sm">
                <div className="d-flex justify-content-end">
                  <span className="pointer btn" onClick={togglePopover}>
                    <FiFilter size={25} />
                  </span>
                  {popover && (
                    <div className={'popover-nav active'}>
                      <ul className="popover-menu" style={{zIndex: 1}}>
                        <li className="popover-menu-item">
                          <button onClick={() => filterChanged('ALL')}>
                            All
                          </button>
                        </li>
                        <li className="popover-menu-item">
                          <button onClick={() => filterChanged('STARTED')}>
                            Started
                          </button>
                        </li>
                        <li className="popover-menu-item">
                          <button onClick={() => filterChanged('REVIEWED')}>
                            Reviewed
                          </button>
                        </li>
                        <li className="popover-menu-item">
                          <button onClick={() => filterChanged('SENT')}>
                            Sent
                          </button>
                        </li>
                        <li className="popover-menu-item">
                          <button onClick={() => filterChanged('MAILED')}>
                            Mailed
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row row-header justify-content-center mb-3 mt-5 ">
              <div className="col-sm-12 row">
                <div className="col">
                  <small className="th">Sender</small>
                </div>
                <div className="col">
                  <small className="th">Send Name</small>
                </div>
                <div className="col">
                  <small className="th">Type</small>
                </div>
                <div className="col">
                  <small className="th">Date Updated</small>
                </div>
                <div className="col">
                  <small className="th">Mailhouse ID</small>
                </div>
                <div className="col">
                  <small className="th">Status</small>
                </div>
                <div className="col">
                  <small className="th">Total Letters</small>
                </div>
                <div className="col">
                  <small className="th">Scheduled Date</small>
                </div>
              </div>
            </div>
            {campaigns.map((cmp, i) => {
              const modifiedDate = new Date(cmp.modified_at);
              const sendOnDate = cmp.send_on_date
                ? new Date(cmp.send_on_date)
                : null;
              const senderName = getSenderName(cmp);
              const mailClassName = getMailClassName(cmp)
              return (
                <div className="row justify-content-center" key={i}>
                  <div
                    className="btn-row col-sm-12 pointer"
                    onClick={() => campaignDetail(cmp)}>
                    <div className="col">{senderName}</div>
                    <div className="col">{cmp.name}</div>
                <div className="col">
                {mailClassName}
                    </div>
                    <div className="col">
                      {modifiedDate && modifiedDate.toLocaleDateString('en-US')}
                    </div>
                    <div
                      className="col"
                      onClick={event => event.stopPropagation()}>
                      <a
                        href={`${MAILHOUSE_URL}/campaign-details/${cmp.mailhouse_campaign_id}`}
                        target="_blank"
                        rel="noreferrer noopener">
                        {cmp.mailhouse_campaign_id}
                      </a>
                    </div>
                    <div className="col">{cmp.status_name}</div>
                    <div className="col">{cmp.document_count}</div>
                    <div className="col">
                      {sendOnDate
                        ? sendOnDate.toLocaleDateString('en-US', {
                            timeZone: 'UTC',
                          })
                        : ''}
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className={'mt-5'}
              style={{
                textAlign: 'center',
              }}>
              {campaigns.length > 0 && (
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(campaignsTotal / pageSize)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={data => {
                    setCurrentPage(data.selected);
                    setPageOffset(Math.ceil(data.selected * pageSize));
                  }}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage={currentPage}
                />
              )}
            </div>
          </div>
        )}
        {!loading && campaigns.length === 0 && (
          <div className="full-center flex-column">
            <img src={NoDrafts} alt="" />
            <h1 className="mt-5 text-center">
              There are no scheduled campaigns.
              <small>
                Press <img src={plus} className="plus-home" alt="plus-button" />{' '}
                to create new send.
              </small>
            </h1>
          </div>
        )}
        <Loading loading={loading} />
      </div>
    </>
  );
};

export default Scheduled;
