import React, {useState, useEffect, useContext} from 'react';
import {Context as SendContext} from 'contexts/send';

import {formattedAddress} from './helpers';
import APIService from 'services/backstrap/apiService';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import imgEnvelope from 'assets/img/ico_envelope.svg';
import icoDownload from 'assets/img/ico_download-white.svg';
import icoPrint from 'assets/img/ico_print-white.svg';
import icoUpload from 'assets/img/ico_upload-white.svg';

import './LetterDetailsModal.scss';

const LetterDetailsModal = ({lid, allDocumentIds, close}) => {
  const {send} = useContext(SendContext);
  const [id, setId] = useState(lid);

  const [viewState, setView] = useState({
    letterDetails: null,
    document: 'original',
    current_doc: null,
  });

  const nextLetter = () => {
    const currentLetter = allDocumentIds.indexOf(id);
    const next = allDocumentIds[currentLetter - 1];
    setId(next);
  };

  const prevLetter = () => {
    const currentLetter = allDocumentIds.indexOf(id);
    const previous = allDocumentIds[currentLetter + 1];
    setId(previous);
  };

  useEffect(() => {
    const loadLetter = async () => {
      try {
        const response = await APIService.getDocument(id);
        setView(lastState => {
          return {
            ...lastState,
            letterDetails: response,
            current_doc: response.merged_doc_uri,
          };
        });
      } catch (err) {
        console.log(err);
      }
    };

    loadLetter();
  }, [id]);

  const switchDocs = docType => {
    var docUrl = null;
    switch (docType) {
      case 'original':
        docUrl = viewState.letterDetails.merged_doc_uri;
        break;
      case 'sent':
        docUrl = viewState.letterDetails.sent_scan_uri;
        break;
      case 'receipt':
        if (viewState.letterDetails.err_confirmed) {
          docUrl = viewState.letterDetails.err_scan_uri;
        } else {
          docUrl = viewState.letterDetails.gc_scan_uri;
        }
        break;
      case 'return':
        docUrl = viewState.letterDetails.returned_scan_uri;
        break;
      case 'envelope':
        docUrl = viewState.letterDetails.envelope_scan_uri;
        break;
      default:
    }
    setView(lastState => {
      return {...lastState, document: docType, current_doc: docUrl};
    });
  };

  const arrow = (
    <span className="mr-3">
      <svg width="16.529" height="11.27" viewBox="0 0 16.529 11.27">
        <path
          fill="#ffffff"
          d="M11.536,1.514c-.642-.806.357-2.061,1-1.254l3.78,4.748a1.026,1.026,0,0,1,0,1.254l-3.78,4.748c-.642.806-1.64-.448-1-1.344L14.1,6.531H.7c-.927,0-.927-1.792,0-1.792H14.1Z"
        />
      </svg>
    </span>
  );

  return (
    <>
      <div id="letter-details-modal" className="modal d-flex" tabIndex="-1">
        <div className="overlay"></div>
        <div className="full-width">
          <div className="close" onClick={close}>
            &times;
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-3 sidebar">
                <div className="info">
                  <h3>Send</h3>
                  <p>{send.name}</p>
                </div>
                <div className="info">
                  <h3>Where</h3>
                  {viewState.letterDetails ? (
                    <p>
                      {viewState.letterDetails.address_line_1}
                      <br />
                      {formattedAddress(viewState.letterDetails)}
                    </p>
                  ) : null}
                </div>
                <div className="info">
                  <h3>Result</h3>
                  {viewState.letterDetails ? (
                    <p>
                      <span className="bullet">•</span>
                      {viewState.letterDetails.status_name}
                    </p>
                  ) : null}
                    {viewState.letterDetails && viewState.letterDetails.status_name.toLowerCase() === 'returned' ? <p>({viewState.letterDetails.status_details})</p> : null }
                </div>
                <div className="info">
                  <h3>External Reference</h3>
                  {viewState.letterDetails
                    ? viewState.letterDetails.external_reference
                    : null}
                </div>
                <div className="info">
                  <h3>Documents</h3>
                  <p
                    className="d-flex align-items-center"
                    onClick={() => {
                      switchDocs('original');
                    }}>
                    {viewState.document === 'original' && arrow} Original
                  </p>
                  {viewState.letterDetails &&
                  viewState.letterDetails.has_sent_scan ? (
                    <p
                      className="d-flex align-items-center"
                      onClick={() => {
                        switchDocs('sent');
                      }}>
                      {viewState.document === 'sent' && arrow} Sent
                    </p>
                  ) : (
                    <div />
                  )}
                  {viewState.letterDetails &&
                  (viewState.letterDetails.gc_confirmed ||
                    viewState.letterDetails.err_confirmed) ? (
                    <p
                      className="d-flex align-items-center"
                      onClick={() => {
                        switchDocs('receipt');
                      }}>
                      {viewState.document === 'receipt' && arrow} Receipt
                    </p>
                  ) : (
                    <div />
                  )}
                  {viewState.letterDetails &&
                  viewState.letterDetails.envelope_scanned ? (
                    <p
                      className="d-flex align-items-center"
                      onClick={() => {
                        switchDocs('envelope');
                      }}>
                      {viewState.document === 'envelope' && arrow} Envelope
                    </p>
                  ) : (
                    <div />
                  )}
                  {viewState.letterDetails &&
                  viewState.letterDetails.is_returned ? (
                    <p
                      className="d-flex align-items-center"
                      onClick={() => {
                        switchDocs('return');
                      }}>
                      {viewState.document === 'return' && arrow} Return
                    </p>
                  ) : (
                    <div />
                  )}
                </div>
                <button className="btn-link" onClick={prevLetter}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5.316"
                    height="8.35"
                    viewBox="0 0 5.316 8.35">
                    <g
                      id="noun_Left_1322309"
                      transform="translate(-9.82 -8.236)">
                      <path
                        id="Path"
                        d="M.385.339a1.093,1.093,0,0,1,1.584,0L5,3.371H5a1.091,1.091,0,0,1,.317.815A1.091,1.091,0,0,1,5,5H5L1.969,8.032a1.091,1.091,0,0,1-.815.317,1.091,1.091,0,0,1-.815-.317,1.093,1.093,0,0,1,0-1.584L2.6,4.141.385,1.923A1.093,1.093,0,0,1,.385.339Z"
                        transform="translate(15.137 16.586) rotate(-180)"
                        fill="#4099FE"
                      />
                    </g>
                  </svg>
                  <span className="pl-1">Previous</span>
                </button>
                <button className="btn btn-link related-send">
                  <img src={imgEnvelope} alt="Resend" />{' '}
                  <span>Related Sends</span>
                </button>
                <button className="btn-link" onClick={nextLetter}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5.316"
                    height="8.35"
                    viewBox="0 0 5.316 8.35">
                    <g
                      id="noun_Left_1322309"
                      transform="translate(15.137 16.586) rotate(180)">
                      <path
                        id="Path"
                        d="M.385.339a1.093,1.093,0,0,1,1.584,0L5,3.371H5a1.091,1.091,0,0,1,.317.815A1.091,1.091,0,0,1,5,5H5L1.969,8.032a1.091,1.091,0,0,1-.815.317,1.091,1.091,0,0,1-.815-.317,1.093,1.093,0,0,1,0-1.584L2.6,4.141.385,1.923A1.093,1.093,0,0,1,.385.339Z"
                        transform="translate(15.137 16.586) rotate(-180)"
                        fill="#4099FE"
                      />
                    </g>
                  </svg>
                  <span className="pl-1">Next</span>
                </button>
              </div>
              <div className="col-sm-9 content">
                <div className="d-flex print-menu">
                  <button className="btn btn-link">
                    <img src={icoPrint} alt="Print Letter" />
                  </button>
                  <button className="btn btn-link">
                    <img src={icoUpload} alt="Upload Letter" />
                  </button>
                  <button className="btn btn-link">
                    <img src={icoDownload} alt="Download Letter" />
                  </button>
                </div>
                <PdfViewer
                  arrows={true}
                  url={
                    viewState.letterDetails ? viewState.current_doc : null
                  }></PdfViewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LetterDetailsModal;
