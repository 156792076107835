import React from 'react';
import StepLayout from 'layouts/Step';
import {monthNames, dayNames} from 'services/util';
import {formatMailClass} from 'helpers/mailClass';
import Modal from './Modal';
import imgHow from 'assets/img/nav_how.svg';
import imgWho from 'assets/img/nav_who.svg';
import imgWhere from 'assets/img/nav_where.svg';
import imgWhen from 'assets/img/nav_when.svg';
import imgWhat from 'assets/img/nav_what.svg';
import imgSend from 'assets/img/nav_send.svg';
import icoEdit from 'assets/img/ico_edit.svg';
import SuccessIcon from 'assets/img/ico_sendcomplete.svg';
import ErrorIcon from 'assets/img/ico_uploaderror.svg';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
import useStep from 'hooks/steps';
import useSendConfirm from './hooks';
import './styles.scss';

const SendConfirm = props => {
  const {send, setError, error, loading} = useStep();
  const {
    numberOfLetters,
    whoName,
    linkTo,
    goToPreviews,
    uploadState,
    setPreview,
    viewState,
    reviewLetters,
    testMerge,
    isMerged,
    getPrice,
    preview,
    selectPreview,
    when,
    nextStep,
    isPreMerged,
    confirmSend,
    showModal,
    closeModal,
  } = useSendConfirm(send, setError);

  return (
    <StepLayout
      {...props}
      loading={loading && !send}
    error={error}
    letterlistId={uploadState === 'success' ? send.id : null}
      buttonTitle={uploadState === 'success' ? 'View Letters' : 'Send'}
      title="Send - How are we sending?"
      nextAction={uploadState === 'success' ? () => {} : confirmSend}
      nextLink={uploadState === 'success' ? `letterlist/${send.id}` : `confirm`}
      previousLink={`when`}>
      <Modal
        nextStep={nextStep}
        showModal={showModal}
        closeModal={closeModal}
      />
      {uploadState === 'ready' ? (
        <>
          <div className="container text-center">
            <h1 className="mt-5">Review before sending.</h1>

            <div className="row mx-0 justify-content-center align-items-center mt-5">
              <div className="col-sm-12">
                <div className="send-confirmation">
                  <div className="card-body row position-relative how">
                    <button
                      className="btn btn-outline-primary btn-edit nb"
                      onClick={() => linkTo('how')}>
                      <img src={icoEdit} alt="edit step" />
                    </button>
                    <div className="col-2 label text-left how">
                      <img src={imgHow} alt="" />
                      <label>How</label>
                    </div>
                    <div className="col-4 details text-left">
                      <p className="m-0">
                        {' '}
                        {formatMailClass(send.mail_class_id)}{' '}
                      </p>
                    </div>

                    <div className="col-4 details text-left">
                      <p className="m-0"> {send.mail_class}</p>
                    </div>

                    <div className="col-2 details text-left">
                      <p className="m-0">
                        {' '}
                        {viewState.perLetterPrice !== null
                          ? '$' +
                            viewState.perLetterPrice.toFixed(2) +
                            ' per piece'
                          : null}
                      </p>
                    </div>
                  </div>

                  <div className="card-body row position-relative who">
                    <button
                      className="btn btn-outline-primary btn-edit nb"
                      onClick={() => linkTo('who')}>
                      <img src={icoEdit} alt="edit step" />
                    </button>
                    <div className="col-2 label text-left who">
                      <img src={imgWho} alt="" />
                      <label>Who</label>
                    </div>
                    <div className="col-4 details text-left">
                      <p className="m-0">{whoName}</p>
                    </div>
                    <div className="col-6 details text-left">
                      <p className="m-0">
                        {send.address1
                          ? send.address1 +
                            ' ' +
                            (send.address2 ? send.address2 : ' ') +
                            send.city +
                            (send.state
                              ? ', ' + send.state.value + ' '
                              : ', ') +
                            send.zip
                          : 'Digitized'}
                      </p>
                    </div>
                  </div>

                  <div className="card-body row position-relative where">
                    <button
                      className="btn btn-outline-primary btn-edit nb"
                      onClick={() => linkTo('where')}>
                      <img src={icoEdit} alt="edit step" />
                    </button>
                    <div className="col-2 label text-left where">
                      <img src={imgWhere} alt="" />
                      <label>Where</label>
                    </div>
                    <div className="col-10 details text-left">
                      <p className="m-0">{numberOfLetters} Recipients</p>
                    </div>
                  </div>

                  <div className="card-body row position-relative what">
                    <button
                      className="btn btn-outline-primary btn-edit nb"
                      onClick={() => linkTo('what')}>
                      <img src={icoEdit} alt="edit step" />
                    </button>
                    <div className="col-2 label text-left what">
                      <img src={imgWhat} alt="" />
                      <label>What</label>
                    </div>
                    <div className="col-4 details text-left">
                      <div className="m-0 d-flex flex-column">
                        {send.template_do_merge === false
                          ? 'Print Ready'
                          : 'Mail Merge'}
                        <div className="btn-wrap">
                          {!isPreMerged && (
                            <button
                              className="btn btn-link btn-lg px-0"
                              onClick={goToPreviews}>
                              View Previews
                            </button>
                          )}
                          <button
                            className="btn btn-link btn-lg px-0 ml-2"
                            onClick={reviewLetters}>
                            Review Letters
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 details text-left">
                      <p className="m-0">8.5" x 11" - Color</p>
                    </div>
                    <div className="col-2 details text-left flex-column justify-content-center">
                      {!isPreMerged && (
                        <>
                          <button
                            onClick={testMerge}
                            className={`btn btn-lg ${
                              isMerged ? 'btn-outline-primary' : 'btn-primary'
                            }`}>
                            {isMerged ? 'Merged ✓' : 'Merge'}
                          </button>
                          {!isMerged && (
                            <small className="sm1em">
                              This will merge the first few
                            </small>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="card-body row position-relative when">
                    <button
                      className="btn btn-outline-primary btn-edit nb"
                      onClick={() => linkTo('when')}>
                      <img src={icoEdit} alt="edit step" />
                    </button>
                    <div className="col-2 label text-left when">
                      <img src={imgWhen} alt="" />
                      <label>When</label>
                    </div>
                    <div className="col-10 details text-left">
                      <p className="m-0">
                        {when
                          ? dayNames[when.getDay()] +
                            ', ' +
                            monthNames[when.getMonth()] +
                            ' ' +
                            when.getDate() +
                            ', ' +
                            when.getFullYear()
                          : 'no date'}
                      </p>
                    </div>
                  </div>

                  <div className="card-body row position-relative send">
                    <div className="col-2 label text-left total">
                      <img src={imgSend} alt="" />
                      <label>Send</label>
                    </div>
                    <div className="col-4 details text-left">
                      <p className="m-0 black">{numberOfLetters} letters</p>
                    </div>
                    <div className="col-4 details text-left">
                      <p className="m-0 black">
                        {viewState.perLetterPrice !== null
                          ? '$' +
                            viewState.perLetterPrice.toFixed(2) +
                            ' per piece'
                          : null}
                      </p>
                    </div>
                    <div className="col-2 details text-left">
                      <p className="m-0 black semibold">${getPrice()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container success-container flex-column text-center">
          {uploadState === 'success' && (
            <>
              <div className="row mx-0 justify-content-center align-items-center mt-5">
                <div className="col-sm-12 upload-button-wrap">
                  <div className="upload-button">
                    <div className="card-body">
                      <img src={SuccessIcon} alt="Success uploading" />
                      <h1>Your send is on its way.</h1>
                      <p>Congratulations!</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {uploadState === 'error' && (
            <>
              <div className="row mx-0 justify-content-center align-items-center mt-5">
                <div className="col-sm-12 upload-button-wrap">
                  <div className="upload-button">
                    <div className="card-body">
                      <img src={ErrorIcon} alt="Error uploading" />
                      <h1>Sorry</h1>
                      <p>
                        There was an error processing this send. Please try
                        again.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {preview.show && (
        <div id="letter-details-modal" className="modal d-flex" tabIndex="-1">
          <div className="overlay"></div>
          <div className="full-width">
            <div
              className="close"
              onClick={() => {
                setPreview(lastState => {
                  return {...lastState, show: false};
                });
              }}>
              &times;
            </div>
            <div className="container text-center">
              <h1 className="mb-4 mb-lg-5">Previews</h1>

              <PdfViewer url={viewState.currentPreview}></PdfViewer>
              <div className="d-flex justify-content-center btn-group my-5 container px-5">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => selectPreview('short')}
                  hidden={viewState.shortestRowPreview == null}>
                  Shortest
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{maxWidth: 33 + '%'}}
                  onClick={() => selectPreview('first')}>
                  First
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => selectPreview('long')}
                  hidden={viewState.longestRowPreview == null}>
                  Longest
                </button>
              </div>
              <p className="mt-2 go-back">
                Doesn't look right? &nbsp;
                <a
                  onClick={() => {
                    setPreview(lastState => {
                      return {...lastState, show: false};
                    });
                    linkTo('what');
                  }}>
                  Go back and edit.
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      {preview.show ? (
        <div id="letter-details-modal" className="modal d-flex" tabIndex="-1">
          <div className="overlay"></div>
          <div className="full-width">
            <div
              className="close"
              onClick={() => {
                setPreview(lastState => {
                  return {...lastState, show: false};
                });
              }}>
              &times;
            </div>
            <div className="container text-center">
              <h1 className="mb-4 mb-lg-5">Previews</h1>

              <PdfViewer url={viewState.currentPreview}></PdfViewer>
              <div className="d-flex justify-content-center btn-group my-5 container px-5">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => selectPreview('short')}
                  hidden={viewState.shortestRowPreview == null}>
                  Shortest
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{maxWidth: 33 + '%'}}
                  onClick={() => selectPreview('first')}>
                  First
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => selectPreview('long')}
                  hidden={viewState.longestRowPreview == null}>
                  Longest
                </button>
              </div>
              <p className="mt-2 go-back">
                Doesn't look right? &nbsp;
                <a
                  onClick={() => {
                    setPreview(lastState => {
                      return {...lastState, show: false};
                    });
                    props.history.push('/send/what');
                  }}>
                  Go back and edit.
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </StepLayout>
  );
};

export default SendConfirm;
