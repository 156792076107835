import React, {useState, useEffect} from 'react';
import {CTX} from 'store';
import {Redirect} from 'react-router-dom';

import APIService from 'services/backstrap/apiService';
import Toast from 'components/Toast/Toast';
import logo from 'assets/img/logo.svg';

const SignIn = props => {
  document.title = `Sign In to Send`;

  const [appState, dispatch] = React.useContext(CTX);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [err, setErr] = useState(null);
  const handleusernameChange = e => setUsername(e.target.value);
  const handlePasswordChange = e => setPassword(e.target.value);

  const [toast, toastState] = React.useState(true);
  const toggletoast = () => toastState(!toast);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    dispatch({type: 'LOADING_ENABLED'});

    dispatch({type:'SIGNING_IN'});
    await APIService.signIn(username, password)
      .then(res => {
        localStorage.setItem('cs_token', res.cs_token);
        localStorage.setItem('cs_username', res.username);
        dispatch({type: 'LOADING_DISABLED'});
        dispatch({type: 'SIGN_IN_SUCCESS', payload: res});
        dispatch({type: 'SIGNIN_COMPLETE'});
        props.history.push('/');
      })
      .catch(err => {
        console.log(err);
        err && err.data ? setErr(err.data.message) : setErr('Error signing in');
        dispatch({type: 'LOADING_DISABLED'});
        dispatch({type: 'SIGNIN_COMPLETE'});
      });
  }

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      dispatch({type: 'LOADING_DISABLED'});
    }, 777);
    return () => loadingTimeout;
  }, []);

  return (
    <>
      {!appState.isAuthenticated ? (
        <>
          <nav id="top-bar" className="navbar navbar-light">
            <a className="navbar-brand">
              <img src={logo} className="logo-header" alt="Send" />
            </a>
          </nav>
          <div className="container full-center">
            <div className="col-sm-5 text-center">
              <h1 className="mt-5" data-testid="sign-in-header">
                Sign in to Send.
              </h1>
              <form
                className="mt-3 d-flex flex-column justify-content-center"
                onSubmit={handleFormSubmit}>
                <input
                  className="form-control mb-3"
                  type="text"
                  onChange={handleusernameChange}
                  autoFocus
                  name="username"
                  placeholder="Username"
                  value={username}
                />
                <input
                  className="form-control mb-5"
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handlePasswordChange}
                  value={password}
                />
                <button
                  type="submit"
                  className="btn btn-primary btn-action-home"
                  disabled={username !== '' && password !== '' ? false : true}>
                  <span className="action">SIGN IN</span>
                </button>
              </form>
            </div>
            {err ? (
              <Toast
                message={err}
                type="danger"
                active={toast}
                close={toggletoast}
              />
            ) : null}
          </div>
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default SignIn;
