import {useState, useEffect, useContext} from 'react';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';
import {handleException} from 'services/exceptions';
import {Context as SendContext} from 'contexts/send';

const useLetterList = () => {
  const history = useHistory()
  const location = useLocation()
  const {cid, letterId} = useParams();
  const {send, setSend} = useContext(SendContext);
  const [showModal, setShowModal] = useState(false)
  const [letterIdFromParams, setLetterIdFromParams] = useState()
  const id = Number(cid);


  useEffect(() => {
    const loadSend = async () => {
      try {
        const response = await APIService.getCampaignDetails(id);
        setSend(response);
      } catch (err) {
        handleException(err);
      }
    };
    loadSend();
  }, [setSend, id]);

  useEffect(() => {
    if (letterId) {
      setLetterIdFromParams(letterId)
      setShowModal(true)
    }
  }, [])

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    if (letterId) {
      setShowModal(false)
      if (location.pathname.includes('reviewed')) {
        history.push(`/reviewed/letterlist/${cid}`)
      } else {
        history.push(`/sent/letterlist/${cid}`)
      }
    }
    setShowModal(false)
  }

  return {
    send,
    cid,
    letterIdFromParams,
    showModal,
    openModal,
    closeModal,
  };
};

export default useLetterList;
