import React from 'react';
import StepLayout from 'layouts/Step';
import Select from 'react-select';
import './styles.scss';
import 'font-awesome/css/font-awesome.css';
import {customSelectStyles} from 'services/util';
import Columns from './Columns';
import useStep from 'hooks/steps';
import useWhereStepOptions from './hooks';

const WhereStep = props => {
  const {error, setError, loading, send} = useStep();
  const {
    columns,
    setColumns,
    onFieldMapSelect,
    activeSelectValue,
    fieldMapOptions,
    datasourceId,
    uploadFieldMaps,
  } = useWhereStepOptions(send, setError);

  if (loading || !send) {
    return <div>Loading...</div>;
  }

  return (
    <StepLayout
      {...props}
      loading={loading && !send}
      error={error}
      title="Send - Where are we sending?"
      nextLink={`what`}
      nextAction={uploadFieldMaps}
      previousLink={`where`}>
      <h1 className="mt-5 mb-3 text-left">Map Address Columns</h1>
      <p className="mb-5 text-left text-md">
        Click a column to assign required values. You must select a name and an
        address.
      </p>
      {datasourceId && (
        <div className="row mb-5">
          <Columns data={columns} setColumns={setColumns} />
          {columns.activeColumn && columns.activeColumn.id !== null && (
            <div className="col-sm-12 mt-5 text-left px-5 mb-5">
              <div className="col-sm-6 pl-0">
                <h2 className="mb-3">Editing '{columns.activeColumn.id}'</h2>
                <label className="text-uppercase text-sm">
                  Select field to map to this column
                </label>
                <Select
                  value={activeSelectValue()}
                  options={fieldMapOptions}
                  styles={customSelectStyles}
                  placeholder="Select..."
                  onChange={e => onFieldMapSelect(e, columns.activeColumn.id)}
                  isClearable={true}
                />
              </div>
              <div id="required-selections" className="col-sm-6">
                <h2 className="mb-3">Required Selections</h2>
                <div className="row">
                  {columns.nameFieldMapType || columns.addressFieldMapType ? (
                    <>
                      {/*full name selected*/
                      columns.nameFieldMapType === 'full' && (
                        <div className="col-sm-12">
                          <p
                            className={`an-option text-md ${
                              columns.fieldMaps.name &&
                              columns.fieldMaps.name.id != null
                                ? 'text-primary'
                                : 'muted'
                            }`}>
                            Full Name <i className="fa fa-check-circle"></i>
                          </p>
                        </div>
                      )}
                      {/*full address selected*/
                      columns.addressFieldMapType === 'full' && (
                        <div className="col-sm-12">
                          <p
                            className={`an-option text-md ${
                              columns.fieldMaps.full_address &&
                              columns.fieldMaps.full_address.id != null
                                ? 'text-primary'
                                : 'muted'
                            }`}>
                            Full Address <i className="fa fa-check-circle"></i>
                          </p>
                        </div>
                      )}
                      {/*partial selected */
                      columns.nameFieldMapType === 'partial' && (
                        <>
                          <div className="col-sm-6">
                            <p
                              className={`an-option text-md ${
                                columns.fieldMaps.first_name &&
                                columns.fieldMaps.first_name.id != null
                                  ? 'text-primary'
                                  : 'muted'
                              }`}>
                              First Name <i className="fa fa-check-circle"></i>
                            </p>
                            <p
                              className={`an-option text-md ${
                                columns.fieldMaps.last_name &&
                                columns.fieldMaps.last_name.id !== null
                                  ? 'text-primary'
                                  : 'muted'
                              }`}>
                              Last Name <i className="fa fa-check-circle"></i>
                            </p>
                          </div>
                        </>
                      )}
                      {/*partial selected */
                      columns.addressFieldMapType === 'partial' && (
                        <>
                          <div className="col-sm-6">
                            <p
                              className={`an-option text-md ${
                                columns.fieldMaps.address_line_1 &&
                                columns.fieldMaps.address_line_1.id != null
                                  ? 'text-primary'
                                  : 'muted'
                              }`}>
                              Address 1 <i className="fa fa-check-circle"></i>
                            </p>
                            <p
                              className={`an-option text-md ${
                                columns.fieldMaps.city_town &&
                                columns.fieldMaps.city_town.id != null
                                  ? 'text-primary'
                                  : 'muted'
                              }`}>
                              City <i className="fa fa-check-circle"></i>
                            </p>
                            <p
                              className={`an-option text-md ${
                                columns.fieldMaps.state_province_district &&
                                columns.fieldMaps.state_province_district.id !=
                                  null
                                  ? 'text-primary'
                                  : 'muted'
                              }`}>
                              State <i className="fa fa-check-circle"></i>
                            </p>
                            <p
                              className={`an-option text-md ${
                                columns.fieldMaps.postal_code &&
                                columns.fieldMaps.postal_code.id != null
                                  ? 'text-primary'
                                  : 'muted'
                              }`}>
                              Zip <i className="fa fa-check-circle"></i>
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="col-sm-12 text-md">
                      <div className="alert alert-warning">
                        You must select either a <strong>Full Name</strong> and{' '}
                        <strong>Full Address</strong> or the component pieces of
                        a <strong>name and address</strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </StepLayout>
  );
};

export default WhereStep;
