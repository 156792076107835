import {arrowFunctionExpression} from '@babel/types';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SIGN_IN_SUCCESS':
      return {
        ...state,
        bs_user: action.payload,
        isAuthenticated: true,
      };
    case 'USER_IS_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: true,
      };
    case 'USER_IS_NOT_AUTHENTICATED':
      return {
        ...state,
        isAuthenticated: false,
      };
    case 'LOG_OUT':
      return {
        ...state,
        isAuthenticated: false,
      };
    case 'LOADING_DISABLED':
      return {
        ...state,
        isLoading: false,
      };
    case 'LOADING_ENABLED':
      return {
        ...state,
        isLoading: true,
      };
    case 'GET_ALL_CAMPAIGNS':
      return {
        ...state,
        campaigns: action.payload,
      };
    case 'UPDATE_SEND_TITLE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          name: action.payload,
        },
      };
    case 'UPDATE_SEND_ID':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          id: action.payload,
        },
      };
    case 'CREATE_NEW_SEND':
      return {
        ...state,
        activeSendObject: {
          id: null,
          name: null,
          how: null,
          sender: {
            name: null,
            address1: null,
            address2: null,
            city: null,
            state: null,
            zip: null,
          },
          where: null,
          what: {
            type: null,
            file: null,
          },
          when: null,
          metadata: {
            organization_logo: null,
          },
          options: {
            coversheet: false,
            perf: false,
            returnEnv: false,
            alertColor: null,
            message1: null,
            message2: null,
          },
        },
      };
    case 'SET_ACTIVE_SEND':
      return {
        ...state,
        activeSendObject: action.payload,
      };
    case 'UPDATE_ACTIVE_STEP':
      return {
        ...state,
        activeStep: action.payload,
      };
    case 'SELECT_HOW_STEP':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          how: action.payload,
        },
      };
    case 'SELECT_HOW_TYPE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          howType: action.payload,
        },
      };
    case 'SET_SENDER_FIRST_NAME':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            first_name: action.payload,
          },
        },
      };
    case 'SET_ORGANIZATION_LOGO':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          metadata: {
            ...state.activeSendObject.metadata,
            organization_logo: action.payload,
          },
        },
      };
    case 'SET_LOGO_PATH':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          metadata: {
            ...state.activeSendObject.metadata,
            logo_path: action.payload,
          },
        },
      };
    case 'SET_SENDER_LAST_NAME':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            last_name: action.payload,
          },
        },
      };
    case 'SET_SENDER_ORG_NAME':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            from_organization_name: action.payload,
          },
        },
      };
    case 'SET_SENDER_ADDRESS1':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            address1: action.payload,
          },
        },
      };
    case 'SET_SENDER_ADDRESS2':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            address2: action.payload,
          },
        },
      };
    case 'SET_SENDER_CITY':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            city: action.payload,
          },
        },
      };
    case 'SET_SENDER_STATE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            state: action.payload,
          },
        },
      };
    case 'SET_SENDER_ZIP':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          sender: {
            ...state.activeSendObject.sender,
            zip: action.payload,
          },
        },
      };
    case 'SET_OPTIONS_COVERSHEET':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          options: {
            ...state.activeSendObject.options,
            coversheet: action.payload,
          },
        },
      };
    case 'SET_OPTIONS_PERF':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          options: {
            ...state.activeSendObject.options,
            perf: action.payload,
          },
        },
      };
    case 'SET_OPTIONS_RETURN_ENV':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          options: {
            ...state.activeSendObject.options,
            returnEnv: action.payload,
          },
        },
      };
    case 'SET_OPTIONS_ALERT_COLOR':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          options: {
            ...state.activeSendObject.options,
            alertColor: action.payload,
          },
        },
      };
    case 'SET_OPTIONS_MESSAGE1':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          options: {
            ...state.activeSendObject.options,
            message1: action.payload,
          },
        },
      };
    case 'SET_OPTIONS_MESSAGE2':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          options: {
            ...state.activeSendObject.options,
            message2: action.payload,
          },
        },
      };
    case 'SET_CSV_ADDRESS_FILE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          where: action.payload,
        },
      };
    case 'SELECT_WHAT_TEMPLATE_TYPE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          what: {
            ...state.activeSendObject.what,
            templateType: action.payload,
          },
        },
      };
    case 'SELECT_WHAT_TYPE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          what: {
            ...state.activeSendObject.what,
            type: action.payload,
          },
        },
      };
    case 'SELECT_WHAT_FILE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          what: {
            ...state.activeSendObject.what,
            file: action.payload,
          },
        },
      };
    case 'SELECT_WHEN_DATE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          when: action.payload,
        },
      };
    case 'SET_NUM_LETTERS':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          numLetters: action.payload,
        },
      };
    case 'SET_DATA_SOURCE':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          datasource: action.payload,
        },
      };
    case 'SET_DATA_SOURCE_MAPS':
      return {
        ...state,
        activeSendObject: {
          ...state.activeSendObject,
          datasource: {
            ...state.activeSendObject.datasource,
            fieldmaps: action.payload,
          },
        },
      };
    case 'SIGNING_IN':
      return {
        ...state,
        signingIn: true
      };
    case 'SIGNIN_COMPLETE':
      return {
        ...state,
        signingIn: false
      };
    default:
      throw Error('reducer error');
  }
};

export default reducer;
