import React from 'react';
import Header from 'components/Header/Header';
import ProgressNav from 'components/ProgressNav/ProgressNav';
import useStep from 'hooks/steps';
import StepNav from 'components/StepNav/StepNav';
import ErrorToast from 'components/ErrorToast';

const SendLayout = props => {
  document.title = props.title || `Send - How are we sending?`;
  const {
    nextLink,
    previousLink,
    children,
    noSteps,
    disabled,
    nextAction,
    step,
  } = props;
  const {id, prevStep, loading} = useStep();

  return (
    <>
      <Header props={props} />
      <ProgressNav step={step} />
      <div className="container text-center">
        {!loading && children}
        {!noSteps && (
          <StepNav
            title={props.buttonTitle || 'Continue'}
            previousLink={
              previousLink.includes('new')
                ? previousLink
                : `/send/${id}/${previousLink}`
            }
            nextLink={
              props.buttonTitle === 'View Letters'
                ? `/reviewed/letterlist/${id}`
                : `/send/${id}/${nextLink}`
            }
            disabled={disabled}
            nextAction={nextAction}
            back={() => prevStep()}
          />
        )}
      </div>
      <ErrorToast error={props.error} />
    </>
  );
};

export default SendLayout;
