export const formattedAddress = (letterDetails = {}) => {
  const { propertycity, propertystate, propertyzip } =  letterDetails.metadata || {}

  // if a formatted version of the address is provided in metadata, use that. If
  // not fallback to user inputted metadata.
  const address = {
    cityTown: propertycity || letterDetails.city_town,
    stateProvinceDistrict: propertystate || letterDetails.state_province_district,
    postalCode: propertyzip || letterDetails.postal_code
  }

  return `${address.cityTown}, ${address.stateProvinceDistrict} ${address.postalCode}`
}
