import React from 'react';
import {Redirect, Route} from 'react-router-dom';

const PrivateRoute = ({loading, isAuthenticated, component, ...options}) => {
  if (loading) {
    return null
  }

  if (!isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  if (!loading) {
    return <Route {...options} component={component} />;
  }
};

export default PrivateRoute;
