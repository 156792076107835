import {useState, useEffect, useCallback, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {Context as SendContext} from 'contexts/send';
import APIService from 'services/backstrap/apiService';
import {handleException} from 'services/exceptions';
import baseStylesEditorPDF from 'components/Froala/base-styles';
import froalaStylesEditorPDF from 'components/Froala/froala-styles';
import {fetchAsBlob, convertBlobToBase64} from 'services/util';
import {getMergeTagHTML} from 'helpers/mergeTagsHTML';
import {useDropzone} from 'react-dropzone';

const mammoth = require('mammoth');

const useWhatStep = (send, setErr) => {
  const {templateId} = useParams();
  const {whatType, setWhatType, templateType, setTemplateType} = useContext(
    SendContext,
  );
  const [loading, setLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [editorContent, setEditorContent] = useState(
    baseStylesEditorPDF + froalaStylesEditorPDF,
  );
  const [docFileState, setDocFileState] = useState({
    docFile: null,
  });
  const [viewState, setView] = useState(whatType !== null);
  const [modal, modalState] = useState(false);
  const toggleModal = () => modalState(!modal);
  const [modal2, modalState2] = useState(false);
  const toggleModal2 = () => modalState2(!modal2);
  const [preview, setPreview] = useState({
    show: false,
    preview: null,
    loading: false,
    previewPollTimer: null,
  });
  //we need base styles for the editor CSS of the merge fields and plugins
  //we need froala styles for the styles that froala formatting puts on.
  //NOTE: there is useClasses:false in froala config to only use inline styles, but it removes the basestyles classes which are needed, so to get around it, we include froala classes as well
  const [mergeTags, setMergeTags] = useState({
    'ADD NEW': 'ADD NEW MERGE TAG',
  });

  const [newMergeTagModal, setNewMergeTagModal] = useState(false);
  const [newMergeTag, setNewMergeTag] = useState('');

  const addNewMergeTag = values => {
    var obj = {};
    values.forEach(val => {
      obj['{$' + `${val}}`] = val;
    });
    setMergeTags({...mergeTags, ...obj});
    setNewMergeTag('');
    setNewMergeTagModal(false);
  };

  //mail merge or print ready
  const selectWhatType = what => {
    setWhatType(what);
  };
  //create or upload or select template
  const changeView = () => setView(!viewState);

  const onDrop = useCallback(files => {
    var file = files[0];
    const reader = new FileReader();
    reader.onload = event => {
      var matches = event.target.result.match(
        /^data:[^\/]+\/([^;]+);base64,(.*)$/,
      );
      let fileNameParts = file.name.split('.');
      let fileExt = fileNameParts[fileNameParts.length - 1];
      if (matches.length === 3) {
        fileExt = matches[1];
      }

      setDocFileState(prevState => {
        return {
          ...prevState,
          docFile: file,
          base64String: matches[matches.length - 1],
          fileName: file.name,
          fileExt: fileExt,
        };
      });
    };
    reader.readAsDataURL(file);
  }, []);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const downloadUploadedTemplate = evt => {
    evt.stopPropagation();
    APIService.getCampaignDetails(send.id)
      .then(campDet => {
        return APIService.getTemplate(campDet.id, campDet.template_do_merge);
      })
      .then(templateRes => {
        window.open(templateRes.url);
      })
      .catch(campErr => {
        console.log('error downloading template:', campErr);
      });
  };

  const renderTaggedHtml = (template, html) => {
    return template.merge_tags.reduce((newObj, tag) => {
      const tempFormatTag = `{$${tag}}`;
      const newHtml = getMergeTagHTML(tempFormatTag, tempFormatTag);
      return newObj.replaceAll(tempFormatTag, newHtml);
    }, html);
  }

  const renderMergeTags = (template, html) => {
    if (template.merge_tags) {
      return template.merge_tags.reduce((newObj, tag) => {
        const tempFormatTag = `{$${tag}}`;
        const newHtml = getMergeTagHTML(tempFormatTag, tempFormatTag);
        return newObj.replaceAll(tempFormatTag, newHtml);
      }, html);
    }
  }

  const renderWithTags = (template, html) => {
    const taggedHtml = renderTaggedHtml(template, html)
    const mergeTagHtml = renderMergeTags(template, taggedHtml)
    return mergeTagHtml;
  };

  const selectWhatTemplateType = newTemplateType => {
    setTemplateType(newTemplateType);
  };

  const previewTemplate = selected => {
    setActiveTemplate(selected);
  };

  const loadHTMLFileIntoEditor = (template, forceParse = false) => {
    const parsedHTML = template.file_body;
    let htmlString;

    if (!parsedHTML) {
      htmlString = decodeURIComponent(
        escape(window.atob(docFileState.base64String)),
      );
    } else if (forceParse) {
      htmlString = decodeURIComponent(escape(window.atob(parsedHTML)));
    } else {
      htmlString = parsedHTML;
    }
    //check if base and froala styles exist already inside of template to prevent duplicates, if not add them
    let content =
      "<div style='max-width: 100%; display: block; position: relative;'>" +
      htmlString +
      '</div>';
    //doesn't already have baseStyles
    if (htmlString.indexOf('lf-styles') < 0) {
      content = baseStylesEditorPDF + content;
    }
    //doesn't already have froalaStyles
    if (htmlString.indexOf('froala-styles') < 0) {
      content = froalaStylesEditorPDF + content;
    }
    setEditorContent(renderWithTags(template, content));
    selectWhatTemplateType('createTemplate');
  };

  const loadDocXIntoEditor = (file, template) => {
    APIService.postCampaignTemplate(template)
      .then(res => {
        if (
          activeTemplate &&
          (activeTemplate.ext === 'docx' ||
            activeTemplate.ext ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        ) {
          //convert docx to html
          fetchAsBlob(file).then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const arrayBuffer = reader.result;
              mammoth
                .convertToHtml({arrayBuffer: arrayBuffer})
                .then(function(result) {
                  loadHTMLFileIntoEditor(result.value);
                })
                .catch(err => {
                  handleException(err);
                })
                .done();
            };
            reader.readAsArrayBuffer(blob);
          });
        } else if (
          activeTemplate &&
          (activeTemplate.ext === 'html' || activeTemplate.ext === 'htm')
        ) {
          fetchAsBlob(file)
            .then(convertBlobToBase64)
            .then(res => {
              const encodedData = res.replace(/^data:text\/html;base64,/, '');
              setDocFileState(prevState => {
                return {
                  ...prevState,
                  docFile: {name: activeTemplate.name},
                  base64String: encodedData, //encodedData,
                  fileName: activeTemplate.name,
                  fileExt: 'html',
                };
              });
            })
            .catch(err => {
              handleException(err);
            });
        }
      })
      .catch(err => {
        setErr(
          err.data && err.data.message
            ? err.data.message
            : 'error attaching template',
        );
      });
  };

  //select template from list
  const selectTemplate = template => {
    const file = template.url;

    switch (template.ext) {
      case 'docx':
        return loadDocXIntoEditor(file, {
          campaign_id: send.id,
          template_id: template.id,
        });
      case 'html':
        return loadHTMLFileIntoEditor(template, true);
      default:
        setErr('This template has a format that is unhandled');
    }
  };

  useEffect(() => {
    const getCampaignMergeTags = async () => {
      await APIService.getCampaignDatasource(send.id)
        .then(res => {
          if (res.id) {
            if (res.preview.length > 1) {
              const csvColumns = res.preview[0];
              const mergeTags = [];
              Object.keys(csvColumns).forEach(key => {
                mergeTags.push(key);
              });
              addNewMergeTag(mergeTags);
            }
          } else {
            //setErr('No merge tags found for this id.');
            setTimeout(getCampaignMergeTags, 5000);
          }
        })
        .catch(err => {
          if (err.data && err.data.message) {
            setErr(err.data.message);
          } else {
            setErr('Failed to download Merge Tags');
          }
        });
    };

    if (send.id) {
      getCampaignMergeTags();
      if (templateType === null) {
        selectWhatTemplateType('selectTemplate');
        // loadHTMLFileIntoEditor()
      }
    }
  }, [send.id]);

  const loadTemplate = async () => {
    try {
      const data = await APIService.getTemplateById(templateId);
      const template = data.templates[0];
      setActiveTemplate(template);
      selectTemplate(template);
      setDocFileState(prevState => {
        return {
          ...prevState,
          docFile: {name: template.name},
          base64String: template.file_body,
          fileName: template.name,
          fileExt: template.ext,
        };
      });
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    if (send.id) {
      loadTemplate();
    }
  }, [send.id]);

  return {
    activeTemplate,
    loading,
    previewTemplate,
    selectWhatTemplateType,
    editorContent,
    setEditorContent,
    loadHTMLFileIntoEditor,
    setDocFileState,
    docFileState,
    selectWhatType,
    changeView,
    toggleModal,
    toggleModal2,
    viewState,
    mergeTags,
    setNewMergeTagModal,
    getRootProps,
    getInputProps,
    isDragActive,
    downloadUploadedTemplate,
    newMergeTagModal,
    setNewMergeTag,
    modal,
    modal2,
    preview,
    setPreview,
    addNewMergeTag,
    newMergeTag,
    loading,
  };
};

export default useWhatStep;
