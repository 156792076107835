import React from 'react';
import StepLayout from 'layouts/Step';
import './styles.scss';
import 'font-awesome/css/font-awesome.css';
import useStep from 'hooks/steps';
import useWhereStep from './hooks';
import UploadForm from './UploadForm';

const WhereStep = props => {
  const {error, setError, loading, send} = useStep();
  const {
    isDragActive,
    uploadState,
    getRootProps,
    getInputProps,
    getCsv,
    handleFormatChange,
    format,
    fileState,
    step,
    disabled,
    uploadFile,
    activeFile,
    showUpload,
    handleShowUpload,
  } = useWhereStep(send, setError);

  return (
    <StepLayout
      {...props}
      loading={loading && !send}
      step={step}
      error={error}
      disabled={disabled}
      nextAction={uploadFile}
      title="Send - Where are we sending?"
      buttonTitle={
        fileState.fileName ? `Upload ${fileState.fileName}` : 'Continue'
      }
      nextLink={`where/options`}
      previousLink={`who/options`}>
      <div className="row mx-0 justify-content-center align-items-center mt-5">
        {showUpload ? (
          <UploadForm
            isPremerge={send.campaign_id_type === 2}
            handleFormatChange={handleFormatChange}
            format={format}
            uploadState={uploadState}
            isDragActive={isDragActive}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
          />
        ) : (
          <section className="WhereStep-DatasourceContainer">
            <h2>{activeFile.name || `Datasource ${send.id}`} is attached.</h2>
            <div className="WhereStep-ButtonGroup">
              <button
                onClick={() => window.open(activeFile.url)}
                className="btn btn-secondary">
                DownloadCSV
          </button>
              {send.campaign_type_id !== 2 && (
                <button
                  className="btn btn-primary"
                  onClick={() => handleShowUpload(true)}>
                  Upload another
                </button>
              )}
            </div>
          </section>
        )}
      </div>
    </StepLayout>
  );
};

export default WhereStep;
