import React from 'react';
import Routes from 'routes';
import Store from 'store';
import {Provider as SendProvider} from '../../contexts/send';

import 'assets/scss/index.scss';

const App = () => {
  document.title = `Send App`;

  return (
    <SendProvider>
      <Store>
        <Routes />
      </Store>
    </SendProvider>
  );
};

export default App;
