import {useEffect, useCallback, useState} from 'react';
import APIService from 'services/backstrap/apiService';

const useWhoStepOptions = (send, setError) => {
  const {
    message1,
    message2,
    coversheet,
    perf,
    return_envelope,
    alert_color_id,
  } = send;

  const initialState = {
    message1: message1 || '',
    message2: message2 || '',
    coversheet: coversheet || false,
    perf: perf || false,
    return_envelope: return_envelope || false,
    alert_color_id: String(alert_color_id) || null,
  };

  const [values, setValues] = useState(initialState);

  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeToggle = attribute => {
    setValues(values => ({
      ...values,
      [attribute]: !values[attribute],
    }));
  };

  const patchCampaign = async () => {
    try {
      const formattedNumber =
        values.alert_color_id === null ? null : Number(values.alert_color_id);
      await APIService.patchCampaign({
        id: send.id,
        coversheet: values.coversheet,
        perf: values.perf,
        return_envelope: values.return_envelope,
        alert_color_id: formattedNumber,
        message1: values.message1,
        message2: values.message2,
      });
    } catch (err) {
      if (err.data && err.data.message) {
        setError(err.data.message);
      } else {
        setError('Failed to update this campaign');
      }
    }
  };

  useEffect(() => {
    const loadInitialValues = () => {
      setValues(initialState);
    };
    if (send.id) {
      loadInitialValues();
    }
  }, [send.id]);

  return {
    handleChange,
    values,
    patchCampaign,
    handleChangeToggle,
  };
};

export default useWhoStepOptions;
