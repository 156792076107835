import React from 'react';
import {useHistory} from 'react-router-dom';
import './StepNav.scss';
import Help from 'assets/img/ico_question.svg';
import NextIcon from './NextIcon';
import BackButton from './Back';

const StepNav = ({
  margin = 5,
  disabled = false,
  disableBack,
  previousLink,
  nextLink,
  title,
  nextAction,
  letterlistId,
}) => {
  const history = useHistory();

  const next = async () => {
    const link = letterlistId
      ? `/reviewed/letterlist/${letterlistId}`
      : nextLink;
    if (nextAction) {
      await nextAction();
      history.push(link);
    } else {
      history.push(link);
    }
  };

  const previous = () => {
    const link = previousLink.includes('new') ? '/send/new' : previousLink;
    history.push(link);
  };

  return (
    <div className={`step-nav col-sm-12 d-flex mb-5 mt-${margin}`}>
      <BackButton disableBack={disableBack} previousLink={previous} />
      <div className={`${disableBack ? 'col-sm-12' : 'col-sm-4'}`}>
        <button
          onClick={() => next()}
          className="btn btn-lg btn-block btn-primary btn-action"
          disabled={disabled}>
          <span className="action">{title}</span>
          <NextIcon disabled={disabled} />
        </button>
      </div>
      <div className="col-sm-4 d-none justify-content-end">
        <img src={Help} alt="Help" className="help" />
      </div>
    </div>
  );
};

export default StepNav;
