import React, {useEffect, useState} from 'react';
import APIService from 'services/backstrap/apiService';
import Header from 'components/Header/Header';
import NoDrafts from 'assets/img/ico_no_drafts.svg';
import {MAILHOUSE_URL} from 'config';

const Scheduled = props => {
  document.title = `Sent To Mailhouse`;

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCampaigns = async () => {
      await APIService.sentToCSMailhouse().then(res => {
        setCampaigns(res);
      });
      setLoading(false);
    };
    getCampaigns();
  }, []);

  return (
    <>
      <Header props={props} />
      <div className="container">
        {!loading && campaigns.length > 0 && (
          <div>
            <div className="row row-header justify-content-center mb-3 mt-5 ">
              <div className="col-sm-12 row">
                <div className="col">
                  <small className="th">Send Name</small>
                </div>
                <div className="col">
                  <small className="th">Mail Class</small>
                </div>
                <div className="col">
                  <small className="th">Send Id</small>
                </div>
                <div className="col">
                  <small className="th">Mailhouse Job Id</small>
                </div>
                <div className="col">
                  <small className="th">Sent On</small>
                </div>
                <div className="col">
                  <small className="th">Total Letters</small>
                </div>
              </div>
            </div>
            {campaigns.map((cmp, i) => {
              const sendToCSMailhouse = new Date(cmp.sent_to_csmailhouse);
              return (
                <div className="row justify-content-center" key={i}>
                  <div className="btn-row col-sm-12 pointer">
                    <div className="col">{cmp.campaign_name}</div>
                    <div className="col">{cmp.mail_class_id !== 1 ? cmp.delivery_confirmation : cmp.mail_class}</div>
                    <div className="col">{cmp.campaign_id}</div>
                    <div className="col">
                      <a
                        href={`${MAILHOUSE_URL}/campaign-details/${cmp.mailhouse_campaign_id}`}
                        target="_blank"
                        rel="noreferrer noopener">
                        {cmp.mailhouse_campaign_id}
                      </a>
                    </div>
                    <div className="col">
                      {sendToCSMailhouse.toLocaleString('en-US')}
                    </div>
                    <div className="col">{cmp.document_count}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!loading && campaigns.length === 0 && (
          <div className="full-center flex-column">
            <img src={NoDrafts} alt="" />
            <h1 className="mt-5 text-center">
              There are no Campaigns sent to CivicSource Mailhouse.
            </h1>
          </div>
        )}
        {loading && (
          <div className="full-center flex-column">
            <h1 className="mt-5 text-center">Loading...</h1>
          </div>
        )}
      </div>
    </>
  );
};

export default Scheduled;
