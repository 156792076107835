import React from 'react';
import reducer from 'reducer';

const initialState = {
  activeStep: null,
  isLoading: true,
  activeSendObject: {
    id: null,
    name: null,
    how: null,
    howType: null,
    options: {
      coversheet: false,
      perf: false,
      returnEnv: false,
      alertColor: null,
      message1: null,
      message2: null,
    },
    sender: {
      first_name: null,
      last_name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      from_organization_name: null,
    },
    where: null,
    what: {
      type: 'merge',
      file: null,
      templateType: null,
    },
    when: null,
    numLetters: 0,
    metadata: {
      organization_logo: null,
      logo_path: null,
    },
    datasource: null,
  },
  bs_user: {
    username: localStorage.getItem('cs_username') || null,
  },
  campaigns: null,
  isAuthenticated: localStorage.getItem('cs_token') ? true : false,
};

const CTX = React.createContext();

const Store = props => {
  const ctxVal = React.useReducer(reducer, initialState);

  return <CTX.Provider value={ctxVal}>{props.children}</CTX.Provider>;
};

export {CTX};
export default Store;
