import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';

const useNewSend = () => {
  const history = useHistory();
  const [values, setValues] = useState({name: ''});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const campaign = {
      name: values.name,
      paper_type_id: 1,
      mailhouse_id: 1,
      campaign_type_id: 1,
    };

    await APIService.postCampaign(campaign)
      .then(response => {
        history.push(`/send/${response.id}/how/`);
      })
      .catch(err => {
        if (err.data && err.data.message) {
          setError(err.data.message);
        } else {
          setError('Failed to create new campaign');
        }
      });
    setLoading(false);
  };

  const handleChange = event => {
    event.persist();
    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    values,
    handleChange,
    loading,
    error,
    handleSubmit,
  };
};

export default useNewSend;
