import React from 'react';
import StepLayout from 'layouts/Step';
import Toggle from 'react-toggle';
import useStep from 'hooks/steps';
import useWhoStepOptions from './hooks';

const Toggles = ({values, handleChangeToggle}) => (
  <>
    <div className="toggle-wrap d-flex flex-row my-5">
      <Toggle
        checked={values.coversheet}
        icons={false}
        onChange={() => handleChangeToggle('coversheet')}
      />
      <h4 className="m-0 ml-5">Include a coversheet?</h4>
    </div>
    <div className="toggle-wrap d-flex flex-row my-5">
      <Toggle
        checked={values.perf}
        icons={false}
        onChange={() => handleChangeToggle('perf')}
      />
      <h4 className="m-0 ml-5">Print on perforated paper?</h4>
    </div>

    <div className="toggle-wrap d-flex flex-row my-5">
      <Toggle
        checked={values.return_envelope}
        icons={false}
        onChange={() => handleChangeToggle('return_envelope')}
      />
      <h4 className="m-0 ml-5">Include a return envelope?</h4>
    </div>
  </>
);

const WhoStepOptions = props => {
  const {error, send, loading, setError, disabled} = useStep();
  const {
    values,
    handleChange,
    patchCampaign,
    handleChangeToggle,
  } = useWhoStepOptions(send, setError);
  console.log('render');
  return (
    <StepLayout
      {...props}
      loading={loading && !send.id}
      disabled={disabled}
      nextAction={patchCampaign}
      error={error}
      title="Send - Mailhouse Options"
      nextLink={`where`}
      previousLink={`who`}>
      <h1 className="mt-5">Which options should we include?</h1>
      <div className="row mx-0 justify-content-center align-items-center mt-5">
        <div className="col-sm-7">
          <Toggles values={values} handleChangeToggle={handleChangeToggle} />
          <div className="toggle-wrap d-flex flex-row my-5">
            <div
              id="alert-bar-colors"
              style={{display: 'flex', flexDirection: 'column'}}>
              <label style={{display: 'flex'}}>
                <input
                  type="radio"
                  value={null}
                  name="alert_color_id"
                  checked={values.alert_color_id === null}
                  onClick={handleChange}
                  style={{marginRight: '5px'}}
                />
                None
              </label>
              <label style={{display: 'flex'}}>
                <input
                  type="radio"
                  value={1}
                  name="alert_color_id"
                  checked={values.alert_color_id === '1'}
                  onClick={handleChange}
                />
                <div
                  style={{
                    marginLeft: '5px',
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'black',
                  }}></div>
              </label>
              <label style={{display: 'flex'}}>
                <input
                  type="radio"
                  value={2}
                  name="alert_color_id"
                  checked={values.alert_color_id === '2'}
                  onClick={handleChange}
                />
                <div
                  style={{
                    marginLeft: '5px',
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'red',
                  }}></div>
              </label>
              <label style={{display: 'flex'}}>
                <input
                  type="radio"
                  value={3}
                  name="alert_color_id"
                  checked={values.alert_color_id === '3'}
                  onClick={handleChange}
                />
                <div
                  style={{
                    marginLeft: '5px',
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'blue',
                  }}></div>
              </label>
              <label style={{display: 'flex'}}>
                <input
                  type="radio"
                  value={4}
                  name="alert_color_id"
                  checked={values.alert_color_id === '4'}
                  onClick={handleChange}
                />
                <div
                  style={{
                    marginLeft: '5px',
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'green',
                  }}></div>
              </label>
            </div>
            <h4 className="m-0 ml-5">Include an alert bar?</h4>
          </div>

          <br />
          <input
            type="text"
            name="message1"
            className="form-control mt-3"
            placeholder="Message 1"
            value={values.message1}
            onChange={handleChange}
          />
          <br />
          <input
            type="text"
            name="message2"
            className="form-control mt-3"
            placeholder="Message 2"
            value={values.message2}
            onChange={handleChange}
          />
        </div>
      </div>
    </StepLayout>
  );
};

export default WhoStepOptions;
