import React from 'react';
import StepLayout from 'layouts/Step';
import Month from 'components/Calendar/Month';
import DatepickerContext from 'components/Calendar/datePickerContext';
import backArrow from 'assets/img/ico_cal-arrow-left.svg';
import forwardArrow from 'assets/img/ico_cal-arrow-right.svg';
import useStep from 'hooks/steps';
import useWhenStep from './hooks';

const WhenStep = props => {
  document.title = `Send - When are we sending?`;
  const {send, error, setError, loading} = useStep();

  const {
    dateValues,
    activeMonths,
    goToPreviousMonths,
    goToNextMonths,
  } = useWhenStep(send, setError);

  return (
    <StepLayout
      {...props}
      loading={loading && !send}
      error={error}
      title="Send - When are we sending?"
      nextLink={`confirm`}
      previousLink={`what`}>
      <h1 className="mt-5">When are we sending?</h1>
      <div className="row mx-0 text-center mt-5 justify-content-center">
        <DatepickerContext.Provider value={dateValues}>
          <div className="card calendar-card d-flex align-items-center">
            <div
              className="calendar-wrap card-body"
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${activeMonths.length}, 100%)`,
                gridGap: '0 64px',
                justifyItems: 'center',
              }}>
              <button
                className="month-toggle back"
                type="button"
                onClick={goToPreviousMonths}>
                <img src={backArrow} alt="Previous Month" />
              </button>
              <button
                className="month-toggle forward"
                type="button"
                onClick={goToNextMonths}>
                <img src={forwardArrow} alt="Previous Month" />
              </button>

              {activeMonths.map(month => (
                <Month
                  key={`${month.year}-${month.month}`}
                  year={month.year}
                  month={month.month}
                  firstDayOfWeek={0}
                />
              ))}
            </div>
          </div>
        </DatepickerContext.Provider>
      </div>
    </StepLayout>
  );
};

export default WhenStep;
