import React from 'react';
import StepLayout from 'layouts/Step';
import ActionButton from 'components/ActionButton/ActionButton';
import imgMail from 'assets/img/ico_mail.svg';
import imgERR from 'assets/img/ico_err.svg';
import imgBoth from 'assets/img/ico_both.svg';
import useHowStep from './hooks';

const HowStep = props => {
  const {howType, calcPrice, handleChange, disabled, error, mailClassId} = useHowStep();
  return (
    <StepLayout
      {...props}
      disabled={disabled}
      error={error}
      nextLink={`who`}
      previousLink={`how`}>
      <h1 className="mt-5">How are we sending?</h1>
      <div className="row mx-0 text-center mt-5">
        <div className="col-sm-6">
          <div className="card-button nb">
            <div className="card-body">
              {mailClassId === 1 ? <img src={imgMail} alt="" /> : null}
              {mailClassId === 2 ? <img src={imgERR} alt="" /> : null}
              {mailClassId === 3 ? <img src={imgBoth} alt="" /> : null}
              <h4>
                {mailClassId === 1 ? 'First-Class Mail' : null}
                {mailClassId === 2 ? 'Certified Mail® Services' : null}
                {mailClassId === 3 ? 'First Class + Certified Mail®' : null}
              </h4>
              <p>Starting at ${calcPrice(mailClassId)}</p>
            </div>
          </div>
        </div>

        <div className="col-sm-6">
          <ActionButton
            title="Electronic Delivery Confirmation"
            subtitle="Starting at $x.xx a notice"
            type={'horizontal'}
            value={'EDC'}
            action={e => handleChange(e.target.value)}
            active={howType === 'EDC' ? true : false}
          />

          <ActionButton
            title="Green Card Return Receipt"
            subtitle="Starting at $x.xx a notice."
            type={'horizontal'}
            value={'GCRR'}
            action={e => handleChange(e.target.value)}
            active={howType === 'GCRR' ? true : false}
          />

          <ActionButton
            title="Electronic Return Receipt"
            subtitle="Starting at $x.xx a notice"
            type={'horizontal'}
            value={'ERR'}
            action={e => handleChange(e.target.value)}
            active={howType === 'ERR' ? true : false}
          />
        </div>
      </div>
    </StepLayout>
  );
};

export default HowStep;
