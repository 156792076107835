import React from 'react';
import plus from 'assets/img/ico_plus.svg';

const Loading = ({loading}) => {
  if (loading) {
    return (
      <div className="full-center flex-column">
        <h1 className="mt-5 text-center">
          Loading campaigns...
          <small>
            Press <img src={plus} className="plus-home" /> to create new send.
          </small>
        </h1>
      </div>
    );
  }
  return null;
};

export default Loading;
