import React from 'react';
import StepLayout from 'layouts/Step';
import 'font-awesome/css/font-awesome.css';
import Header from 'components/Header/Header';
import ProgressNav from 'components/ProgressNav/ProgressNav';
import ActionButton from 'components/ActionButton/ActionButton';
import imgMerge from 'assets/img/ico_merge.svg';
import imgPrint from 'assets/img/ico_print.svg';
import useStep from 'hooks/steps';
import useWhatStep from './hooks';

import './styles.scss';

const WhatStep = props => {
  const {setError, send, loading, error} = useStep();
  const {handleChange, whatType, reviewLetters, isPreMerged} = useWhatStep(
    send,
    setError,
  );

  return (
    <StepLayout
      {...props}
      loading={loading && !send}
      disabled={!whatType}
      error={error}
      title="Send - What are we sending?"
      nextLink={isPreMerged ? 'when' : `what/templates`}
      previousLink={`where/options`}>
      <div className="container">
        <>
          <h1 className="mt-5 text-center">What are we sending?</h1>
          <div className="row mx-0 text-center justify-content-center mt-5">
            <ActionButton
              title="Mail Merge"
              subtitle="Customized document"
              img={imgMerge}
              value={'merge'}
              columns={2}
              modalClick={''}
              action={handleChange}
              active={whatType === 'merge'}
            />
            <ActionButton
              title="Print Ready"
              subtitle="Uniform document"
              img={imgPrint}
              value={'print'}
              columns={2}
              action={handleChange}
              active={whatType === 'print'}
            />
          </div>
          {isPreMerged && (
            <button className="mt-5 btn btn-secondary" onClick={reviewLetters}>
              Review Letters
            </button>
          )}
        </>
      </div>
    </StepLayout>
  );
};

export default WhatStep;
