import {useState, useContext, useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import APIService from 'services/backstrap/apiService';
import {Context as SendContext} from 'contexts/send';

const useUploadHook = () => {
  const [docFile, setDocFile] = useState(null);
  const [uploadState, setUploadState] = useState('ready');
  const {whatType, send} = useContext(SendContext);
  const history = useHistory();

  const onDrop = useCallback(files => {
    let file = files[0];
    const reader = new FileReader();
    reader.onload = event => {
      let matches = event.target.result.match(
        /^data:[^\/]+\/([^;]+);base64,(.*)$/,
      );
      let fileNameParts = file.name.split('.');
      let fileExt = fileNameParts[fileNameParts.length - 1];
      if (matches.length === 3) {
        fileExt = matches[1];
      }

      setDocFile(prevState => {
        return {
          ...prevState,
          docFile: file,
          base64String: matches[matches.length - 1],
          fileName: file.name,
          fileExt: fileExt,
        };
      });
    };
    reader.readAsDataURL(file);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const downloadUploadedTemplate = async evt => {
    evt.stopPropagation();
    try {
      const template = await APIService.getTemplate(
        send.id,
        send.template_do_merge,
      );
      window.open(template.url);
    } catch (err) {
      console.log('error downloading template:', err);
    }
  };

  useEffect(() => {
    const uploadFile = async () => {
      if (docFile.base64String) {
        setUploadState('uploading');
        try {
          const response = await APIService.postCampaignTemplate({
            campaign_id: send.id,
            merge: whatType === 'merge',
            file_name: docFile.fileName,
            file: {
              body: docFile.base64String,
              ext: docFile.fileExt,
            },
          });
          if (docFile.fileExt === 'html') {
            history.push(
              `/send/${send.id}/what/templates/${response.template_id}/editor`,
            );
          } else {
            history.push(`/send/${send.id}/when`);
          }
          setUploadState('success');
          setTimeout(() => setUploadState('ready'), 3000);
        } catch (err) {
          console.log(err);
          if (err.data && err.data.message) {
            setUploadState('error');
          }
        }
      }
    };
    if (send.id && docFile) {
      uploadFile();
    }
    if (uploadState === 'success') {
    }
  }, [send.id, whatType, docFile]);

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    whatType,
    uploadState,
    docFile,
    downloadUploadedTemplate,
  };
};

export default useUploadHook;
