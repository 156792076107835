import React, { useEffect } from 'react';

import {Document, Page, pdfjs} from 'react-pdf';
import './PdfViewer.scss';

const PdfViewer = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect( () =>  {
    setView(lastState => {return {...lastState, url:props.url}});
  }
  , [props.url] 
);
 
  //set view state
  const initViewState = {page:1, numPages:0, url:props.url};
  const [viewState, setView] = React.useState(initViewState);
  
  const nextPage = () => {
    if(viewState.page < viewState.numPages) {
      setView(lastState => { return {...lastState, page: viewState.page+1} })
    }
  };
  const prevPage = () => {
    if(viewState.page > 1) {
      setView(lastState => { return {...lastState, page: viewState.page-1} })
    }
  };

  return (
    <div className={props.mode !== 'upload' ? "pdf-viewer-wrap d-flex flex-column align-items-center justify-content-center" : "pdf-viewer-wrap upload-preview d-flex flex-column align-items-center justify-content-center"}>
      {props.arrows ? 
      <>
      <div className="pdf-with-arrows d-flex flex-row">
        <button className="btn btn-clear" onClick={prevPage}> 
          <svg xmlns="http://www.w3.org/2000/svg" width="5.316" height="8.35" viewBox="0 0 5.316 8.35">
            <g id="noun_Left_1322309" transform="translate(-9.82 -8.236)">
              <path id="Path" d="M.385.339a1.093,1.093,0,0,1,1.584,0L5,3.371H5a1.091,1.091,0,0,1,.317.815A1.091,1.091,0,0,1,5,5H5L1.969,8.032a1.091,1.091,0,0,1-.815.317,1.091,1.091,0,0,1-.815-.317,1.093,1.093,0,0,1,0-1.584L2.6,4.141.385,1.923A1.093,1.093,0,0,1,.385.339Z" transform="translate(15.137 16.586) rotate(-180)" fill="#4099FE"/>
            </g>
          </svg>
        </button>
        <Document file={viewState.url}
          onLoadSuccess={(pdf) => {
            setView(lastState => { return {...lastState, numPages: pdf._pdfInfo.numPages} });
          }} 
          noData={<h4>Generating Preview...</h4>}>
            <Page pageNumber={viewState.page}/>
        </Document>
        <button className="btn btn-clear" onClick={nextPage}> 
          <svg xmlns="http://www.w3.org/2000/svg" width="5.316" height="8.35" viewBox="0 0 5.316 8.35">
            <g id="noun_Left_1322309" transform="translate(15.137 16.586) rotate(180)">
              <path id="Path" d="M.385.339a1.093,1.093,0,0,1,1.584,0L5,3.371H5a1.091,1.091,0,0,1,.317.815A1.091,1.091,0,0,1,5,5H5L1.969,8.032a1.091,1.091,0,0,1-.815.317,1.091,1.091,0,0,1-.815-.317,1.093,1.093,0,0,1,0-1.584L2.6,4.141.385,1.923A1.093,1.093,0,0,1,.385.339Z" transform="translate(15.137 16.586) rotate(-180)" fill="#4099FE"/>
            </g>
          </svg>
        </button>
      </div>
      </>
      :
      <Document file={viewState.url}
        onLoadSuccess={(pdf) => {
          setView(lastState => { return {...lastState, numPages: pdf._pdfInfo.numPages} });
        }} 
        noData={<h4>Generating Preview...</h4>}>
          <Page pageNumber={viewState.page} height={window.innerHeight * 0.5} />
      </Document>
      }


      <div className="d-flex justify-content-center align-items-center my-3">
        <button className="btn btn-clear" onClick={prevPage}> 
          <svg xmlns="http://www.w3.org/2000/svg" width="5.316" height="8.35" viewBox="0 0 5.316 8.35">
            <g id="noun_Left_1322309" transform="translate(-9.82 -8.236)">
              <path id="Path" d="M.385.339a1.093,1.093,0,0,1,1.584,0L5,3.371H5a1.091,1.091,0,0,1,.317.815A1.091,1.091,0,0,1,5,5H5L1.969,8.032a1.091,1.091,0,0,1-.815.317,1.091,1.091,0,0,1-.815-.317,1.093,1.093,0,0,1,0-1.584L2.6,4.141.385,1.923A1.093,1.093,0,0,1,.385.339Z" transform="translate(15.137 16.586) rotate(-180)" fill={props.mode !== 'upload' ? "#fff" : "#4099fe"} />
            </g>
          </svg>
        </button>
        <span className="mx-3 page-count">{viewState.page} of {viewState.numPages}</span>
        <button className="btn btn-clear" onClick={nextPage}> 
          <svg xmlns="http://www.w3.org/2000/svg" width="5.316" height="8.35" viewBox="0 0 5.316 8.35">
            <g id="noun_Left_1322309" transform="translate(15.137 16.586) rotate(180)">
              <path id="Path" d="M.385.339a1.093,1.093,0,0,1,1.584,0L5,3.371H5a1.091,1.091,0,0,1,.317.815A1.091,1.091,0,0,1,5,5H5L1.969,8.032a1.091,1.091,0,0,1-.815.317,1.091,1.091,0,0,1-.815-.317,1.093,1.093,0,0,1,0-1.584L2.6,4.141.385,1.923A1.093,1.093,0,0,1,.385.339Z" transform="translate(15.137 16.586) rotate(-180)" fill={props.mode !== 'upload' ? "#fff" : "#4099fe"} />
            </g>
          </svg>
        </button>
      </div>
    </div>   
  )
}

export default PdfViewer;
