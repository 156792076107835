import {useEffect, useState, useContext} from 'react';
import {Context as SendContext} from 'contexts/send';
import {useHistory} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';

const useSendConfirm = (send, setError) => {
  const history = useHistory();
  const {setNumberOfLetters, numberOfLetters} = useContext(SendContext);
  const [viewState, setView] = useState({perLetterPrice: 0.7});
  const [uploadState, changeUploadState] = useState('ready');
  const [preview, setPreview] = useState({show: false, preview: null});
  const [isMerged, setMergeState] = useState(false);
  const [when, setWhen] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const linkTo = step => history.push(`/send/${send.id}/${step}`);
  const isPreMerged = send.campaign_type_id === 2;

  const linkToResults = () => history.push('/campaigns?status=SCHEDULED');

  const nextStep = async () => {
    closeModal()
    try {
      await APIService.sendCampaign({campaign_id: send.id});
      changeUploadState('success');
    } catch (err) {
      if (err.data && err.data.message) {
        setError(err.data.message);
        changeUploadState('error');
        setTimeout(() => changeUploadState('ready'), 3000);
      } else {
        setError('Failed send this campaign');
      }
    }
  };

  const confirmSend = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const goToPreviews = () => {
    setPreview(lastState => {
      return {...lastState, show: true, preview: preview.currentPreview};
    });
  };

  const selectPreview = previewKey => {
    switch (previewKey) {
      case 'first':
        setView(prevState => {
          return {...prevState, currentPreview: viewState.firstRowPreview};
        });
        break;
      case 'long':
        setView(prevState => {
          return {...prevState, currentPreview: viewState.longestRowPreview};
        });
        break;
      case 'short':
        setView(prevState => {
          return {...prevState, currentPreview: viewState.shortestRowPreview};
        });
        break;
      default:
        break;
    }
  };

  const getPrice = () => {
    if (viewState.perLetterPrice !== null) {
      return (send.numLetters * viewState.perLetterPrice).toFixed(2);
    } else {
      return 0.0;
    }
  };

  const reviewLetters = () => {
    history.push(`/reviewed/letterlist/${send.id}`);
  };

  const testMerge = async () => {
    try {
      await APIService.mergeCampaign({campaign_id: send.id});
      setMergeState(true);
    } catch (err) {
      if (err.data && err.data.message) {
        setError(err.data.message);
      } else {
        setError('Failed to merge');
      }
    }
  };

  const whoName = send.from_last_name
    ? `${send.from_first_name} ${send.from_last_name}`
    : send.from_organization_name;

  useEffect(() => {
    if (send.id) {
      // SET THE PER LETTER PRICE
      if (send.how === 'CMS') {
        setView(lastState => {
          return {...lastState, perLetterPrice: 7.3};
        });
      } else if (send.how === 'BOTH') {
        setView(lastState => {
          return {...lastState, perLetterPrice: 8};
        });
      } else {
        setView(lastState => {
          return {...lastState, perLetterPrice: 0.7};
        });
      }

      APIService.getDocumentStatuses(send.id)
        .then(statuses => {
          setNumberOfLetters(statuses.total_count);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [send.how, send.id, send, setNumberOfLetters]);

  useEffect(() => {
    const loadPreviews = async () => {
      try {
        const results = await APIService.getPreviews(send.id);

        setView(lastState => {
          return {
            ...lastState,
            currentPreview: results.first,
            firstRowPreview: results.first,
            longestRowPreview: results.long,
            shortestRowPreview: results.short,
            page: 1,
          };
        });
      } catch (err) {
        console.log(err);
      }
    };

    if (send.id) {
      let offset = new Date(send.send_on_date).getTimezoneOffset();
      let adjDate = new Date(send.send_on_date).getTime() + offset * 60 * 1000;
      setWhen(new Date(adjDate));
      loadPreviews();
    }
  }, [send.id]);

  return {
    linkTo,
    goToPreviews,
    uploadState,
    setPreview,
    viewState,
    reviewLetters,
    testMerge,
    isMerged,
    getPrice,
    linkToResults,
    preview,
    nextStep,
    selectPreview,
    whoName,
    numberOfLetters,
    when,
    isPreMerged,
    confirmSend,
    showModal,
    closeModal,
  };
};

export default useSendConfirm;
