export const customStyles = {
  control: (base, state) => ({
    ...base,
    background: 'transparent',
    borderRadius: 0,
    borderColor: '#ced4da',
    borderRight: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    boxShadow: null,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    fontWeight: 'lighter',
    fontSize: '20px',
    letterSpacing: '1px',
    padding: '0.5rem 0',
    width: '100%',
    paddingBottom: '3px',
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    hyphens: 'auto',
    marginTop: 0,
    textAlign: 'left',
    wordWrap: 'break-word',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  }),
};
