
//documenation on froala plugins
//https://froala.com/wysiwyg-editor/docs/concepts/custom/plugin/
//documentation on froala popups
//https://froala.com/wysiwyg-editor/docs/concepts/custom/popup/


import Froalaeditor from 'froala-editor';



// Define popup template.
Object.assign(Froalaeditor.POPUP_TEMPLATES, {
  'mergeTag.popup': '[_BUTTONS_][_CUSTOM_LAYER_]', 
  "mergeTag.smarty": "[_BUTTONS_][_INPUT_LAYER_]"
});

// Define popup buttons.
Object.assign(Froalaeditor.DEFAULTS, {
  mergeTagPopupButtons: ['mergeTagClose', '|', 'popupButton1'],
  smartyPopupButtons: ['back'],
});

// The custom popup is defined inside a plugin (new or existing).
Froalaeditor.PLUGINS.mergeTag = function (editor) {
  var k = editor.$;

  // Create custom popup.
  function initMergeTagPopup () {
    // Load popup template.
    var template = Froalaeditor.POPUP_TEMPLATES.customPopup;
    if (typeof template == 'function') template = template.apply(editor);

    // Popup buttons.
    var popup_buttons = '';

    // Create the list of buttons.
    if (editor.opts.mergeTagPopupButtons.length > 0) {
      popup_buttons += '<div class="fr-buttons">';
      popup_buttons += editor.button.buildList(editor.opts.mergeTagPopupButtons);
      popup_buttons += '</div>';
    }

    // Load popup template.
    var template = {
      buttons: popup_buttons,
      custom_layer: ''
    };

    // Create popup.
    var $popup = editor.popups.create('mergeTag.popup', template);

    return $popup;
  }

  // Show the popup
  function showMergeTagPopup () {

    var $popup = editor.popups.get('mergeTag.popup');
    if (!$popup) $popup = initMergeTagPopup();

    
    editor.popups.setContainer("mergeTag.popup", editor.$sc);
    
    //set the popup relative to the element
    var element = k(editor.selection.element());
    var left = element.offset().left + element.outerWidth() / 2;
    var top = element.offset().top + element.outerHeight();
    editor.popups.show("mergeTag.popup", left, top, element.outerHeight(), !0);

    //blur the selection
    editor.events.disableBlur(); editor.$el.blur(); editor.events.enableBlur();
  }

  // Hide the custom popup.
  function hideMergeTagPopup() {
    editor.popups.hide('mergeTag.popup');
  }


  function removeMergeTag () {
    editor.popups.hide('mergeTag.popup');

    //some voodoo to remove the selection
    //look up 5 elements
    for(var i = 0; i < 5; i++){
      var element;
      if(!editor.node.isDeletable(editor.selection.element()))
      {
        element = k(editor.selection.element());
        editor.selection.setAfter(element);
        editor.selection.restore();
      }
      else{
        break;
      }
    }

    //once we have the deletable element, move to the end of it, and hit backspace
    element = k(editor.selection.element());
    editor.selection.setAfter(element);
    editor.selection.restore();

    editor.cursor.backspace();

  }
  
  function handleAlternateInteraction(r){
    if (r.type == "mouseup" || r.which != Froalaeditor.KEYCODE.ESC && editor.core.hasFocus()) {
      if(editor.node.hasClass(editor.selection.element(), 'LF-DATA')){
        r.preventDefault();
        showMergeTagPopup();
        return false;
      }
    }
  }

  //we need to be able to save the seleciton to find which tag was selected for inserting smarty function
  //in order to know what element is on the cursor, froala uses selections, and we use it to be able find it to edit said element
  function saveMergeTagSelection(){
    editor.selection.save();
    editor.selection.restore();
  }

  
  function showSmartyPopup() {
    var $popup = editor.popups.get('mergeTag.smarty');
    if (!$popup) $popup = initSmartyPopup();

    
    editor.popups.setContainer("mergeTag.smarty", editor.$sc);
    
    //set the popup relative to the element
    var element = k(editor.selection.element());
    var left = element.offset().left + element.outerWidth() / 2;
    var top = element.offset().top + element.outerHeight();
    editor.popups.show("mergeTag.smarty", left, top, element.outerHeight(), !0);

  }
  
  function hideSmartyPopup() {
    editor.popups.hide('mergeTag.smarty');
  }

  function insertSmartyFunction() {
    var smartyPopup = editor.popups.get('mergeTag.smarty');
    var smartyFunction = smartyPopup.find('input[name="smartyFunction"]').val() || "";
    hideSmartyPopup();

    var element = k(editor.selection.element());
    if(element.hasClass('LF-DATA-FIELD-TEXT')){
      element = element.parent();
    }
    
    var dataEl = element.find('.LF-DATA-FIELD-DATA');
    var textEl = element.find('.LF-DATA-FIELD-TEXT');

    if(!dataEl || !textEl){
      console.error("MergeTagPlugin - Unable to find data to replace Smarty Function");
      return;
    }

    var data = dataEl.html();
    var text = textEl.html();

    //check for existing smarty functions, by removing after | character of smarty function
    //get the data in a format of :  {$mergetag
    //so we can append smarty function and then reappend the ending }
    var pureData = data.indexOf('|') > -1 ? data.substring(0, data.indexOf('|')) : data.substring(0, data.indexOf('}'));
    var pureText = text.indexOf('|') > -1 ? text.substring(0, text.indexOf('|')) : text;

    var smartyData = pureData+'|'+smartyFunction + '}';
    var smartyText = pureText+'|'+smartyFunction;

    textEl.html(smartyText);
    dataEl.html(smartyData);

    hideMergeTagPopup();
  }
  
  

  function initSmartyPopup () {
    // Load popup template.
    var template = Froalaeditor.POPUP_TEMPLATES.customPopup;
    if (typeof template == 'function') template = template.apply(editor);

    // Popup buttons.
    var popup_buttons = '';

    // Create the list of buttons.
    if (editor.opts.smartyPopupButtons.length > 0) {
      popup_buttons = '<div class="fr-buttons fr-tabs">'.concat(editor.button.buildList(editor.opts.smartyPopupButtons), "</div>");
    }

    // Load popup template.
    var template = {
      buttons: popup_buttons,
      input_layer: '<div class="fr-layer fr-active" id="lf-smartyFunction-layer-'
                  .concat(editor.id, '"><div class="fr-input-line"><input id="lf-smartyFunction-')
                  .concat(editor.id, '" type="text" name="smartyFunction" placeholder="Smarty Function" tabIndex="1"></div><div class="fr-action-buttons"><button type="button" class="fr-command fr-submit" data-cmd="insertSmartyFunction" tabIndex="2" role="button">Insert</button></div></div>')
    };

    // Create popup.
    var $popup = editor.popups.create('mergeTag.smarty', template);

    return $popup;
  }


  function _init () {
    // You can access any option from documentation or your custom options.

    editor.events.on("keyup", function (e) {
        return handleAlternateInteraction(e);
    });
    editor.events.on("window.mouseup", function(e){ return handleAlternateInteraction(e); });

    editor.events.$on(editor.$el, "click", ".LF-DATA-FIELD", function (e) {
        e.preventDefault();
        showMergeTagPopup();
        editor.edit.isDisabled() && e.preventDefault();
    });

  }

  // Methods visible outside the plugin.
  return {
    _init: _init,
    showMergeTagPopup: showMergeTagPopup,
    hideMergeTagPopup: hideMergeTagPopup,
    removeMergeTag: removeMergeTag,
    saveMergeTagSelection: saveMergeTagSelection,
    showSmartyPopup: showSmartyPopup,
    hideSmartyPopup: hideSmartyPopup,
    insertSmartyFunction: insertSmartyFunction
  }
}

// Define custom popup close button icon and command.
Froalaeditor.DefineIcon('mergeTagClose', { NAME: "close", SVG_KEY: 'close'});
Froalaeditor.RegisterCommand('mergeTagClose', {
  title: 'Remove Merge Tag',
  undo: false,
  focus: false,
  callback: function () {
    this.mergeTag.removeMergeTag();
  }
});

// Define custom popup 1.
Froalaeditor.DefineIcon('back', { NAME: "back", SVG_KEY: 'back'});
Froalaeditor.RegisterCommand('back', {
  title: 'Back',
  undo: false,
  focus: false,
  callback: function () {
    this.mergeTag.hideSmartyPopup();
    this.mergeTag.showMergeTagPopup();
  }
});

Froalaeditor.RegisterCommand("insertSmartyFunction", {
  undo: !0,
  focus: !1,
  title: "Insert",
  refreshAfterCallback: !1,
  callback: function () {
      this.mergeTag.insertSmartyFunction();
  },
});


// Define custom popup 1.
Froalaeditor.DefineIcon('popupButton1', { NAME: "codeView", SVG_KEY: 'codeView'});
Froalaeditor.RegisterCommand('popupButton1', {
  title: 'Insert Smarty Function',
  undo: false,
  focus: false,
  callback: function () {
    this.mergeTag.saveMergeTagSelection();
    this.mergeTag.showSmartyPopup();
  }
});
