import {
  Act384BentonTemplate,
  AudrainNoticeofTaxSale,
  CityOfPonchatoulaAdjudicatedCampaign,
  CityOfPonchatoulaSoldTemplate,
  GreeneNoticeOfTaxSaleTemplate,
  TangipahoaParishAdjudicatedTemplate,
  TangipahoaParishSoldTemplate,
  TownOfAmiteCityAdjudicatedTemplate,
  TownOfAmiteCitySoldTemplate,
  HeaderPartial,
  FooterPartial,
  TaxTablePartial,
  PerforationPartial,
} from '../fixtures';

const getTags = template => template.match(/\$(.*?)\}/g);

const getHtmlTemplates = () =>
  Promise.resolve({
    data: [
      {
        ext: 'html',
        id: 1,
        name: 'Act 384 Benton Template',
        file: Act384BentonTemplate,
        merge_tags: getTags(Act384BentonTemplate),
      },
      {
        ext: 'html',
        id: 2,
        name: 'Audrain Notice of Tax Sale',
        file: AudrainNoticeofTaxSale,
        merge_tags: getTags(AudrainNoticeofTaxSale),
      },
      {
        ext: 'html',
        id: 3,
        name: 'City of Ponchatoula Sold Template',
        file: CityOfPonchatoulaSoldTemplate,
        merge_tags: getTags(CityOfPonchatoulaSoldTemplate),
      },
      {
        ext: 'html',
        id: 4,
        name: 'City of Ponchatoula Adjudicated Template',
        file: CityOfPonchatoulaAdjudicatedCampaign,
        merge_tags: getTags(CityOfPonchatoulaAdjudicatedCampaign),
      },
      {
        ext: 'html',
        id: 5,
        name: 'Tangipahoa Parish Audjudicated Template',
        file: TangipahoaParishAdjudicatedTemplate,
        merge_tags: getTags(TangipahoaParishAdjudicatedTemplate),
      },
      {
        ext: 'html',
        id: 6,
        name: 'Tangipahoa Parish Sold Template',
        file: TangipahoaParishSoldTemplate,
        merge_tags: getTags(TangipahoaParishSoldTemplate),
      },
      {
        ext: 'html',
        id: 7,
        name: 'Town of Amite City Adjudicated Template',
        file: TownOfAmiteCityAdjudicatedTemplate,
        merge_tags: getTags(TownOfAmiteCityAdjudicatedTemplate),
      },
      {
        ext: 'html',
        id: 8,
        name: 'Town of Amite City Sold Template',
        file: TownOfAmiteCitySoldTemplate,
        merge_tags: getTags(TownOfAmiteCitySoldTemplate),
      },
    ],
  });

const getPartial = template => {
  switch (template) {
    case 'header':
      return HeaderPartial;
    case 'footer':
      return FooterPartial;
    case 'taxTable':
      return TaxTablePartial;
    case 'perforation':
      return PerforationPartial;
    default:
      return null;
  }
};

const TemplateService = {
  getHtmlTemplates,
  getPartial,
};

export default TemplateService;
