import React from 'react';
import Back from 'assets/img/ico_arrow-left.svg';

const BackButton = ({disableBack, previousLink}) => {
  if (!disableBack) {
    return (
      <div className="col-sm-4 d-flex justify-content-start">
        <div onClick={() => previousLink()} className="step-nav-back">
          <img src={Back} alt="Go to previous Step" className="arrow-left" />
        </div>
      </div>
    );
  }
  return null;
};

export default BackButton;
