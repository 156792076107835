import React from 'react';
import StepLayout from 'layouts/Step';
import RightCarat from './Icon';
import useStep from 'hooks/steps';
import useNameSend from './hooks';

const NameStep = props => {
  const {send} = useStep();
  const {values, handleChange, handleSubmit, error} = useNameSend(send);

  return (
    <StepLayout {...props} error={error} noSteps title="Let's Send">
      <h1 className="mt-5">Let’s send.</h1>
      <form
        onSubmit={handleSubmit}
        className="row my-6 justify-content-center  align-items-center flex-column">
        <div className="col-sm-7">
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            placeholder="Name this send"
            value={values.name}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-4 mt-6">
          <button
            type="submit"
            className="btn btn-primary btn-action-home full-width"
            disabled={values.name.length === 0}>
            <span className="action">START</span>
            <RightCarat disabled={values.name.length === 0} />
          </button>
        </div>
      </form>
    </StepLayout>
  );
};

export default NameStep;
