import React from 'react';
import Toast from 'components/Toast/Toast';

const ErrorToast = ({error}) => {
  if (error) {
    return <Toast message={error} type="danger" />;
  }
  return null;
};

export default ErrorToast;
