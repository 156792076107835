import {useState, useEffect} from 'react';
import Froalaeditor from 'froala-editor';
import TemplateService from 'services/templates';
import {getMergeTagHTML} from 'helpers/mergeTagsHTML';

const useFroala = (mergeTags, addNewMergeTagPress) => {
  const toolbarButtons = [
    ['bold', 'italic', 'underline'],
    ['align'],
    ['fontSize', 'fontFamily', 'textColor', 'backgroundColor'],
    ['insertTable', 'formatOL', 'formatUL', 'insertImage', 'page_break'],
    ['undo', 'redo', 'my_dropdown'],
    ['insertTable', 'add_html_template'],
  ];

  const [tagStatus, setTagStatus] = useState(null);

  const insertHTML = (editorInstance, html) => {
    editorInstance.html.insert(html);
    editorInstance.events.trigger('contentChanged'); //need this to make sure the model updates after inserting
  };

  const froalaConfig = {
    placeholder: 'Edit Me',
    attribution: true,
    documentReady: true,
    htmlRemoveTags: ['script'],
    events: {
      //make image uploads upload as base64
      'image.beforeUpload': function(files) {
        const editor = this;
        if (files.length) {
          // Create a File Reader.
          const reader = new FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload = function(e) {
            const result = e.target.result;
            editor.image.insert(result, null, null, editor.image.get());
          };
          // Read image as base64.
          reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      },
    },
    toolbarButtons: toolbarButtons,
  };

  const insertElement = (editor, template) => {
    const partial = TemplateService.getPartial(template);
    insertHTML(editor, partial);
  };

  useEffect(() => {
    const insertMergeTag = (editorInstance, data, displayText) => {
      let tmp = [];
      for (const [key, value] of Object.entries(mergeTags)) {
        if (value !== 'ADD NEW MERGE TAG')
          tmp.push({name: value, active: false});
      }
      const arr = (tmp.find(obj => obj.name === displayText).active = true);
      setTagStatus(arr);

      insertHTML(editorInstance, getMergeTagHTML(displayText || data, data));
    };
    const insertPageBreak = editorInstance => {
      //fr-deletable class needed to allow deleting as whole element inside plugin
      //LF-REMOVABLE and LF-REMOVEABLE-PIECE classes needed for html-removeable-plugin to trigger
      insertHTML(
        editorInstance,
        `<p class="LF-PG-BREAK LF-REMOVEABLE LF-REMOVEABLE-PIECE fr-deletable" style="page-break-before: always;"><span class="LF-PG-BREAK-TEXT LF-REMOVEABLE LF-REMOVEABLE-PIECE">PAGE BREAK</span></p>`,
      );
    };

    const froalaInit = () => {
      Froalaeditor.DefineIcon('add_html_template', {
        NAME: 'Add Template',
        template: 'text',
      });

      Froalaeditor.RegisterCommand('add_html_template', {
        title: 'Add Element',
        type: 'dropdown',
        focus: false,
        undo: true,
        options: {
          header: 'Header',
          footer: 'Footer',
          perforation: 'Perforation',
          taxTable: 'Tax Table',
        },
        refreshAfterCallback: true,
        htmlExecuteScripts: true,
        immediateReactModelUpdate: true,
        callback: function(cmd, val) {},
      });
      Froalaeditor.DefineIcon('my_dropdown', {NAME: 'star', SVG_KEY: 'star'});
      Froalaeditor.RegisterCommand('my_dropdown', {
        title: 'Insert Merge Tag',
        type: 'dropdown',
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        htmlExecuteScripts: true,
        options: mergeTags,
        immediateReactModelUpdate: true,
        callback: function(cmd, val) {
          if (val === 'ADD NEW') {
            addNewMergeTagPress();
          } else {
            insertMergeTag(this, val, mergeTags[val]);
          }
        },
      });

      Froalaeditor.DefineIcon('page_break', {
        NAME: 'window-minimize',
        template: 'font_awesome',
      });

      Froalaeditor.RegisterCommand('page_break', {
        title: 'Insert Page Break',
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        callback: function() {
          insertPageBreak(this);
        },
      });

      Froalaeditor.DefineIcon('add_html_template', {
        NAME: 'Add Element',
        template: 'text',
      });

      Froalaeditor.RegisterCommand('add_html_template', {
        title: 'Add Element',
        type: 'dropdown',
        focus: false,
        undo: false,
        options: {
          header: 'Header',
          footer: 'Footer',
          taxTable: 'Tax Table',
          perforation: 'Perforation',
        },
        refreshAfterCallback: true,
        immediateReactModelUpdate: true,
        callback: function(command, value) {
          insertElement(this, value);
        },
      });
    };
    froalaInit();
  }, [insertElement, tagStatus, addNewMergeTagPress, mergeTags]);

  return {
    froalaConfig,
    tagStatus,
  };
};

export default useFroala;
