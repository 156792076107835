import React from 'react';
import { useMonth } from '@datepicker-react/hooks';

import Day from './Day';
import './Calendar.scss';

import {environment} from '../../config';

const Month = ({ year, month, firstDayOfWeek }) => {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek
  });

  function checkDisabled(day) { 
    if(new Date(day.date).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) {
      return false;
    }
    else {
      return true;
    }
  }

  return (
    <div className="month-wrap">
      <div className="month-label">
        <label>{monthLabel}</label>
      </div>
      <div className="weekdays">
        {weekdayLabels.map(dayLabel => (
          <div className="text-center day-label" key={dayLabel}>
            {dayLabel}
          </div>
        ))}
      </div>
      <div className="days">

        {days.map((day, i) => (
          <Day 
            date={day.date} 
            key={i} 
            day={day.dayLabel} 
            disabled={checkDisabled(day)}  
          />
        ))}
      </div>
    </div>
  );
}

export default Month;
