import React from 'react';
import Carousel from '@brainhubeu/react-carousel';

const Columns = ({data, setColumns}) => {
  if (data.allColumns) {
    const result = data.allColumns;
    const items = () => {
      let arr = [];
      for (let key in result) {
        arr.push(
          <div
            className={`column-match-wrap ${data.activeColumn &&
              data.activeColumn.id === key &&
              'active'}`}
            key={key}
            onClick={() => {
              setColumns({
                ...data,
                activeColumn: {
                  id: key,
                  selectedMap: Object.keys(data.fieldMaps).find(
                    key => data.fieldMaps[key] === key,
                  ),
                },
                allColumns: {
                  ...data.allColumns,
                  [key]: {
                    ...data.allColumns[key],
                  },
                },
              });
            }}>
            <div className={`th`}>
              {key} <i className="fa fa-edit"></i>
            </div>
            <div className="tr">
              {result[key].values.map((e, i) => (
                <div key={i}>{e}</div>
              ))}
            </div>
          </div>,
        );
      }
      return arr;
    };
    return (
      <Carousel slidesPerScroll={2} slidesPerPage={6} slides={items()} arrows />
    );
  }

  return null;
};

export default Columns;
