import React from 'react';
import NextIcon from 'components/StepNav/NextIcon';

const Button = ({onClick, disabled, children}) => {
  return (
    <div className="col-sm-12">
      <button
        disabled={disabled}
        onClick={() => onClick()}
        className="btn btn-lg btn-block btn-primary btn-action">
        <span className="action">{children}</span>
        <NextIcon disabled={disabled} />
      </button>
    </div>
  );
};

export default Button;
