import React, {useEffect} from 'react';
import {CTX} from 'store';
import {useParams} from 'react-router-dom';

import APIService from 'services/backstrap/apiService';
import Header from 'components/Header/Header';

import PdfViewer from 'components/PdfViewer/PdfViewer';

const LetterDetails = props => {
  document.title = `Letter Details`;

  const [appState, dispatch] = React.useContext(CTX);

  const [viewState, setView] = React.useState({letterDetails: null});

  useEffect(() => {
    const loadLetter = async () => {
      try {
        dispatch({type: 'LOADING_ENABLED'});
        await APIService.getDocument(props.lid).then(res => {
          setView(lastState => {
            return {letterDetails: res};
          });
          dispatch({type: 'LOADING_DISABLED'});
        });
      } catch (err) {
        console.log(err);
      }
    };
    loadLetter();
  }, []);

  return (
    <>
      <Header props={props} />
      <div className="container">
        <h1 className="mt-5 mb-5">Letter Details</h1>

        <PdfViewer
          url={
            viewState.letterDetails
              ? viewState.letterDetails.merged_doc_uri
              : null
          }></PdfViewer>
      </div>
    </>
  );
};

export default LetterDetails;
