import React from 'react';
import UploadIcon from 'assets/img/ico_upload.svg';
import UploadPendingIcon from 'assets/img/ico_uploadpending.svg';
import SuccessIcon from 'assets/img/ico_uploadcomplete.svg';
import ErrorIcon from 'assets/img/ico_uploaderror.svg';

const UploadForm = ({
  uploadState,
  isDragActive,
  getInputProps,
  getRootProps,
  handleFormatChange,
  format,
}) => {
  return (
    <div className="row mx-0 justify-content-center align-items-center mt-5">
      <section>
        <h1 className="mt-5 mb-5">Where are we sending?</h1>
        <select onChange={handleFormatChange} value={format}>
          <option>csv</option>
          <option>excel</option>
          <option value="txt">fixed-width</option>
          <option>xml</option>
          <option>csv-schema</option>
          <option>json</option>
        </select>
        <div className="upload-button">
          <input
            {...getInputProps()}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .txt, .xml, .json"
          />
        </div>
      </section>
      <div className="col-sm-6 col-md-6 upload-button-wrap" {...getRootProps()}>
        <div className="card-body">
          {uploadState === 'ready' && (
            <>
              <img src={UploadIcon} alt="Upload CSV of Addresses" />
              <h1>Upload</h1>
              {isDragActive ? (
                <p>Drop CSV here</p>
              ) : (
                <p>
                  Drag recipient list here or <u>browse.</u>
                </p>
              )}
            </>
          )}

          {uploadState === 'uploading' && (
            <>
              <img src={UploadPendingIcon} alt="Uploading" />
              <h1>Uploading</h1>
            </>
          )}

          {uploadState === 'success' && (
            <>
              <img src={SuccessIcon} alt="Success uploading" />
              <h1>Success</h1>
            </>
          )}

          {uploadState === 'error' && (
            <>
              <img src={ErrorIcon} alt="Error uploading" />
              <h1>Sorry</h1>
              <p>Please use a valid .csv file. Thanks!</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadForm;
