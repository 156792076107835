import {useState, useEffect, useContext} from 'react';
import APIService from 'services/backstrap/apiService';
import {Context as SendContext} from 'contexts/send';

const useWhereStep = (send, setError) => {
  const {datasourceId, setDatasourceId} = useContext(SendContext);
  const [viewState, setViewState] = useState('upload');
  const [uploadState, setUploadState] = useState('ready');
  const [selectValue, setSelectValue] = useState(null);
  const [columns, setColumns] = useState({
    activeColumn: null,
    allColumns: [],
    valid: true,
    fieldMaps: {
      name: null,
      full_address: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      suffix: null,
      name2: null,
      contact_identifier: null,
      address_line_1: null,
      address_line_2: null,
      local_municipality: null,
      city_town: null,
      state_province_district: null,
      postal_code: null,
      country: null,
    },
    fieldMapSelected: null,
    nameFieldMapType: null,
    addressFieldMapType: null,
    selectedInputValue: null,
  });

  const attachDatasourceToCampaign = async () => {
    const attachDatasource = async () => {
      try {
        await APIService.attachDatasource(send.id, datasourceId);
        setUploadState('success');
      } catch (err) {
        if (err.data && err.data.message) {
          setUploadState('error');
        } else {
          setError('Failed to upload file');
        }
      }
    };

    try {
      const csvResponse = await APIService.getDatasource(datasourceId);
      if (csvResponse && csvResponse.finished_loading) {
        await attachDatasource();
      }
      else {
        setTimeout(attachDatasourceToCampaign, 3000);
      }
    } catch (err) {
      console.log('error getting datasource:', err);
    }
  };

  const getFieldMapOptions = () => {
    return [
      {
        label: 'Full Name',
        value: 'name',
        isDisabled:
          (columns.fieldMaps.name && columns.fieldMaps.name.id) ||
          columns.nameFieldMapType === 'partial'
      },
      {
        label: 'Full Address',
        value: 'full_address',
        isDisabled:
          (columns.fieldMaps.full_address && columns.fieldMaps.full_address.id) ||
          columns.addressFieldMapType === 'partial'
      },
      {
        label: 'First Name',
        value: 'first_name',
        isDisabled:
          (columns.fieldMaps.first_name && columns.fieldMaps.first_name.id) ||
          columns.nameFieldMapType === 'full'
      },
      {
        label: 'Middle Name',
        value: 'middle_name',
        isDisabled:
          (columns.fieldMaps.middle_name && columns.fieldMaps.middle_name.id) ||
          columns.nameFieldMapType === 'full'
      },
      {
        label: 'Last Name',
        value: 'last_name',
        isDisabled:
          (columns.fieldMaps.last_name && columns.fieldMaps.last_name.id) ||
          columns.nameFieldMapType === 'full'
      },
      {
        label: 'Suffix',
        value: 'suffix',
        isDisabled:
          (columns.fieldMaps.suffix && columns.fieldMaps.suffix.id) ||
          columns.nameFieldMapType === 'full'
      },
      {
        label: 'Name2',
        value: 'name2',
        isDisabled:
          columns.fieldMaps.name2 && columns.fieldMaps.name2.id,
      },
      {
        label: 'Contact Identifier',
        value: 'contact_identifier',
        isDisabled:
          columns.fieldMaps.contact_identifier &&
          columns.fieldMaps.contact_identifier.id
      },
      {
        label: 'Address 1',
        value: 'address_line_1',
        isDisabled:
          (columns.fieldMaps.address_line_1 &&
            columns.fieldMaps.address_line_1.id) ||
          columns.addressFieldMapType === 'full'
      },
      {
        label: 'Address 2',
        value: 'address_line_2',
        isDisabled:
          (columns.fieldMaps.address_line_2 &&
            columns.fieldMaps.address_line_2.id) ||
          columns.addressFieldMapType === 'full'
      },
      {
        label: 'Municipality',
        value: 'local_municipality',
        isDisabled:
          (columns.fieldMaps.local_municipality &&
            columns.fieldMaps.local_municipality.id) ||
          columns.addressFieldMapType === 'full'
      },
      {
        label: 'City',
        value: 'city_town',
        isDisabled:
          (columns.fieldMaps.city_town && columns.fieldMaps.city_town.id) ||
          columns.addressFieldMapType === 'full'
      },
      {
        label: 'State',
        value: 'state_province_district',
        isDisabled:
          (columns.fieldMaps.state_province_district &&
            columns.fieldMaps.state_province_district.id) ||
          columns.addressFieldMapType === 'full'
      },
      {
        label: 'Postal Code',
        value: 'postal_code',
        isDisabled:
          (columns.fieldMaps.postal_code && columns.fieldMaps.postal_code.id) ||
          columns.addressFieldMapType === 'full'
      },
      {
        label: 'Country',
        value: 'country',
        isDisabled:
          (columns.fieldMaps.country && columns.fieldMaps.country.id) ||
          columns.addressFieldMapType === 'full'
      },
    ];
  };

  var fieldMapOptions = getFieldMapOptions();

  const onFieldMapSelect = (e, id) => {
    if (e !== null) {
      let nameType = columns.nameFieldMapType;
      let addressType = columns.addressFieldMapType;
      if (e.value === 'name') {
        nameType = 'full';
      } else if (
        ['first_name', 'middle_name', 'last_name', 'suffix'].includes(e.value)
      ) {
        nameType = 'partial';
      } else if (e.value === 'full_address') {
        addressType = 'full';
      } else if (
        [
          'address_line_1',
          'address_line_2',
          'local_municipality',
          'city_town',
          'state_province_district',
          'postal_code',
          'country',
        ].includes(e.value)
      ) {
        addressType = 'partial';
      }

      let newColumns = {
        ...columns,
        fieldMaps: {
          ...columns.fieldMaps,
          [e.value]: {id, value: e},
        },
        activeColumn: {
          ...columns.activeColumn,
          selectedMap: e,
        },
        allColumns: {
          ...columns.allColumns,
          [id]: {
            ...columns.allColumns[id],
            selectedMap: e.value,
          },
        },
      };
      newColumns.nameFieldMapType = nameType;
      newColumns.addressFieldMapType = addressType;
      setColumns(newColumns);
      fieldMapOptions = getFieldMapOptions();
    } else {
      // DELETE A FIELD FROM THE FIELDMAP
      if (columns.activeColumn.id === id && e === null) {
        const field = Object.keys(columns.fieldMaps).find(
          key =>
            columns.fieldMaps[key] &&
            columns.fieldMaps[key].id === columns.activeColumn.id,
        );

        let nameType = null;
        let addressType = null;
        let fieldMap = JSON.parse(JSON.stringify(columns.fieldMaps));
        fieldMap[field] = null;
        let mappedFields = Object.keys(fieldMap)
          .filter(fmKey => fieldMap[fmKey] && fieldMap[fmKey].id);
          //.filter(fmItem => fmItem != null);
        if (mappedFields.includes('name')) {
          nameType = 'full';
        } else if (
          mappedFields.some(
            mf =>
              ['first_name', 'middle_name', 'last_name', 'suffix'].indexOf(
                mf,
              ) >= 0,
          )
        ) {
          nameType = 'partial';
        }
        if (mappedFields.includes('full_address')) {
          addressType = 'full';
        } else if (
          mappedFields.some(
            mf =>
              ['address_line_1', 'address_line_2', 'city_town', 'state_province_district', 'postal_code'].indexOf(
                mf,
              ) >= 0,
          )
        ) {
          addressType = 'partial';
        }

        let newFMCol = columns.fieldMaps[field];
        newFMCol.id = null;

        setColumns({
          ...columns,
          fieldMaps: {
            ...columns.fieldMaps,
            [field]: newFMCol,
          },
          nameFieldMapType: nameType,
          addressFieldMapType: addressType,
        });
        fieldMapOptions = getFieldMapOptions();
      }
    }
    handleSelectValue();
  };

  const activeSelectValue = () => {
      if(columns.activeColumn && columns.activeColumn.id) {
        const key = Object.keys(columns.fieldMaps).find(key => columns.fieldMaps[key] && columns.fieldMaps[key].id === columns.activeColumn.id);
        if(columns.fieldMaps[key]) {
          return columns.fieldMaps[key].value
        } else {
          return null
        }
      }
      return null
  }

  const handleSelectValue = () => {
    setSelectValue(activeSelectValue())
  };

  const uploadFieldMaps = async () => {
    setViewState('loading');
    const fm = columns.fieldMaps;
    const data = {
      name: fm.name ? fm.name.id : null,
      full_address: fm.full_address ? fm.full_address.id : null,
      first_name: fm.first_name ? fm.first_name.id : null,
      middle_name: fm.middle_name ? fm.middle_name.id : null,
      last_name: fm.last_name ? fm.last_name.id : null,
      suffix: fm.suffix ? fm.suffix.id : null,
      name2: fm.name2 ? fm.name2.id : null,
      contact_identifier: fm.contact_identifier
        ? fm.contact_identifier.id
        : null,
      address_line_1: fm.address_line_1 ? fm.address_line_1.id : null,
      address_line_2: fm.address_line_2 ? fm.address_line_2.id : null,
      local_municipality: fm.local_municipality
        ? fm.local_municipality.id
        : null,
      city_town: fm.city_town ? fm.city_town.id : null,
      state_province_district: fm.state_province_district
        ? fm.state_province_district.id
        : null,
      postal_code: fm.postal_code ? fm.postal_code.id : null,
      country: fm.country ? fm.country.id : null,
    };

    try {
      setViewState('loading');
      const fieldmap = await APIService.postDatasourceMap(data);
      const applyData = {
        id: datasourceId,
        field_map_id: fieldmap[0].id,
      };
      await APIService.applyDatasourceMap(applyData)
      .then(async () => {
        await attachDatasourceToCampaign();
      })
    } catch (err) {
      setError('A problem occured uploading the datasource');
    }
  };

  useEffect(() => {
    const mapColumnsToState = (res, fieldMap) => {
      const previews = res.preview;
      if(previews) {
        let result = {};
        for (let i = 0; i < previews.length; i++) {
          const row = previews[i];
          for (let key in row) {
            if (!(key in result))
              result[key] = {
                //active: false, //active column
                selectedMap: null, //datasource field map name
                values: [], //preview values
              };
            result[key].values.push(row[key]);
          }
        }
        // WE HAVE A FIELDMAP
        if (fieldMap) {
          // PREFIX & EXTERNAL REFERENCE SHOULD BE PART OF FIELD MAPS, BUT WE MISSED THEM.
          // SINCE THE UI DOESN'T HANLDE PREFIX OR EXTERNAL REFERENCE YET, WE'LL JUST REMOVE THEM
          delete fieldMap.prefix;
          delete fieldMap.external_reference;

          let nameFieldMapType = null;
          let addressFieldMapType = null;
          if (fieldMap.name) {
            nameFieldMapType = 'full';
          } else if (
            fieldMap.first_name ||
            fieldMap.last_name ||
            fieldMap.middle_name ||
            fieldMap.suffix
          ) {
            nameFieldMapType = 'partial';
          }

          if (fieldMap.full_address) {
            addressFieldMapType = 'full';
          } else if (
            fieldMap.address_line_1 ||
            fieldMap.address_line_2 ||
            fieldMap.city_town ||
            fieldMap.state_province_district ||
            fieldMap.postal_code
          ) {
            addressFieldMapType = 'partial';
          }

          let sfm = Object.keys(fieldMap)
            .map(fieldMapKey => {
              return {
                id: fieldMap[fieldMapKey],
                value: fieldMapOptions.find(
                  optObj => fieldMapKey === optObj.value,
                ),
              };
            });
          let formattedSfm = {};
          if(sfm.length === 1) {
            formattedSfm[sfm[0].value.value] = sfm[0];
          }
          else if(sfm.length > 1) {
            formattedSfm = sfm.reduce((prev, cur, idx) => {
              if (idx === 1) {
                let outObj = {};
                outObj[prev.value.value] = prev;
                outObj[cur.value.value] = cur;
                return outObj;
              } else {
                prev[cur.value.value] = cur;
                return prev;
              }
            });
          }
            
          setColumns({
            ...columns,
            allColumns: result,
            fieldMaps: formattedSfm,
            addressFieldMapType: addressFieldMapType,
            nameFieldMapType: nameFieldMapType,
          });
        } else {
          setColumns({...columns, allColumns: result});
        }
      }
    };

    const getSuggestedFieldMaps = async (nextStepName = '') => {
      const csvRes = await APIService.getDatasource(send.draft_data_source_id || send.datasourceId);
      try {
        const sfmRes = await APIService.getSuggestedFieldMap(send.draft_data_source_id || send.data_source_id);
        delete sfmRes.id;
        delete sfmRes.created_at;
        delete sfmRes.created_by;
        delete sfmRes.modified_at;
        delete sfmRes.modified_by;
        delete sfmRes.deleted_at;
        delete sfmRes.deleted_by;
        delete sfmRes.group_id;
        mapColumnsToState(csvRes, sfmRes);
      } catch (err) {
        mapColumnsToState(csvRes);
      }
    };

    if (send.draft_data_source_id || send.data_source_id) {
      setDatasourceId(send.draft_data_source_id || send.data_source_id);
      getSuggestedFieldMaps();
      handleSelectValue();
    }
  }, [send.draft_data_source_id, send.data_source_id, selectValue]);

  return {
    columns,
    setColumns,
    viewState,
    onFieldMapSelect,
    selectValue,
    fieldMapOptions,
    datasourceId,
    uploadFieldMaps,
    activeSelectValue,
  };
};

export default useWhereStep;
