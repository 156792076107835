import {useState, useCallback, useEffect, useContext} from 'react';
import APIService from 'services/backstrap/apiService';
import {useDropzone} from 'react-dropzone';
import {Context as SendContext} from 'contexts/send';

const useWhereStep = (send, setError) => {
  const {datasourceId, setDatasourceId} = useContext(SendContext);
  const [fileState, setFileState] = useState({
    csvFile: null,
    uploadFile: null,
  });
  const [uploadState, setUploadState] = useState('ready');
  const [format, setFormat] = useState('csv');
  const [disabled, setDisabled] = useState(true);
  const [activeFile, setActiveFile] = useState(false);
  const [showUpload, setShowUpload] = useState(true);

  const getSchemaId = fileFormat => {
    switch (fileFormat) {
      case 'csv-schema':
        return 2;
      case 'txt':
        return 1;
      default:
        return null;
    }
  };

  const onDrop = useCallback(files => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = event => {
      let fileExt = file.type.split('/').slice(-1)[0];
      let fileName = file.name;
      let nameParts = file.name.split('.');
      if (
        fileExt === 'octet-stream' ||
        fileExt == null ||
        fileExt.trim() === ''
      ) {
        if (nameParts.length > 1) {
          fileExt = nameParts[nameParts.lexgth - 1];
        } else {
          fileExt = 'csv';
        }
      }

      setFileState(prevState => {
        return {
          ...prevState,
          uploadFile: file,
          base64String: event.target.result,
          fileExt: fileExt,
          fileName: fileName,
        };
      });
      setDisabled(false);
    };
    reader.readAsDataURL(file);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const getCsv = async evt => {
    evt.stopPropagation();
    try {
      const campaignData = await APIService.getCampaignDetails(send.id);
      const csv = await APIService.getCsv(campaignData.id);
      window.open(csv.url);
    } catch (err) {
      setError(err);
    }
  };

  const handleFormatChange = evt => {
    evt.stopPropagation();
    setFormat(evt.target.value);
  };

  const uploadFile = async () => {
    if (fileState.base64String) {
      setUploadState('uploading');
      const curCsv = fileState.csvFile;
      setFileState(prevState => {
        return {
          ...prevState,
          csvFile: null,
        };
      });
      try {
        const datasource = await APIService.postCampaignCsv({
          use_field_map: true,
          campaign_id: send.id,
          file: {
            body: fileState.base64String,
            ext: fileState.fileExt,
            filename: fileState.fileName,
          },
          format: format,
          schema_id: getSchemaId(format),
        });
        await APIService.patchCampaign({
          id: send.id,
          draft_data_source_id: datasource.id,
        });
        setDatasourceId(datasource.id);
        setUploadState('success');
      } catch (err) {
        if (err.data && err.data.message) {
          setUploadState('error');
        } else {
          setError('Failed to upload file');
        }
        if (curCsv) {
          setFileState(prevState => {
            return {
              ...prevState,
              csvFile: curCsv,
            };
          });
        }
      }
    }
  };

  const handleShowUpload = () => {
    setDisabled(true)
    setShowUpload(true)
  }


  useEffect(() => {
    const loadDataSource = async () => {
      if (send.data_source_id) {
        try {
          const currentCsv = await APIService.getDatasource(
            send.data_source_id,
          );
          setActiveFile(currentCsv);
          console.log(currentCsv)
          setShowUpload(false);
          setDisabled(false)
        } catch (error) {
          console.log(error);
        }
      }
    };
    loadDataSource();
  }, [send.data_source_id]);

  return {
    isDragActive,
    uploadState,
    getRootProps,
    getInputProps,
    getCsv,
    handleFormatChange,
    format,
    fileState,
    disabled,
    uploadFile,
    activeFile,
    showUpload,
    handleShowUpload,
  };
};

export default useWhereStep;
