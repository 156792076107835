import React, {useState} from 'react';
import BaseModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './styles.scss';

const Modal = ({showModal, closeModal, nextStep}) => {
  if (showModal) {
    return (
      <div className="big-modal-container" onClick={() => closeModal()}>
        <div
          className="big-modal"
          onClick={event => {
            event.stopPropagation();
          }}>
          <h1 className="mb-5">Are you sure?</h1>
          <button
            className="btn btn-primary mr-5"
            onClick={() => {
              nextStep();
            }}>
            Confirm
          </button>
          <button className="btn btn-secondary" onClick={() => closeModal()}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
  return null;
};

export default Modal;
