import React, {useContext} from 'react';
import SelectedTemplate from './SelectedTemplate';
import {Context as SendContext} from 'contexts/send';
import ReactPaginate from 'react-paginate';
import './styles.scss';

const TemplateList = ({
  id,
  activeSendObject,
  selectWhatTemplateType,
  setErr,
  loading,
  templates,
  previewTemplate,
  activeTemplate,
  selectTemplate,
  loadMoreTemplates,
  handlePageChange,
  pageCount,
  showCurrentTemplate,
}) => {
  const {templateType} = useContext(SendContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (templates && templates.length) {
    return (
      <div className="TemplateList-container">
        <div className="TemplateList-column">
          <h1 className="mb-0" data-testid="select-a-template">
            Select a template
          </h1>
          <p className="mb-4 d-flex align-items-center text-sm">
            <button
              className="btn btn-link p-0 mr-2 text-sm"
              onClick={() => selectWhatTemplateType('createTemplate')}>
              Create a template
            </button>
            or
            <button
              className="btn btn-link p-0 mx-2 text-sm"
              onClick={() => selectWhatTemplateType('uploadTemplate')}
              disabled={templateType === 'uploadTemplate'}>
              upload a template
            </button>{' '}
            instead
          </p>
          <section className="SelectTemplate-container">
            <ul className="list-group template-select-list">
              {templates.map(template => (
                <li
                  key={`${template.name}:${template.id}`}
                  className="list-group-item align-items-center d-flex justify-content-between py-4 pointer"
                  onClick={() => previewTemplate(template)}>
                  {template.name}
                  <div className="SelectTemplate-badges">
                    {template.ext === 'html' ? (
                      <span className="badge badge-dark">html</span>
                    ) : null}
                    {template.ext === 'docx' ? (
                      <span className="badge badge-light">docx</span>
                    ) : null}
                    <span className="badge badge-primary">tags</span>
                  </div>
                </li>
              ))}
            </ul>
            <ReactPaginate
              containerClassName={'pagination'}
              pageCount={pageCount}
              onPageChange={data => handlePageChange(data)}
            />
          </section>
        </div>
        <div className="TemplateList-column">
          <SelectedTemplate
            showCurrentTemplate={showCurrentTemplate}
            activeTemplate={activeTemplate}
            selectTemplate={selectTemplate}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default TemplateList;
