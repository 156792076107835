import {useContext, useEffect} from 'react';
import {CTX} from 'store';
import APIService from 'services/backstrap/apiService';
import {handleException} from 'services/exceptions';

const useAuth = () => {
  const [appState, dispatch] = useContext(CTX);

  const loading = appState.isLoading;
  const isAuthenticated = appState.isAuthenticated;

  useEffect(() => {
    if (appState.signingIn !== true) {
      const authenticateUser = async () => {
        await APIService.getMailClasses(null)
          .then(res => {
            dispatch({type: 'USER_IS_AUTHENTICATED'});
          })
          .catch(err => {
            if (err.status === 401) {
              localStorage.removeItem('cs_token');
            } else {
              handleException(err);
            }
            dispatch({type: 'USER_IS_NOT_AUTHENTICATED'});
          });
      };

      authenticateUser();
      if (loading) {
        dispatch({type: 'LOADING_DISABLED'});
      }
    }
  }, [dispatch, appState.isLoading]);

  return {
    isAuthenticated,
    loading,
  };
};

export default useAuth;
