import {useState, useEffect, useContext} from 'react';
import APIService from 'services/backstrap/apiService';
import {useHistory} from 'react-router-dom';
const queryString = require('query-string');

const getFilter = filter => {
  switch (filter) {
    case 'STARTED':
      return 'started';
    case 'SENT':
      return 'sent';
    case 'MAILED':
      return 'mailed';
    case 'REVIEWED':
      return 'reviewed';
    default:
      return null;
  }
};

const useCampaigns = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [campaignsTotal, setCampaignsTotal] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [pageOffset, setPageOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [popover, popoverState] = useState(false);
  const togglePopover = () => popoverState(!popover);
  const pageSize = 10;

  const campaignDetail = async cmp => {
    if (cmp.id) {
      try {
        await APIService.getCampaignDetails(cmp.id).then(res => {
          if (res.status_name === 'started') {
            history.push(`/send/${cmp.id}/confirm`);
          } else if (res.status_name === 'reviewed') {
            history.push('/reviewed/letterlist/' + cmp.id);
          } else {
            history.push('/sent/letterlist/' + cmp.id);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const filterChanged = statusSelected => {
    setPageOffset(0);
    setCurrentPage(0);
    togglePopover();
    setStatusFilter(statusSelected);
  };

  useEffect(() => {
    if (history.location.search) {
      var params = queryString.parse(history.location.search);
      if (params.status) {
        setStatusFilter(params.status);
      }
    }
  }, [history.location.search]);

  useEffect(() => {
    const getCampaigns = async () => {
      try {
        const filteredCampaigns = await APIService.getCampaignsFiltered({
          limit: pageSize,
          offset: pageOffset,
          status: getFilter(statusFilter),
        });
        setCampaigns(filteredCampaigns.campaigns);
        setCampaignsTotal(filteredCampaigns.total_count);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };

    getCampaigns();
  }, [pageOffset, statusFilter]);

  return {
    loading,
    campaigns,
    statusFilter,
    togglePopover,
    popover,
    filterChanged,
    campaignDetail,
    campaignsTotal,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageOffset,
  };
};

export default useCampaigns;
