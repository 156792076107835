import {useState, useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import APIService from 'services/backstrap/apiService';
import {Context as SendContext} from 'contexts/send';

const useHowStep = () => {
  const {sendId} = useParams();
  const {send} = useContext(SendContext);
  const [mailClassId, setMailClassId] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);

  const handleSelect = async newMailClassId => {
    setMailClassId(newMailClassId);
    try {
      await APIService.patchCampaign({
        id: sendId,
        mail_class_id: newMailClassId,
      });
    } catch (err) {
      const errorMessage =
        err.data && err.data.message
          ? err.data.message
          : 'Failed to set mail class';
      setError(errorMessage);
    }
    setDisabled(false);
  };

  useEffect(() => {
    console.log(send)
    if (send.mail_class_id) {
      setMailClassId(send.mail_class_id);
      setDisabled(false);
    }
  }, [send.mail_class_id]);

  return {
    handleSelect,
    error,
    disabled,
    mailClassId,
  };
};

export default useHowStep;
