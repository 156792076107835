import * as Sentry from '@sentry/react';

const captureExceptions = process.env.NODE_ENV === 'production';

const handleException = (error, sendToSentry = true) => {
  console.warn(error);

  if (captureExceptions && sendToSentry) {
    Sentry.captureException(error);
  }

  return (error || {}).message ? error.message : error;
};

export {handleException};
