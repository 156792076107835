import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';

import MainScreen from 'containers/MainScreen';
import SignIn from 'containers/SignIn';
import NewSend from 'containers/NewSend';
import HowStep from 'containers/HowStep';
import HowStepOptions from 'containers/HowStepOptions';
import NameStep from 'containers/NameStep';
import WhoStep from 'containers/WhoStep';
import WhoStepOptions from 'containers/WhoStepOptions';
import WhereStep from 'containers/WhereStep';
import WhereStepOptions from 'containers/WhereStepOptions';
import WhatStep from 'containers/WhatStep';
import WhatStepTemplates from 'containers/WhatStepTemplates';
import WhatStepEditor from 'containers/WhatStepEditor';
import WhenStep from 'containers/WhenStep';
import SendConfirm from 'containers/SendConfirm';
import NotFound from 'containers/NotFound';
import Drafts from 'containers/Drafts';
import Sent from 'containers/Sent';
import Scheduled from 'containers/Scheduled';
import LetterList from 'containers/LetterList';
import LetterDetails from 'containers/LetterDetails';
import Campaigns from 'containers/Campaigns';
import SentToCSMailhouse from 'containers/SentToCSMailhouse';
import useAuth from 'hooks/auth';

const privateRoutes = [
  {
    path: '/',
    component: MainScreen,
    exact: true,
  },
  {
    path: '/campaigns',
    component: Campaigns,
    exact: false,
  },
  {
    path: '/send/new',
    component: NewSend,
    exact: false,
  },
  {
    path: '/send/:sendId/name',
    component: NameStep,
    exact: false,
  },
  {
    path: '/send/:sendId/how/options',
    component: HowStepOptions,
    exact: false,
  },
  {
    path: '/send/:sendId/how',
    component: HowStep,
    exact: false,
  },
  {
    path: '/send/:sendId/who/options',
    component: WhoStepOptions,
    exact: false,
  },
  {
    path: '/send/:sendId/who',
    component: WhoStep,
    exact: false,
  },
  {
    path: '/send/:sendId/where/options',
    component: WhereStepOptions,
    exact: false,
  },
  {
    path: '/send/:sendId/where',
    component: WhereStep,
    exact: false,
  },
  {
    path: '/send/:sendId/what/templates/editor',
    component: WhatStepEditor,
    exact: false,
  },
  {
    path: '/send/:sendId/what/templates/:templateId/editor',
    component: WhatStepEditor,
    exact: false,
  },
  {
    path: '/send/:sendId/what/templates',
    component: WhatStepTemplates,
    exact: false,
  },
  {
    path: '/send/:sendId/what',
    component: WhatStep,
    exact: false,
  },
  {
    path: '/send/:sendId/what/:templateId',
    component: WhatStep,
    exact: false,
  },
  {
    path: '/send/:sendId/when',
    component: WhenStep,
    exact: false,
  },
  {
    path: '/send/:sendId/confirm',
    component: SendConfirm,
    exact: false,
  },
  {
    path: '/drafts',
    component: Drafts,
    exact: true,
  },
  {
    path: '/sent',
    component: Sent,
    exact: true,
  },
  {
    path: '/scheduled',
    component: Scheduled,
    exact: true,
  },
  {
    path: '/sent/letterlist/:cid',
    component: LetterList,
    exact: true,
  },
  {
    path: '/sent/letterlist/:cid/:letterId',
    component: LetterList,
    exact: true,
  },
  {
    path: '/reviewed/letterlist/:cid/:letterId',
    component: LetterList,
    exact: true,
  },
  {
    path: '/sent/letterdetails/:lid',
    component: LetterDetails,
    exact: true,
  },
  {
    path: '/reviewed/letterlist/:cid',
    component: LetterList,
    exact: true,
  },
  {
    path: '/report-sent-to-cs-mailhouse',
    component: SentToCSMailhouse,
    exact: true,
  },
];

const publicRoutes = [
  {
    path: '/loading',
  },
  {
    path: '/signin',
    component: SignIn,
    exact: false,
  },
];

const Routes = () => {
  const {loading, isAuthenticated} = useAuth();

  return (
    <Router>
      <div className="app-container d-flex">
        {loading ? (
          <div className="loading-container">
            <BarLoader
              sizeUnit={'px'}
              size={150}
              color={'#4099FE'}
              loading={loading}
            />
          </div>
        ) : null}
        <main>
          <Switch>
            {publicRoutes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
            {privateRoutes.map((route, i) => (
              <PrivateRoute
                key={i}
                loading={loading}
                isAuthenticated={isAuthenticated}
                path={route.path}
                component={route.component}
                exact={route.exact}
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    </Router>
  );
};

export default Routes;
