import React from 'react';
import UploadIcon from 'assets/img/ico_upload.svg';
import UploadPendingIcon from 'assets/img/ico_uploadpending.svg';
import SuccessIcon from 'assets/img/ico_uploadcomplete.svg';
import ErrorIcon from 'assets/img/ico_uploaderror.svg';
import './styles.scss'
import useUploadHook from './hooks';

const UploadTemplate = ({selectWhatTemplateType}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    whatType,
    uploadState,
    docFile,
    downloadUploadedTemplate,
  } = useUploadHook();

  const acceptedFileTypes = whatType === 'merge' ? '.doc, .docx, .html' : '.pdf'

  return (
    <div className="UploadTemplate-container">
      <div className="UploadTemplate-column">
        <h1 className="mb-0">hello {whatType}</h1>
        <p className="mb-4 d-flex align-items-center text-sm">
          <button
            className="btn btn-link p-0 mr-2 text-sm"
            onClick={() => selectWhatTemplateType('createTemplate')}>
            Create a template
          </button>
          or
          <button
            className="btn btn-link p-0 mx-2 text-sm"
            onClick={() => selectWhatTemplateType('selectTemplate')}>
            select a template
          </button>{' '}
          instead
        </p>
        <p className="text-muted">
          Upload a preformatted .docx or .html template. You will have a chance
          to make changes after upload.
        </p>
      </div>

      <div className="col-sm-6 col-md-6 upload-button-wrap" {...getRootProps()}>
        <div className="upload-button text-center">
          <input
            {...getInputProps()}
            accept={acceptedFileTypes}
          />
          <div className="card-body">
            {uploadState === 'ready' && (
              <>
                <img src={UploadIcon} alt="Upload CSV of Addresses" />
                <h1>Upload</h1>
                {isDragActive ? (
                  <p>Drop {whatType === 'merge' ? 'DOC' : 'PDF'} file here</p>
                ) : (
                  <p>
                    Drag document here or <u>browse.</u>
                  </p>
                )}
              </>
            )}

            {uploadState === 'uploading' && (
              <>
                <img src={UploadPendingIcon} alt="Uploading" />
                <h1>Uploading</h1>
              </>
            )}

            {uploadState === 'success' && (
              <>
                <img src={SuccessIcon} alt="Success uploading" />
                <h1>Success</h1>
              </>
            )}

            {uploadState === 'error' && (
              <>
                <img src={ErrorIcon} alt="Error uploading" />
                <h1>Sorry</h1>
                <p>
                  Please use a valid {whatType === 'merge' ? 'DOC' : 'PDF'}{' '}
                  file. Thanks!
                </p>
              </>
            )}
          </div>

          {docFile && docFile.fileName && (
            <p className="file-info">
              <button
                className="btn btn-link"
                onClick={downloadUploadedTemplate}>
                {docFile.fileName}
              </button>
            </p>
          )}

          {docFile && !docFile.fileName && (
            <p className="file-info">
              <button
                className="btn btn-link"
                onClick={downloadUploadedTemplate}>
                {docFile}
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadTemplate;
