import React from 'react';

const RightCarat = ({disabled}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.125"
    height="12.249"
    viewBox="0 0 7.125 12.249"
    style={{margin: '0 0 0 auto'}}>
    <path
      id="next"
      stroke={disabled ? '#a7a7a7' : '#FFFFFF'}
      d="M596.266,10287.489l5.125,5.124-5.125,5.126"
      fill="none"
      transform="translate(-595.265 -10286.488)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default RightCarat;
