import React from 'react';

const NextIcon = ({disabled}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.125"
    height="12.249"
    viewBox="0 0 7.125 12.249">
    <path
      id="next"
      d="M596.266,10287.489l5.125,5.124-5.125,5.126"
      fill="none"
      transform="translate(-595.265 -10286.488)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      stroke={disabled ? '0.32' : '1'}
    />
  </svg>
);

export default NextIcon;
