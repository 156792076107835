 var baseStylesEditorPDF = `
  <style type="text/css" id="lf-styles">



  
      @page {
          margin-top: 50px;
          margin-bottom: 50px;
          margin-left: 100px;
          margin-right: 60px;
      }

      .LF-PG-BREAK{
          display:block;
          height:20px; 
          background:#efefef; 
          width:100%; 
          text-align:center;
          cursor:pointer;
      }

      .LF-DATA-FIELD {
          display:inline-block; 
          border-radius:15px; 
          background:rgba(144, 68, 199); 
          color:white; 
          font-weight:normal;
          cursor:pointer;
      }
      
      .LF-DATA-FIELD-DATA {
          display:none; 
      }
      .LF-DATA-FIELD-TEXT {
          display:inline; 
          padding-left:10px; 
          padding-right:10px; 
      }

      @media print { 
          .LF-PG-BREAK{
              height:1px !important;
              background:transparent !important;
          }
          .LF-PG-BREAK-TEXT{
              display:none;
          }
          
          .LF-DATA-FIELD {
              display:inline; 
              padding-left:0; 
              padding-right:0; 
              border-radius:0; 
              background:inherit; 
              color:inherit; 
              font-weight:inherit;
          }
          .LF-DATA-FIELD-DATA {
              display:inline; 
          }
          .LF-DATA-FIELD-TEXT {
              display:none; 
          }
      }

      
      
  </style>
 `;

 export default baseStylesEditorPDF;