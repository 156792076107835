import {useEffect, useState} from 'react';
import {useDatepicker, START_DATE} from '@datepicker-react/hooks';
import APIService from 'services/backstrap/apiService';

const useWhenStep = (send, error) => {
  const [dates, setDates] = useState({
    focusedInput: START_DATE,
  });

  const handleDateChange = data => {
    console.log('hit this');
    if (!data.focusedInput) {
      setDates({...data, focusedInput: START_DATE});
    } else {
      setDates(data);
    }
  };

  const {
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dates.startDate,
    focusedInput: dates.focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths: 1,
    exactMinBookingDays: true,
  });

  const dateValues = {
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  };

  useEffect(() => {
    const updateCampaign = async campaign => {
      try {
        let offset = new Date(campaign.send_on_date).getTimezoneOffset();
        let unformattedDate = new Date(campaign.send_on_date.getTime() - (offset*60*1000))
        campaign.send_on_date = unformattedDate.toISOString().split('T')[0];
        await APIService.patchCampaign(campaign);
      } catch (err) {
        console.log(err);
      }
    };

    if (dates.startDate != null) {
      send.send_on_date = dates.startDate;
      updateCampaign({
        id: send.id,
        send_on_date: dates.startDate,
      });
    }

    if (send.send_on_date && !dates.startDate) {
      let offset = new Date(send.send_on_date).getTimezoneOffset();
      let adjDate = new Date(send.send_on_date).getTime() + (offset*60*1000)
      setDates(data => ({...data, startDate: adjDate}));
    }
  }, [send.id, dates.startDate]);

  return {
    dateValues,
    activeMonths,
    goToPreviousMonths,
    goToNextMonths,
  };
};

export default useWhenStep;
