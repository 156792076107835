import React from 'react';
import 'font-awesome/css/font-awesome.css';
import StepLayout from 'layouts/Step';
import useStep from 'hooks/steps';
import TemplateList from 'components/TemplateList';
import UploadTemplate from 'components/UploadTemplate';
import useWhatStep from './hooks';

import './styles.scss';

const WhatStep = props => {
  document.title = `Send - What are we sending?`;
  const {id, send, setError, error, disabled} = useStep();
  const {
    activeTemplate,
    loading,
    previewTemplate,
    selectTemplate,
    selectWhatTemplateType,
    templates,
    loadMoreTemplates,
    handlePageChange,
    showUploadTemplate,
    showCurrentTemplate,
  } = useWhatStep(send, setError);

  return (
    <StepLayout
      {...props}
      loading={loading && !send}
      disabled={disabled}
      error={error}
      noSteps
      title="Send - What are we sending?">
      <h1 className="mt-5 text-center">What are we sending?</h1>
      <div className="row mx-0 text-center justify-content-center mt-5">
        {showUploadTemplate ? (
          <UploadTemplate selectWhatTemplateType={selectWhatTemplateType} />
        ) : (
          <TemplateList
            selectWhatTemplateType={selectWhatTemplateType}
            id={id}
            activeSendObject={send}
            showCurrentTemplate={showCurrentTemplate}
            setError={setError}
            loading={loading}
            templates={templates}
            previewTemplate={previewTemplate}
            activeTemplate={activeTemplate}
            selectTemplate={selectTemplate}
            loadMoreTemplates={loadMoreTemplates}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
    </StepLayout>
  );
};

export default WhatStep;
