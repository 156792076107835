import React, {useContext} from 'react';
import {CTX} from 'store';
import {Context as SendContext} from 'contexts/send';

import Modal from 'components/Modal/Modal';
import logo from 'assets/img/logo.svg';
import plus from 'assets/img/ico_plus.svg';
import './Header.scss';

const Header = p => {
  const {send} = useContext(SendContext);
  const [appState, dispatch] = useContext(CTX);
  const [modal, modalState] = React.useState(false);
  const toggleModal = () => modalState(!modal);
  const [popover, popoverState] = React.useState(false);
  const togglePopover = () => popoverState(!popover);

  const newClicked = () => {
    if (send.name) {
      modalState(true);
    } else {
      createNewSend();
    }
  };

  const createNewSend = () => {
    dispatch({type: 'CREATE_NEW_SEND'});
    p.props.history.push('/send/new');
  };

  const logOut = () => {
    dispatch({type: 'LOG_OUT'});
    localStorage.removeItem('cs_token');
  };

  const linkHome = () => p.props.history.push('/');
  const linkDrafts = () => p.props.history.push('/drafts');
  const linkSent = () => p.props.history.push('/sent');
  const linkScheduled = () => p.props.history.push('/scheduled');
  const linkCampaigns = () => p.props.history.push('/campaigns');
  const sentToCSMailhouse = () =>
    p.props.history.push('/report-sent-to-cs-mailhouse');

  return (
    <>
    <nav id="top-bar" className="navbar navbar-light">
        <a className="navbar-brand" onClick={linkHome}>
          <img src={logo} className="logo-header" alt="Send" />
        </a>

        <div className="text-center send-title">
          {appState.activeStep === 'drafts'
            ? 'Drafts'
            : appState.activeStep === 'sent_to_cs_mailhouse'
            ? 'Report: Sent to CivicSource Mailhouse'
            : appState.activeStep === 'scheduled'
            ? 'Scheduled'
            : appState.activeStep === 'campaigns'
            ? 'Campaigns'
            : send.name
            ? send.name
            : null}
        </div>

        <div className="align-items-right d-flex">
          <button className="btn nav-action" type="button" onClick={newClicked}>
            <img src={plus} className="plus-ico" />
          </button>

          <div className="btn d-flex align-items-center justify-content-center pointer p-0 btn-user-menu">
            <span className="name" onClick={togglePopover}>
              {appState.bs_user.username}
            </span>
            {popover ? (
              <div className={[popover ? 'popover-nav active' : 'popover-nav']}>
                <ul className="popover-menu">
                  <li className="popover-menu-item">
                    <button onClick={linkCampaigns}>Campaigns</button>
                  </li>
                  <li className="popover-menu-item">
                    <button onClick={sentToCSMailhouse}>
                      Sent to Mailhouse
                    </button>
                  </li>
                  <li className="popover-menu-item">
                    <button onClick={logOut}>Logout</button>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </nav>

      {modal ? (
        <Modal
          title="Create New Send?"
          body="Lorem ipsum dolor sit amet, consectetur adipiscing elit?"
          confirm={createNewSend}
          close={toggleModal}
        />
      ) : null}
    </>
  );
};

export default Header;
