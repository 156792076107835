export const getMergeTagHTML = (displayText, data) => {
  //fr-deletable class needed to allow deleting as whole element inside plugin
  //LF-DATA-FIELD and LF-DATA classes needed for mergetag-plugin to trigger
  return `<span class="LF-DATA LF-DATA-FIELD fr-deletable"><span class="LF-DATA LF-DATA-FIELD-DATA">${data}</span><span class="LF-DATA LF-DATA-FIELD-TEXT">${displayText
    .replace('{$', '')
    .replace('}', '')}</span></span> `;
};


export const getTagHTML = (displayText, data) => {
  //fr-deletable class needed to allow deleting as whole element inside plugin
  //LF-DATA-FIELD and LF-DATA classes needed for mergetag-plugin to trigger
  return `<span class="LF-DATA LF-DATA-FIELD fr-deletable"><span class="LF-DATA LF-DATA-FIELD-DATA">${data}</span><span class="LF-DATA LF-DATA-FIELD-TEXT">${displayText
    .replace('{$', '')
    .replace('}', '')}</span></span> `;
};
