import React, { useEffect } from 'react';
import { CTX } from 'store';

import Header from 'components/Header/Header';
import plus from 'assets/img/ico_plus.svg';

const MainScreen = (props) => {

    document.title = `Send`;

    const [appState, dispatch] = React.useContext(CTX);
    useEffect( () =>  { dispatch({type: 'UPDATE_ACTIVE_STEP', payload: 'welcome'}) }, [appState.activeStep] )

    const createNewSend = () => {
      dispatch({type: 'CREATE_NEW_SEND'});
      props.history.push('/send/new')
    }

    return (
      <>
      <Header props={props} />
      <div className="container full-center">
        <h1 className="mt-5 text-center">
          Welcome.
          <small>Press <img src={plus} className="plus-home pointer" alt="plus" onClick={createNewSend} /> to begin.</small>
        </h1>
      </div>
      </>
    );

}

export default MainScreen;