const template = `<meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
<style type='text/css'>
  @page {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 100px;
    margin-right: 60px;
  }
  .highlight_yellow_remove {
    display: inline-block;
    padding: 3px;
    background-color: #feeb9d;
    color: #000000;
    border-radius: 5px;
  }
  .highlight_yellow_dark_remove {
    display: inline-block;
    padding: 3px;
    background-color: #fad133;
    color: #000000;
    border-radius: 5px;
  }
  .column_50 {
    float: left;
    width: 50%;
  }
  .black_bar {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    width: 100%;
    color: white;
    background: black;
    border-radius: 8px;
    text-align: center;
    padding: 2px;
  }
  th,
  td {
    padding: 3px 4px 3px 4px;
  }
  table.rounded tr {
    border-radius: 5px;
  }
  .small_margin {
    margin: 5px;
  }
  .no_margin {
    margin: 0;
  }
  .clear {
    clear: both;
    height: 0 !important;
  }
</style>
<div class='block' style='margin-bottom: 20px;'>
  <div style='display:inline-block;float:left;width:50%;'>
    <p><br /></p>
  </div>
</div>

<div class='clear'></div>

<div class='block_head extra'>
  <div class='sub_block' style='width:35%;float:left;'>
    <div>
      <table style='width:100%;'>
        <tbody>
          <tr>
            <td style='vertical-align: top; text-align: center;'>
              <span style='font-family:Arial,Helvetica,sans-serif;'
                ><img
                  src='https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_icon_1.png'
                  style='width: 25px;'
              /></span>
            </td>
            <td>
              <span style='font-family:Arial,Helvetica,sans-serif;'
                ><span style='font-size:11px;'
                  ><span style='font-weight:bold;'>PROPERTY ADDRESS</span></span
                ><br />
                <span style='font-size:11px;'
                  ><span class='highlight_yellow'
                    >{$propertyaddress}</span
                  ></span
                ></span
              >
            </td>
          </tr>
          <tr>
            <td style='vertical-align: top; text-align: center;'>
              <img
                alt=''
                src='https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_icon_2.png'
                style='width: 25px;'
              />
            </td>
            <td>
              <span style='font-family:Arial,Helvetica,sans-serif;'
                ><span style='font-size:11px;'
                  ><span style='font-weight:bold;'>PARCEL NUMBER</span></span
                ><br />
                <span style='font-size:11px;'
                  ><span class='highlight_yellow'>{$taxbill}</span></span
                ></span
              >
            </td>
          </tr>
          <tr>
            <td style='vertical-align: top; text-align: center;'>
              <img
                alt=''
                src='https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_icon_3.png'
                style='width: 25px;'
              />
            </td>
            <td>
              <span style='font-family:Arial,Helvetica,sans-serif;'
                ><span style='font-size:11px;'
                  ><span style='font-weight:bold;'
                    >PAY BY {$duedate}</span
                  ></span
                ><br />
                <span style='font-size:11px;'
                  ><span class='highlight_yellow'
                    ><span style='color:red;'>{$taxdue}</span></span
                  ></span
                ></span
              >
            </td>
          </tr>
          <tr>
            <td style='vertical-align: top; text-align: center;'>
              <img
                alt=''
                src='https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_icon_4.png'
                style='width: 25px;'
              />
            </td>
            <td>
              <span style='font-family:Arial,Helvetica,sans-serif;'
                ><span style='font-size:11px;'
                  ><span style='font-weight:bold;'>SEND PAYMENTS TO</span></span
                ><br />
                <span style='font-size:11px;'
                  >AUDRAIN COUNTY<br />
                  COLLECTOR'S OFFICE <br />
                  101 NORTH JEFFERSON<br />
                  ROOM 103<br />
                  MEXICO, MO 65265</span
                ></span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    class='sub_block'
    style='width:65%;float:left;min-height:240px;background-image: url('https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_red_line.png');background-repeat:no-repeat;background-size: contain;display:none;'
  >
    <div style='margin-top:-20px;padding-left:50px;'>
      <div class='clear'></div>
    </div>
  </div>

  <div class='clear'></div>

  <div class='clear' style='text-align: right;'></div>

  <div style='border-radius: 0.0625in;'>
    <h1
      style='font-size:22px; display:block; padding:5px 10px; color: white; background: #e84733; border-radius:10px; font-family:Arial,Helvetica,sans-serif;font-weight: bold;text-align: center;'
    >
      <strong>NOTICE: YOUR PROPERTY IS SUBJECT TO TAX SALE</strong>
    </h1>

    <p class='small_margin'>
      <span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'
        >The taxes owned on your property are now delinquent. Tax payments are
        due by December 31 each year. If you believe your mortgage company
        should have paid this bill on your behalf, please contact them
        immediately to arrange payment. Real property with unpaid taxes is
        subject to sale by the county to satisfy the outstanding tax lien. This
        year, Audrain County's sale will take place on
        <strong>Monday, August {$saleDate}</strong>. We will be offering
        properties that have taxes due from
        <strong>{$taxyear} or earlier</strong>. A statement of delinquent taxes
        is enclosed.</span
      >
    </p>

    <p class='small_margin'>
      <span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'>
        <strong
          >If the enclosed statement indicates that the taxes on your property
          are delinquent for {$taxYear} or earlier, your property will be
          auctioned at our tax sale. Please be aware of the following important
          notices:</strong
        >></span
      >
    </p>

    <p class='small_margin'>
      <span>&#8226;</span
      ><span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'>
        Payments of delinquent taxes may be made with any regular form of
        payment through May 31, {$currentYear}. Beginning June 1,
        {$currentYear}, payments must be made in cash, debit/credit card, or
        certified funds. We will not accept personal checks for delinquent real
        property taxes after May 31, {$currentYear}. Additionally, ALL TAXES
        must be brought current to remove your property from sale as of June 1,
        {$currentYear}</span
      >
    </p>

    <p class='small_margin'>
      <span>&#8226;</span
      ><span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'>
        On June 1, {$currentYear}, fees will be added to the bill for
        statutorily required research, advertising and conducting the tax sale.
        These fees cannot be compromised or removed, and become an enforceable
        part of the tax bill when they are added.</span
      >
    </p>

    <p class='small_margin'>
      <span>&#8226;</span
      ><span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'>
        Mailed payments must be received by Friday, August 21, 2020 at 5:00 p.m.
        We will also accept payments delivered in person until 10:00 a.m. on
        Monday, August {$auctionDate}. Your payment must be actually received
        before the sale starts, or your property will be included in the sale.
        Overnight delivery or mailed payments will not be accepted after 10:00
        a.m. on August {$saledate}. A postmark or delivery confirmation will not
        protect your property from tax sale.</span
      >
    </p>
  </div>

  <div>
    <h3
      style='color:white;background:black;border-radius:5px;text-align:center;font-size:10px;z-index: -1;
    position:relative'
    >
      <span style='font-family:Arial,Helvetica,sans-serif;'
        >▼ DETACH PORTION BELOW AND RETURN IN ENVELOPE WITH PAYMENT ▼</span
      >
    </h3>

    <table style='width:100%;margin:0 auto;'>
      <tbody>
        <tr>
          <td style='width:50%;'>
            <table
              class='rounded'
              style='border:1px solid #e84733;border-radius:5px;border-spacing:0;width:70%'
            >
              <tbody>
                <tr>
                  <td
                    style='background:#e84733;color:white;padding:5px 0;font-weight:bold;text-align:center;'
                  >
                    <span style='font-size:16px;'
                      ><span style='font-family: Arial, Helvetica, sans-serif;'
                        >PAY BY {$duedate}</span
                      ></span
                    >
                  </td>
                </tr>
                <tr>
                  <td style='text-align:center;'>
                    <span style='font-size:16px;'
                      ><strong
                        ><span
                          class='highlight_yellow'
                          style='font-family: Arial, Helvetica, sans-serif;'
                          >{$taxdue}</span
                        ></strong
                      ></span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style='width:50%;'>
            <table style='width:100%;'>
              <tbody>
                <tr>
                  <td>
                    <span style='font-size:12px;'
                      ><span style='font-family:Arial,Helvetica,sans-serif;'
                        ><span style='font-weight:bold;'>PROPERTY:</span></span
                      ></span
                    >
                  </td>
                  <td>
                    <span style='font-size:12px;'
                      ><span
                        class='highlight_yellow'
                        style='font-family:Arial,Helvetica,sans-serif;'
                        >{$propertyaddress}</span
                      ></span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style='font-size:12px;'
                      ><span style='font-family:Arial,Helvetica,sans-serif;'
                        ><span style='font-weight:bold;'
                          >PARCEL NUMBER:</span
                        ></span
                      ></span
                    >
                  </td>
                  <td>
                    <span style='font-size:12px;'
                      ><span
                        class='highlight_yellow_dark'
                        style='font-family:Arial,Helvetica,sans-serif;'
                        >{$parcelid}</span
                      ></span
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style='font-size:12px;'
                      ><span style='font-family:Arial,Helvetica,sans-serif;'
                        ><span style='font-weight:bold;'
                          >OWNER NAME:</span
                        ></span
                      ></span
                    >
                  </td>
                  <td>
                    <span style='font-size:12px;'
                      ><span
                        class='highlight_yellow'
                        style='font-family:Arial,Helvetica,sans-serif;'
                        >{$owner}</span
                      ></span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <div class='block'>
      <div class='column_50'>
        <table style='margin-top:20px;'>
          <tbody>
            <tr>
              <td width='25%'>
                <span style='font-family:Arial,Helvetica,sans-serif;'
                  ><img
                    src='https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/52334/ck/images/lookfar_crest2.png'
                    style='width: 50px; height: 55px;'
                /></span>
              </td>
              <td>
                <span style='font-size:12px;'
                  ><span style='font-family:Arial,Helvetica,sans-serif;'
                    ><span style='font-weight:bold;'
                      >AUDRAIN COUNTY COLLECTOR'S OFFICE</span
                    ><br />
                    101 NORTH JEFFERSON<br />
                    ROOM 103<br />
                    MEXICO, MO 65265</span
                  ></span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class='column_50'>
        <div class='black_bar'>
          <strong><span style='font-size:13px;'>TO PAY BY MAIL</span></strong>
        </div>

        <p style='margin:5px 0;'>
          <span
            style='font-size:12px;font-family:Arial,Helvetica,sans-serif;font-weight: bold;'
            >MAKE CASHIER'S CHECK PAYABLE TO: AUDRAIN COUNTY COLLECTOR'S
            OFFICE</span
          >
        </p>
        <span style='font-family:Arial,Helvetica,sans-serif;'
          ><span style='font-size:12px;'
            >Detach the return stub and mail with a cashier's check for the
            amount due.<br />
            Please write the Parcel Number on your check.</span
          ><br />
        </span>
      </div>
    </div>

    <div><formfeed></formfeed></div>

    <br />
    <br />
    <table>
      <tbody>
        <tr>
          <td>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='font-weight:bold;'>PROPERTY:</span></span
                ></span
              ></span
            >
          </td>
          <td>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span
                    class='highlight_yellow'
                    style='font-family:Arial,Helvetica,sans-serif;'
                    >{$propertyaddress}</span
                  ></span
                ></strong
              ></span
            >
          </td>
        </tr>
        <tr>
          <td>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='font-weight:bold;'>PARCEL NUMBER:</span></span
                ></span
              ></span
            >
          </td>
          <td>
            <span style='font-size:12px;'
              ><strong
                ><span class='highlight_yellow' style='font-size:12px;'
                  ><span style='font-family:Arial,Helvetica,sans-serif;'
                    >{$parcelid}</span
                  ></span
                ></strong
              ></span
            >
          </td>
        </tr>
        <tr>
          <td>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='font-weight:bold;'>OWNER NAME:</span></span
                ></span
              ></span
            >
          </td>
          <td>
            <span style='font-size:12px;'
              ><strong
                ><span class='highlight_yellow' style='font-size:12px;'
                  ><span style='font-family:Arial,Helvetica,sans-serif;'
                    >{$owner}</span
                  ></span
                ></strong
              ></span
            >
          </td>
        </tr>
        <tr>
          <td>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='font-weight:bold;'
                    >LEGAL DESCRIPTION:</span
                  ></span
                ></span
              ></span
            >
          </td>
          <td>
            <span style='font-size:12px;'
              ><span style='font-family:Arial,Helvetica,sans-serif;'
                ><span class='highlight_yellow' style='font-size:12px;'
                  >{$legal}</span
                ><br />
                <span style='font-size:9px;'
                  >Legal description may be truncated to fit. For full
                  description, please call the tax collector.</span
                ></span
              ></span
            >
          </td>
        </tr>
        <br />
        <br />
        <br />
      </tbody>
    </table>
  </div>

  <div class='block_head extra'>
    <p class='small_margin'>
      <span>&#8226;</span
      ><span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'>
        Most properties that are purchased at the sale have a redemption period
        where parties with a legal interest in the property may pay all back
        taxes (which may include city taxes or special assessments), interest,
        and penalties, and reclaim full title to the property. Ownership is not
        lost until the redemption period has expired and the tax sale bidder
        completes his obligations. Most properties with taxes that are 2-3 years
        past due will have a 1 year redemption period. Properties with taxes 4
        years past due will have a 90 day redemption period. Properties with
        taxes 5 or more years past due will not have a redemption period. A deed
        will immediately be issued if we have an acceptable bid on the property.
        Properties with taxes 4 years past due, that do not sell at the tax
        sale, will be available to the public after the sale, with no redemption
        period.</span
      >
    </p>
    <p class='small_margin'>
      <span>&#8226;</span
      ><span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'>
        All amounts appearing on your tax bill, including special assessments,
        city taxes, and fees or penalties must be paid in full through
        {$previousYear} as of June 1, {$currentYear} in order to keep your
        property from being sold at the tax sale.</span
      >
    </p>
    <p style='text-align: center;'>
      <span style='font-family:Arial,Helvetica,sans-serif;font-size:11.5px;'
        ><strong>Questions? Call (573) 473-5824</strong></span
      >
    </p>
  </div>

  <div style='margin-top: 60px;'>
    <table
      border='0'
      cellpadding='3'
      cellspacing='0'
      style='border:1px solid #333333;text-align:center;width:100%;'
    >
      <tbody style='font-family: Arial, Helvetica, sans-serif;'>
        <tr>
          <td style='background-color:#333;text-align: left;'>
            <span style='font-family:Arial,Helvetica,sans-serif;'
              ><font color='#ffffff'
                ><span style='font-size: 12px;'><b>Assessments</b></span></font
              ></span
            >
          </td>
          <td style='background-color: #333;text-align: left;'>
            <span style='font-family:Arial,Helvetica,sans-serif;'
              ><font color='#ffffff'
                ><span style='font-size: 12px;'><b>Value</b></span></font
              ></span
            >
          </td>
          <td style='background-color:#333; text-align: left;'>
            <span style='font-family:Arial,Helvetica,sans-serif;'
              ><font color='#ffffff'
                ><span style='font-size: 12px;'><b>Exemption</b></span></font
              ></span
            >
          </td>
          <td style='background-color: #333; text-align: left;'>
            <span style='font-family:Arial,Helvetica,sans-serif;'
              ><font color='#ffffff'
                ><span style='font-size: 12px;'
                  ><b>Taxable Value</b></span
                ></font
              ></span
            >
          </td>
        </tr>
        <tr>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{tablerow from=$a1 item=t2}{tableif !empty($t2.Aval)}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t2.Value}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t2.Exempt}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t2.Total}{/tableif}{/tablerow}</span
                ></span
              ></span
            >
          </td>
        </tr>
        <tr>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span style='font-family:Arial,Helvetica,sans-serif;'
                    >Total</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span
                    class='highlight_yellow'
                    style='font-family:Arial,Helvetica,sans-serif;'
                    >{$value}</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span
                    class='highlight_yellow'
                    style='font-family:Arial,Helvetica,sans-serif;'
                    >{$t1total}</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span class='highlight_yellow' style='font-size:12px;'
                  ><span style='font-family:Arial,Helvetica,sans-serif;'
                    >{$t1total}</span
                  ></span
                ></strong
              ></span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />

    <span style='font-size:14px;'
      ><strong
        ><span style='font-family:Arial,Helvetica,sans-serif;'
          >TAXES</span
        ></strong
      >
    </span>

    <table
      border='0'
      cellpadding='3'
      cellspacing='0'
      style='border:1px solid #333333;text-align:center;width:100%;'
    >
      <tbody style='font-family: Arial, Helvetica, sans-serif;'>
        <tr>
          <td style='background-color:#333;text-align: left;'>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='color: rgb(255, 255, 255); font-weight: bold;'
                    >Year</span
                  ></span
                ></span
              ></span
            >
          </td>
          <td style='background-color: #333;text-align: left;'>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='color: rgb(255, 255, 255); font-weight: bold;'
                    >Type</span
                  ></span
                ></span
              ></span
            >
          </td>
          <td style='background-color:#333; text-align: left;'>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='color: rgb(255, 255, 255); font-weight: bold;'
                    >Base</span
                  ></span
                ></span
              ></span
            >
          </td>
          <td style='background-color: #333; text-align: left;'>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='color: rgb(255, 255, 255); font-weight: bold;'
                    >Interests</span
                  ></span
                ></span
              ></span
            >
          </td>
          <td style='background-color:#333; text-align: left; '>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='color: rgb(255, 255, 255); font-weight: bold;'
                    >Penalty</span
                  ></span
                ></span
              ></span
            >
          </td>
          <td style='background-color: #333; text-align: left;'>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='color: rgb(255, 255, 255); font-weight: bold;'
                    >Collection</span
                  ></span
                ></span
              ></span
            >
          </td>
          <td style='background-color: #333; text-align: left;'>
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span style='font-family:Arial,Helvetica,sans-serif;'
                  ><span style='color: rgb(255, 255, 255); font-weight: bold;'
                    >Total</span
                  ></span
                ></span
              ></span
            >
          </td>
        </tr>
        <tr>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{tablerow from=$taxtable item=t1}{tableif
                  !empty($t1.year)}{$t1.year}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t1.type}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t1.base}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t1.int}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t1.pen}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t1.collect}</span
                ></span
              ></span
            >
          </td>
          <td
            style='text-align: left; font-family: Arial, Helvetica, sans-serif'
          >
            <span style='font-size:12px;'
              ><span style='font-size:12px;'
                ><span
                  class='highlight_yellow'
                  style='font-family:Arial,Helvetica,sans-serif;'
                  >{$t1.Total}{/tableif}{/tablerow}</span
                ></span
              ></span
            >
          </td>
        </tr>
        <tr>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span style='font-family:Arial,Helvetica,sans-serif;'
                    >Total</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'> </span>
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span
                    class='highlight_yellow'
                    style='font-family:Arial,Helvetica,sans-serif;'
                    >{$tbase}</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span class='highlight_yellow' style='font-size:12px;'
                  ><span style='font-family:Arial,Helvetica,sans-serif;'
                    >{$tint}</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span
                    class='highlight_yellow'
                    style='font-family:Arial,Helvetica,sans-serif;'
                    >{$tpen}</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span
                    class='highlight_yellow'
                    style='font-family:Arial,Helvetica,sans-serif;'
                    >{$tcollect}</span
                  ></span
                ></strong
              ></span
            >
          </td>
          <td style='text-align: left'>
            <span style='font-size:12px;'
              ><strong
                ><span style='font-size:12px;'
                  ><span
                    class='highlight_yellow'
                    style='font-family:Arial,Helvetica,sans-serif;'
                    >{$ttotal}</span
                  ></span
                ></strong
              ></span
            >
          </td>
        </tr>
      </tbody>
    </table>
    <br />
  </div>

  <div style='margin-top:120px;'>
    <div
      style='display:block;height:10px;background-color:#000;border-radius:50px;margin:10px 0;'
    ></div>

    <div class='block'>
      <div class='column_50'>
        <div class='black_bar'>
          <strong><span style='font-size:13px;'>TO PAY ONLINE</span></strong>
        </div>
        <span style='font-size:12px;padding:5px 5px 10px;'
          ><span style='font-family:Arial,Helvetica,sans-serif;'
            >Visit http://tax.audraincounty.org/ and click the link to pay
            <br />
            property taxes.</span
          >
        </span>

        <div class='black_bar' style='margin-top:10px;'>
          <strong><span style='font-size:13px;'>TO PAY IN PERSON</span></strong>
        </div>
        <span style='font-size:12px;padding:5px 5px 10px;'
          ><span style='font-family:Arial,Helvetica,sans-serif;'
            ><span style='font-weight:bold'
              >Audrain County Collector's Office:
            </span>
            101 North Jefferson, Room 103, Mexico, MO 65265</span
          ></span
        >
      </div>

      <div class='column_50'></div>
    </div>
  </div>

  <div class='clear'></div>

  <div>
    <span style='font-size:12px;'><formfeed></formfeed></span>
  </div>
</div>

  <div style='margin-top:200px;'></div>`;

export default template;
